/*Theme Dragons Fight Styles*/
$app-primary-blue: #2196F3 !default;
$secondary-blue: #FF6E40;

$sidebar-blue: rgba($app-primary-blue, 0.25) !default;
$sidebar-text-blue: #868e96 !default;
//variables
.dragons-fight {

  --bg: #F5FCFF;
  --pure-bg: #FEFEFF;
  --bg-gradient-light: linear-gradient(90deg, #EDF1F8 8.56%, #FEFEFF 100.93%);
  --bg-light: #F7FCFF;
  --bg-lighter : #d9d2d2;
  --table-odd-bg : #EFF9FF;
  --table-head-bg : #000000;
  --price-table-border: #d7e8fd;
  --border-color: #DBE5EC;
  --input-adornment-bg: #e2ecf2;
  --table-even-bg : linear-gradient(90deg, #091629 8.56%, #00091F 100.93%);
  --bg-gradient: linear-gradient(90deg, #EDF1F8 8.56%, #FEFEFF 100.93%);
  --secondary-color:  #03B9F5;
  --secondary-more-light: #d9f3fd;
  --fight-clear-bg: linear-gradient(298.93deg, rgba(30, 148, 210, 0.42) 27.84%, rgba(30, 148, 210, 0.65) 47.79%, rgba(237, 28, 43, 0.25) 55.7%, rgba(237, 28, 43, 0.58) 100.12%);
  --fight-bg: linear-gradient(298.93deg, rgba(30, 148, 210, 0.1) 27.84%, rgba(30, 148, 210, 0.1) 47.79%, rgba(237, 28, 43, 0.17) 55.7%, rgba(237, 28, 43, 0.36) 100.12%);
  --secondary-gradient : linear-gradient(135deg, #20D9FE -254.88%, #00A1F1 247.57%);
  --secondary-gradient-light : linear-gradient(180deg, #80DFFF 0%, #5DD2F8 100%);
  --primary-light-gradient:  linear-gradient(180deg, #F95C5C 0%, #FF7878 100%);
  --secondary-glow: linear-gradient(171.43deg, rgba(231, 248, 255, 0.048) 6.99%, rgba(194, 233, 255, 0.145058) 43.71%, rgba(108, 173, 233, 0.14) 85.17%);
  --button-grey-dark:  linear-gradient(280.17deg, #ABBCD6 -27.05%, #2F3D4B 185.77%);
  --secondary-gradient-fade: linear-gradient(90deg, #2DA3E1 0%, #47AEE6 14.29%, #78BFED 28.57%, #9FCEF4 42.86%, #C3DDF9 57.14%, #F5F9FF 71.43%, rgba(230, 234, 255, 0.9) 85.71%, rgba(224, 226, 255, 0.8) 100%);;
  --primary-btn-gradient: linear-gradient(101.7deg, rgba(134, 134, 134, 0.2) 0%, rgba(255, 255, 255, 0.2) 21.5%, rgba(137, 70, 70, 0.28) 21.51%, rgba(255, 255, 255, 0) 45.14%), radial-gradient(100% 144.66% at 0% 0%, #E91E1E 0%, #BC1212 100%);
  --secondary-btn-gradient: linear-gradient(101.7deg, rgba(189, 189, 189, 0.2) 0%, rgba(255, 255, 255, 0.2) 21.5%, rgba(122, 188, 217, 0.28) 21.51%, rgba(255, 255, 255, 0) 45.14%), radial-gradient(100% 144.66% at 0% 0%, #41C1F1 0%, #0EB1EA 100%);
  --purple-btn-gradient: linear-gradient(101.7deg, rgba(189, 189, 189, 0.2) 0%, rgba(255, 255, 255, 0.2) 21.5%, rgba(122, 188, 217, 0.28) 21.51%, rgba(255, 255, 255, 0) 45.14%), radial-gradient(100% 144.66% at 0% 0%, #310b76 0%, #310b76 100%);

  //--primary-secondary-gradient-border: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)), linear-gradient(220.93deg, rgb(30, 148, 210) 27.84%, rgb(30, 148, 210) 47.79%, #ED1C2B 55.7%, #ED1C2B 100.12%);
  --primary-secondary-gradient-border: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)), linear-gradient(220.93deg, rgb(30 148 210 / 33%) 27.84%, rgb(30 148 210 / 48%) 47.79%, #ed1c2b4d 55.7%, #ed1c2b40 100.12%);
  --field-color: #D9E5ED;
  --text-color: #2c2c2c;
  --secondary-light: #80DFFF;
  --success-linear-gradient: linear-gradient(135deg, rgba(84, 239, 169, 0.1) -254.88%, rgba(2, 166, 179, 0.1) 247.57%);
  --success:  linear-gradient(135deg, #54EFA933 -254.88%, #02A6B333 247.57%);
  --success-solid:  #23c4af;
  --danger-bg: #B10A0A26;
  --danger-text: #ED6464;
  --warning-bg: #ffcd4444;
  --warning-text: #dbac1d;
  --primary-bg: #2196f340;
  --primary-text: #2196F3;

  --success-bg: linear-gradient(135deg, #54EFA933 -254.88%, #02A6B333 247.57%);
  --notif-danger-bg: #f9bcc0;
  --notif-success-bg: #d4f4f2;

  --secondary: #DB0E0E;
  --primary: #0993D7;
  --primary-light: #FF7878;
  --btn-text: #ffffff;
  --bg-img-black: linear-gradient(0deg, rgba(0, 40, 53, 0.1), rgba(0, 40, 53, 0.1)), url("../../assets/images/common/typing.svg");
}

//-Base-scss
.dragons-fight .right-arrow {
  color: $app-primary-blue;
  &:after {
    color: $app-primary-blue;
  }
}

//body
body {
  background-image: var(--bg-gradient);
}
//Panel Styles

.dragons-fight {
  .gate-way-logos {
    //height: 20px;
    max-width: 80px;
    max-height: 18px;
    @media screen and (max-width: 510px ) {
      //height: 16px;
      max-width: 60px;
      max-height: 13px;
    }
  }
}

//Panel Header
.dragons-fight .app-header {
  background-color: var(--bg);
  min-height: 85px;
  @media screen and (max-width: 510px ) {
    min-height: 72px;
    padding: 0 10px;
  }
  .app-logo {
    @media screen and (max-width: 768px ) {
     display: none;
    }
    svg {
      width: 88px;
      height: 88px;
      @media screen and (max-width: 1100px ) {
        width: 70px;
        height: 70px;
      }
    }
  }
   .MuiPaper-root {
     background-color: var(--bg) !important;
   }
  .MuiToolbar-regular {
    min-height: 92px;
    padding-left: unset;
    justify-content: space-between;
    @media screen and (max-width: 510px ) {
      min-height: 72px;
    }
  }
  .header-wallet {
    //&.anime {
    //  animation-name: walletCapsule;
    //  animation-duration: 2s;
    //  animation-iteration-count: 1;
    //}

    //@keyframes walletCapsule {
    //  0% {
    //    transform: translateX(-1.25em);
    //  }
    //  100% {
    //    transform: translateX(0);
    //  }
    //}
    background-image: var(--fight-bg);
    display: flex;
    padding: 3px 14px;
    border-radius: 55px;
    @media screen and (max-width: 580px ) {
      padding: 3px 10px 3px 4px;
      border-radius: 16px;
    }
    .dropdown {
      .dropdown-menu {
        width: 200px;
        padding: 10px;
        border-radius: 20px;
        top: 35px;
        right: 0 !important;
        background-color: var(--bg-light);
        .dropdown-frozen-wrapper {
          .frozen-balance-wrapper {
            //padding-bottom: 8px;
            justify-content: space-between;
            background-image: var(--fight-bg);
            padding: 4px 10px;
            border-radius: 18px;
            //animation-name: walletCapsule;
            //animation-duration: 2s;
            //animation-iteration-count: 1;
            //@keyframes walletCapsule {
            //  0% {
            //    transform: translateX(1.25em);
            //  }
            //  100% {
            //    transform: translateX(0);
            //  }
            //}
            transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            //flex-direction: column;
            gap: 10px;
            @media screen and (max-width: 580px ) {
              gap: 4px;
              flex-flow: column;
              margin-bottom: 8px;
            }
            .balance-text {
              color: var(--text-color);
              font-size: 14px;
              opacity: 0.9;
              @media screen and (max-width: 580px ) {
                font-size: 12px;
                font-weight: 600;
              }
            }
            .header-balance {
              color: var(--text-color);
              font-weight: 700;
              font-size: 15px;
              text-align: center;
              //margin-right: 48px;
              //margin-left: 15px;
              .metric {
                color: var(--secondary-color);
                margin: 0 0 0 6px;
              }
              @media screen and (max-width: 890px ) {
                font-size: 13px;
                margin-right: 16px;
                margin-left: 0;
                text-align: left;
              }
            }
          }
        }
        .drop-down-buttons-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .withdraw-btn {
            background-image: var(--primary-btn-gradient);
            color: var(--btn-text);
            border-radius: 55px;
            height: 30px;
            padding: 8px 20px;
            box-shadow: none;
          }
          .deposit-btn {
            background-image: var(--secondary-btn-gradient);
            color: var(--btn-text);
            border-radius: 55px;
            height: 30px;
            padding: 8px 20px;
            box-shadow: none;
          }
        }
      }
    }

  }
  .wallet_wrapper {
    display: flex;
    gap: 5px;
    align-items: center;

    @keyframes chevron {
      0%   { right:0; top:0;}
      100% {right: 10px; top:0;}
    }
    @keyframes chevronCollapsed {
      0%   { right: 10px; top:0;}
      100% {right: 0; top:0;}
    }
    .wallet-collapse-btn {
      cursor: pointer;
      color: var(--secondary);
      .wallet-chevron {
        position: relative;
        animation-name: chevron;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        &.collapsed {
          transform: rotate(180deg);
          position: relative;
          animation-name: chevronCollapsed;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
        }
      }

    }
    .frozen-balance-wrapper {
      background-color: var(--bg);
      padding: 2px 10px;
      border-radius: 18px;
      //animation-name: walletCapsule;
      //animation-duration: 2s;
      //animation-iteration-count: 1;
      //@keyframes walletCapsule {
      //  0% {
      //    transform: translateX(1.25em);
      //  }
      //  100% {
      //    transform: translateX(0);
      //  }
      //}
      transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      gap: 6px;
      @media screen and (max-width: 580px ) {
        gap: 4px;
      }
      .balance-text {
        color: var(--text-color);
        font-size: 14px;
        opacity: 0.9;
        margin-left: 15px;
        @media screen and (max-width: 580px ) {
          font-size: 10px;
          font-weight: 500;
          margin-left: 0;
        }
      }
      .header-balance {
        color: var(--text-color);
        font-weight: 600;
        font-size: 15px;
        text-align: left;
        margin-left: 4px;
        //margin-right: 48px;
        //margin-left: 15px;
        .metric {
          color: var(--secondary-color);
          margin: 0 0 0 6px;
        }
        @media screen and (max-width: 890px ) {
          font-size: 14px;
          margin-right: 16px;
          margin-left: 0;
          margin-left: 0;
        }
      }
    }
    .withdraw-btn {
      background-image: var(--primary-btn-gradient);
      color: var(--btn-text);
      border-radius: 55px;
      height: 40px;
      padding: 8px 30px;
      box-shadow: none;
      @media screen and (max-width: 890px ) {
       display: none;
      }
    }
    .deposit-btn {
      background-image: var(--secondary-btn-gradient);
      color: var(--btn-text);
      border-radius: 55px;
      height: 40px;
      padding: 8px 30px;
      box-shadow: none;
      @media screen and (max-width: 890px ) {
        display: none;
      }
    }
    .balance-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 580px ) {
        gap: 4px;
      }
      .balance-text {
        color: var(--text-color);
        font-size: 14px;
        opacity: 0.9;
        @media screen and (max-width: 580px ) {
          font-size: 10px;
          font-weight: 500;
        }
      }
      .header-balance {
        color: var(--text-color);
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        margin-right: 48px;
        margin-left: 15px;
        .metric {
          color: var(--secondary-color);
          margin: 0 0 0 6px;
        }
        @media screen and (max-width: 890px ) {
          font-size: 14px;
          margin-right: 16px;
          margin-left: 0;
        }
      }
    }
  }
  .panel-header-notifications {
    @media screen and (max-width: 890px ) {
     padding-left: 6px;
    }
    @media screen and (max-width: 510px ) {
      margin-bottom: 0;
    }
    .list-inline-item {
      cursor: pointer;
      @media screen and (max-width: 510px ) {
        padding: 0;
      }
      .dropdown {
        .dropdown-menu {
          padding: 0;
          border-radius: 15px;
        }
      }
      .mode-btn {
        width: 30px;
        @media screen and (max-width: 580px ) {
          width: 17px;
        }
      }
      .user-header {
        width: 30px;
        height: 30px;
        @media screen and (max-width: 580px ) {
          width: 20px;
          height: 20px;
        }
      }
      .header-img-notif {
        width: 20px;
        @media screen and (max-width: 580px ) {
          width: 14px;
        }
      }
    }
  }
}

.dragons-fight .app-main-content {
  &.panel-content-wrapper {
    padding: 15px 25px 25px 40px;
    @media screen and (max-width: 580px ) {
      padding: 6px 0;
    }
  }

  .landing-section {
    padding: 0 50px;
    @media screen and (max-width: 768px ) {
      padding: 0 10px;
    }
  }
}

//Live Chat PopOver
.dragons-fight .live-chat-pop-over {
  .MuiPopover-paper {
    width: 380px;
    left: 50px;
    bottom: 95px;
    //top: unset !important;
    padding: 0;
    border-radius: 25px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    .live-chat-card {
      background-color: var(--bg-light);
      border-radius: 25px;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 10px;
      height: 100%;
      //margin-top: 80px;

      .live-chat-card-header {
        display: flex;
        align-items: center;
        padding: 3px 9px 5px;
        justify-content: space-between;
        .right-side {
          display: flex;
          gap: 5px;
          align-items: center;
          .close-btn-wrapper {
            //position: absolute;
            .close-icon {
              color: var(--text-color);
            }
            .MuiButtonBase-root {
              padding: 5px;
            }
          }
          .live-chat-header-title {
            font-size: 15px;
            font-weight: 500;
            color: var(--text-color);
          }
        }
        .left-side {
          .end-chat-btn-wrapper {
            .end-chat-btn {
              background-image: var(--primary-btn-gradient);
            }
          }
        }

      }
      .live-chat-content-wrapper {
        height: 100%;
        .live-chat-loading-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
          .live-chat-loading-inner-wrapper {
            color: var(--primary);
            .live-chat-loading-content {
              font-size: 14px;
              font-weight: 600;
              color: var(--text-color);
            }
          }

        }
       .select-management-unit-wrapper {
         display: flex;
         flex-direction: column;
         gap: 15px;
         justify-content: space-between;
         height: 100%;
         .support-chats-wrapper {
           background-image: var(--fight-bg);
           padding: 10px 2px;
           flex: 1;
           border-radius: 5px;
           .chat-item {
             padding: 0 6px;
             align-items: baseline;
             .chat-profile {
               width: 30px;
             }
             .chat-bubble {
               background-color: var(--bg-lighter);
               border-radius: 5px;
               //width: 50%;
               padding: 5px 5px 2px;

               .chat-img {
                 width: 100%;
                 border-radius: 12px;
               }
               .chat-message {
                 color: var(--text-color);
                 opacity: 0.9;
                 font-size: 11px;
                 font-weight: 400;
                 text-align: initial;
               }
             }
           }
         }
         .form-wrapper {
           display: flex;
           flex-direction: column;
           gap: 8px;
           .button-wrapper {
             .confirm-btn {
               background-image: var(--secondary-btn-gradient);
             }
           }
         }
       }
        .live-chat-section-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          gap: 10px;
          .chat-list-scroll {
            flex: 1;
            background-image: var(--fight-bg);
            height: calc(93vh - 258px) !important;
            width: 100% !important;
            margin: 0 auto;
            border-radius: 12px;
            .chat-main-content {
              display: flex;
              flex-direction: column;
              gap: 5px;
              padding: 8px 0;
              .chat-item {
                padding: 0 15px;
                align-items: baseline;
                .chat-profile {
                  width: 30px;
                }
                .chat-bubble {
                  background-color: var(--bg-lighter);
                  border-radius: 5px;
                  width: 50%;
                  padding: 5px 5px 2px;

                  .chat-img {
                    width: 100%;
                    border-radius: 12px;
                  }
                  .chat-message {
                    color: var(--text-color);
                    opacity: 0.9;
                    font-size: 11px;
                    font-weight: 400;
                    text-align: initial;
                  }
                  .chat-footer {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 3px;
                    //&.admin-chat-footer {
                    //  justify-content: start;
                    //}
                    .chat-seen {
                      font-size: 11px;
                      font-weight: 500;
                      color: var(--text-color);
                      opacity: 0.9;
                    }
                    .chat-time {
                      font-size: 9px;
                      color: var(--primary);
                      direction: ltr;
                    }
                  }
                }
              }

            }

          }
          .chat-main-footer {
            width: 100%;
            margin: 0 auto;
            background-color: var(--bg);
            gap: 6px;
            padding:  0;
            border-top: unset;

            .ticket-compose-wrapper {
              display: flex;
              width: 100%;
              gap: 10px;
              .textarea-wrapper {
                flex: 1;
                .chat-text-area {
                  width: 100%;
                  height: 100% !important;
                  background-color: transparent;// var(--bg-lighter);
                  border-radius: 5px;
                  padding: 0 7px;
                  border: none;
                  color: var(--text-color);
                }
              }
              .buttons-wrapper {
                display: flex;
                flex-direction: column;
                gap: 0;
                justify-content: center;
                .file-btn {
                  color: var(--secondary-color);
                  //background-image: var(--primary-btn-gradient);
                }
                .submit-btn {
                  color: var(--secondary-color);
                  //background-image: var(--secondary-btn-gradient);
                }
              }
            }
            .chat-bottom-wrapper {
              position: absolute;
              bottom: 7px;
              left: 4%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row-reverse;
              padding:  0 0 3px 0;
              .file-badge {
                border-radius: 30px;
                background-color: var(--bg-lighter);
                color: var(--text-color);
                font-size: 10px;
                padding: 0 5px 0 0;
                .close-badge {
                  color: var(--secondary);
                  padding: 3px;
                  //background-color: var(--secondary);
                }
              }
              .chat-limit {
                color: var(--text-color);
                opacity: 0.8;
                font-size: 12px;
              }
            }
          }
        }
        .rate-step-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          gap: 10px;
          .support-chats-wrapper {
            background-image: var(--fight-bg);
            padding: 10px 2px;
            flex: 1;
            border-radius: 5px;
            .chat-item {
              padding: 0 6px;
              align-items: baseline;
              .chat-profile {
                width: 30px;
              }
              .chat-bubble {
                background-color: var(--bg-lighter);
                border-radius: 5px;
                //width: 50%;
                padding: 5px 5px 2px;

                .chat-img {
                  width: 100%;
                  border-radius: 12px;
                }
                .chat-message {
                  color: var(--text-color);
                  opacity: 0.9;
                  font-size: 11px;
                  font-weight: 400;
                  text-align: initial;
                }
              }
            }
          }
          .support-rate-section {
            display: flex;
            flex-direction: column;
            .rate-desc {
              font-size: 12px;
              color: var(--text-color);
              opacity: 0.9;
              font-weight: 400;
            }
            .rate-stars{
              text-align: center;
              display: flex;
              justify-content: center;
              .react-stars-wrapper {
                .react-stars {
                  padding: 15px;
                  span {
                    .MuiSvgIcon-root {
                      font-size: 32px;
                    }
                  }
                }
              }
            }
          }
          .buttons-wrapper{
            display: flex;
            gap: 10px;
            .confirm-btn{
            background-image: var(--primary-btn-gradient);
              span {
                .sending-button-text {
                  display: flex;
                  gap: 4px;
                  align-items: center;
                }
              }
            }
            .close-btn {
              background-image: var(--button-grey-dark);
            }

          }
        }
      }

    }
  }
}

//Live Chat
.dragons-fight .live-chat-wrapper {
  .live-chat-fab {
    position: fixed;
    bottom: 40px;
    left: 15px;
    z-index: 10;
    width: 40px;
    height: 40px;
    box-shadow: none;
    background-color: var(--bg);
    border: 1px solid var(--secondary);
    .live-chat-img-wrapper {
      border: 1px solid var(--primary);
      border-radius: 25px;
      padding: 1px;
      .live-chat-img{
        width: 40px;
        height: 40px;
        padding: 5px;
      }
    }
  }

}

//Referral Widget
.dragons-fight .referral-card-wrapper {
  padding: 0;
  box-shadow: none;
  border-radius: 55px;
  border: 1px solid transparent;
  background: var(--primary-secondary-gradient-border);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  margin-top: 80px;
  .referral-card-inner-wrapper {
    background-color: var(--bg-light);
    border-radius: 55px;
    display: flex;
    justify-content: center;
    text-align: center;
    .referral-link-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 20px;
      .img-wrapper {
        height: 195px;
        display: flex;
        @media screen and (max-width: 580px ) {
          margin-bottom: -40px;
        }
        svg {
          position: absolute;
          top: -80px;
        }
      }
      .referral-input-wrapper {
        .referral-input {
          .Mui-focused {
            border: unset;
          }
          .MuiOutlinedInput-root {
            border: 1px dashed var(--secondary);
            padding: 0;
            .MuiInputBase-input {
              color: var(--text-color);
            }
            .MuiButtonBase-root {
              color: var(--text-color);
              padding: 0 5px;
            }
          }
        }
      }
      .desc {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color);
      }
      .invited-friends {
        display: flex;
        gap: 5px;
        justify-content: center;
        .desc {
          font-size: 14px;
          font-weight: 600;
          color: var(--text-color);
          @media screen and (max-width: 580px ) {
            font-size: 14px;
          }
        }
        .value {
          font-size: 15px;
          font-weight: 700;
          color: var(--text-color);
          @media screen and (max-width: 580px ) {
            font-size: 15px;
          }
        }

      }
    }
  }
  .social-media-links-wrapper {
    position: absolute;
    transform: rotate(270deg);
    top: 100px;
    display: none;
    //display: flex;
    gap: 25px;
    left: -25px;

  }
}

//Social media wrapper
.dragons-fight .social-media-wrapper {
  background-color: var(--bg-light);
  padding: 20px;
  .media-row {
    display: flex;
    padding: 20px 0;
    justify-content: space-around;
    img {
      cursor: pointer;
    }
  }
  .horizontal-gradient {
    height: 3px;
    background-image: var(--fight-clear-bg);
  }
  .vertical-gradient {
    height: 3px;
    width: 250px;
    margin: auto;
    transform: rotate(270deg);
    background-image: var(--fight-clear-bg);
  }

}

//view MOde styles
.dragons-fight {
  .rules-dialog-wrapper {
    .MuiDialog-container {
      .MuiDialog-paper {
        .MuiDialogTitle-root {
          .MuiTypography-root {
            font-family: IranSans;
            @media screen and (max-width: 580px) {
             font-size: 14px;
            }

          }
        }
        .MuiDialogContent-root {
          opacity: 0.8;
          font-size: 12px;
          text-align: initial;
          @media screen and (max-width: 580px) {
            font-size: 10px;
            padding: 8px 4px;
          }
        }
      }
    }
    .MuiDialogTitle-root {
      justify-content: space-between;
    }
    .MuiDialogTitle-root h6, .MuiDialogContent-root div, p, .MuiDialogTitle-root svg {
      color: var(--text-color);
    }

  }
  .borders-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 35px 0 73px;
    position: absolute;
    width: 100%;
    top: 24px;
    z-index: -1;
    @media screen and (max-width: 510px) {
      padding: 0 ;
      top: -21px;
      position: relative;
      height: 0;
    }

    svg {
      height: 100px;
      @media screen and (max-width: 510px) {
       width: 100px;
      }
    }
  }
}
.gradient-bottom {
  height: 2px;
  background-image: var(--fight-clear-bg);
  @media screen and (max-width: 580px) {
  height: 1px;
  }
}
.tabs-bottom {
  position: absolute;
  bottom: 0;
  z-index: 3;
  width: 100%;
}
.view-mode-changer-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 6px 8px 0;
  //.mode-changer-btns-wrapper {
  gap: 10px;
  @media screen and (max-width: 510px) {
    padding: 13px 6px 5px 0;
  }
  .change-view-title {
    width: 95px;
    text-align: right;
    color: var(--text-color);
    font-size: 18px;
    font-weight: 600;
    @media screen and (max-width: 510px ) {
      font-size: 11px;
      width: 50px;
    }
  }
  .view-btn {
    flex: 1;
    box-shadow: none;
    background-color: var(--bg);
    border-radius: 25px;
    //height: 30px;
    font-size: 17px;
    padding: 3px 5px;
    &:hover {
      background-color: var(--bg-lighter);
    }
    @media screen and (max-width: 510px ) {
      padding: 4px 5px;
    }
    &.active {
      background-color: var(--bg-lighter);
      box-shadow: none;
      .mode-btn-label {
        color: var(--text-color);
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 510px ) {
          font-size: 10px;
        }
      }
    }
    .mode-btn-img {
      width: 15%;
      position: absolute;
      right: 8px;
      height: 68%;
      @media screen and (max-width: 510px ) {
        //width: 26px;
        //right: -3px;
        //top: 0

      }
    }
    .mode-btn-label {
      color: var(--text-color);
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 510px ) {
        font-size: 11px;
      }
    }
    .MuiButton-label {
      justify-content: center;
      width: 100%;
      .MuiButton-startIcon {
        margin-right: -3px;
        margin-left: 8px;

      }
    }
  }

}

.dragons-fight .user-detail-card {
  .statValue {
    color: var(--text-color);
  }
}

.dragons-fight .panel-calculator-wrapper {
  padding: 0;
  border-radius: 55px;
  border: 1px solid transparent;
  background: var(--primary-secondary-gradient-border);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  margin-top: 26px;
  box-shadow: none;
  margin-bottom: 0;
  //margin: 0;
  @media screen and (max-width: 510px) {
    margin-top: 25px;
    border-radius: 18px;
  }
  .calculator-inner-wrapper {
    background-color: var(--bg-light);
    border-radius: 55px;
    @media screen and (max-width: 510px) {
      border-radius: 10px;
      //height: calc(100vh - 365px);
    }
    .calculator {
      padding: 12px;
      @media screen and (max-width: 510px) {
       padding: 0;
      }
      .tab-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .calc-form {
          gap: 10px;
          @media screen and (max-width: 510px) {
            padding: 6px 11px;
          }
          .input-label {
            font-size: 15px;
            opacity: 0.9;
            @media screen and (max-width: 510px) {
              font-size: 12px;
            }
          }
          .fields-row-wrapper {
            display: flex;
            gap: 5px;
            .field-row {
              flex: 1;
              .MuiAutocomplete-root {
                .selected-currency {
                  background-color: var(--field-color);
                  border-radius: 8px;
                  padding: 6px 2px 6px 0;
                }
              }
            }
          }
          .field-set-bottom {
            @media screen and (max-width: 510px) {
             font-size: 12px;

            }
            .title {
              opacity: 0.8;
            }
          }
        }
        .button-section-wrapper {
          display: flex;
          gap: 5px;
          padding: 12px;
          @media screen and (max-width: 510px) {
            padding: 6px 11px;
          }
          .per-coin-price {
            flex: 1;
            font-size: 14px;
            @media screen and (max-width: 510px) {
             font-size: 12px;
            }

            .title {
              opacity: 0.8;
            }
          }
          .button-wrapper {
            flex: 1;
          }
        }
      }
      .per-coin-price {
        color: var(--text-color);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        span {
          text-align: center;
        }
      }
      .buy-btn {
        background-image: var(--secondary-btn-gradient);
        color: var(--btn-text);
        border-radius: 12px;
        padding: 12px;
      }
      .sell-btn {
        background-image: var(--primary-btn-gradient);
        color: var(--btn-text);
        border-radius: 12px;
        padding: 12px;
      }
      .calc-form {
        color: var(--text-color);
        padding: 12px 20px;
        .input-label {
          @media screen and (max-width: 510px) {
            font-size: 12px;
          }
        }
        .selected-currency {
          display: flex;
          align-items: center;
          //margin-right: 8px;
          padding: 6px 14px 6px 0;

          .coin-logo-converter {
            width: 20px;
            height: 22px;
            min-width: 22px;
          }
          .MuiFormLabel-root {
            margin-right: -32px;
            top: -8px;
          }
          .MuiInputBase-root {
            padding: 1px;
            font-size: 14px;
            @media screen and (max-width: 780px) {
              font-size: 12px;
            }
            input {
              text-align: right;
              direction: rtl;
              padding-right: 0;
            }
          }
        }
        .field-set-bottom {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 0 12px;
          font-size: 14px;
        }
        .MuiFormControl-root .MuiTextField-root
        label{
          color: var(--text-color);
        }
        .MuiInputBase-root{
          color: var(--text-color);
          @media screen and (max-width: 780px) {
            font-size: 12px;
          }
        }
        .tab span {
          font-weight: 700;
          font-size: 18px;
        }
        .coin-change-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          .success-change-v {
            transform: rotate(180deg);
          }
          .coin-changes {
            direction: ltr;
            width: fit-content;
            background-color: var(--field-color);
            border-radius: 25px;
            padding: 1px 5px;
            font-size: 18px;
            font-weight: 700;
            align-items: center;
            display: flex;
            justify-content: center;

            .add-icon {
              color: var(--secondary-color)
            }
            .remove-icon {
              color: #DB0E0E
            }

            img {
              //TODO we should change that img
              width: 62px
            }
          }
        }
        .logo-wrapper {
          display: flex;
          justify-content: space-between;
          direction: ltr;
          .price-wrapper {
            display: flex;
            flex-direction: column;
            text-align: left;
            font-size: 14px;
            justify-content: space-evenly;
            font-weight: 700;
          }
        }
        .MuiFormControl-root {
          background-color: var(--field-color);
          border-radius: 10px;
          .MuiOutlinedInput-notchedOutline {
            border: none;
          }
        }
        .calc-buy-btn {
          background-image: var(--secondary-btn-gradient);
          box-shadow: none;
          border-radius: 10px;
          font-size: 18px;
          @media screen and (max-width: 510px) {
            font-size: 16px;
          }
        }
        .calc-sell-btn {
          background-image: var(--primary-btn-gradient);
          box-shadow: none;
          border-radius: 10px;
          font-size: 18px;
          @media screen and (max-width: 510px) {
            font-size: 16px;
          }
        }
        .converter-total-row {
          justify-content: center;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: 700;
          .equal-icon {
            color: var(--secondary-color);
          }
        }
        .logo {
          width: 90px;
          height: 90px;
          background-size: 100%;
          background-repeat: no-repeat;
          background-image: url(https://ex24ir.com/core/storage/coins_sprite.png);
          background-position-y: 0;

        }
      }
      .calculator-tab {

        .tab {
          color: var(--text-color) !important;
          @media screen and (max-width: 510px) {
            font-size: 12px !important;
          }
        }
        .Mui-selected {
          opacity: 0.6;
        }
        .MuiTabs-indicator {
          background-color: var(--secondary-color) ;
        }
      }

    }

  }

}

// coin cards
.dragons-fight .coin-cards-wrapper {
  background-color: unset !important;
  box-shadow: none !important;
  border: 1px solid transparent;
  background: var(--primary-secondary-gradient-border);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-radius: 36px;
  margin-top: 26px;
  @media screen and (max-width: 510px) {
    margin-top: 25px;
    border-radius: 18px;
  }
  .sort-wrapper {
    .search-bar {
      box-shadow: none;
      background-color: var(--bg);
      height: 40px;
      div:first-of-type {
        .MuiInputBase-root {
          input {
            direction: rtl;
            color: var(--text-color);
          }
        }
      }
      .MuiButtonBase-root {
        .MuiIconButton-label {
          color: var(--text-color);
          .MuiSvgIcon-root {
            @media screen and (max-width: 510px) {
             font-size: 16px;
            }
          }
        }
      }
    }
  }
  .coin-cards-inner-wrapper {
    background-color: var(--bg);//darken(
    border-radius: 36px;
    padding: 6px;
    border-collapse: separate;
    border-spacing: 0 7px;
    @media screen and (max-width: 510px) {
     padding: 3px;
      height: calc(100vh - 365px);
    }
  }
  .coin-col-wrapper {
   padding: 8px;
    @media screen and (max-width: 510px) {
      padding: 5px;
    }
  }
  .coin-card {
    background-image: var(--bg-gradient);//darken(
    box-shadow: none;
    padding: 8px 11px;
    //margin: 7px;
    //padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 5px;
    @media screen and (max-width: 510px) {
      padding: 5px;
      gap: 10px;
    }

    .coin-card-inner{
      display: flex;
      padding: 0 4px;
      justify-content: space-between;
      @media screen and (max-width: 510px) {
        padding: 0 0 0 3px;
        gap: 5px;
      }
      .coin-name-wrapper{
        display: flex;
        align-items: center;
        gap: 9px;
        @media screen and (max-width: 510px) {
         gap: 0;
        }
        .coin-name {
          display: flex;
          flex-direction: column;
          gap: 6px;
          .name {
            font-weight: 500;
            font-size: 15px;
            color: var(--text-color);
            @media screen and (max-width: 510px) {
              font-size: 10px;
              font-weight: 600;
            }
          }
          .symbol {
            font-size: 11px;
            color: var(--text-color);
            opacity: 0.8;
            font-weight: 300;
            @media screen and (max-width: 510px) {
              font-size: 8px;
              font-weight: 500;
            }
          }
        }
      }
      .coin-price-wrapper{
        color: var(--text-color);
        display: flex;
        flex-direction: column;
        text-align: end;
        opacity: 0.9;
        font-size: 14px;
        font-weight: 400;
        gap: 6px;
        @media screen and (max-width: 510px) {
          font-size: 10px;
          font-weight: 600;
          gap: 4px;
        }
      }
    }
    .trade-buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      @media screen and (max-width: 510px ) {
      gap: 4px;
      }
    }
    .coin-name-wrapper {
      display: flex;
      .coin-logo {
        margin: 0 0 13px 13px;
        @media screen and (max-width: 510px ) {
          width: 20px;
          height: 20px;
          margin: 4px 0 4px 8px;
        }
      }
    }
  }
  .coin-name {
    color: var(--text-color) !important;
    white-space: nowrap;
    @media screen and (max-width: 510px ) {
      font-size: 13px;
    }
  }
  .buy-btn {
    border-radius: 10px;
    border: 2px solid var(--primary);
    background-color: transparent;
    //margin-right: 10px;
    box-shadow: none;
    padding: 3px 3px;
    width: 100%;
    @media screen and (max-width: 510px ) {
      padding: 0;
      border: 1px solid var(--primary);
      border-radius: 8px;
    }
    .MuiButton-startIcon {
      flex: 2;
      margin-right: 0;
      margin-left: 0;
    }
    .btn-label {
      flex: 0.8;
      //padding-left: 4px;
      font-weight: 600;
      //font-size: 17px;
      color: var(--text-color);
      @media screen and (max-width: 510px ) {
       font-size: 11px;
      }
    }
    .price-span {
      width: 100%;
      background-color: var(--bg);
      color: var(--text-color) !important;
      border-radius: 25px;
      font-size: 15px;
      font-weight: 600;
      padding-top: 2px;
      line-height: 1.5;

    }
  }
  .sell-btn {
    border-radius: 10px;
    border: 2px solid var(--secondary);
    background-color: transparent;
    //margin-right: 10px;
    box-shadow: none;
    padding: 3px 3px;
    width: 100%;
    @media screen and (max-width: 510px ) {
      border: 1px solid var(--secondary);
      padding: 0;
      border-radius: 8px;
    }
    .MuiButton-startIcon {
      flex: 2;
      margin-right: 0;
      margin-left: 0;
    }
    .btn-label {
      flex: 0.8;
      //padding-left: 4px;
      font-weight: 600;
      //font-size: 17px;
      color: var(--text-color);
      @media screen and (max-width: 510px ) {
       font-size: 11px;
      }
    }
    .price-span {
      width: 100%;
      background-color: var(--bg);
      color: var(--text-color) !important;
      border-radius: 25px;
      font-size: 15px;
      font-weight: 600;
      padding-top: 2px;
      line-height: 1.5;
    }
  }
  .coin-logo {
    background-position-y: 0;
    height: 32px;
    width: 32px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("https://ex24ir.com/core/storage/coins_sprite.png");
    @media screen and (max-width: 580px ) {
      width: 25px;
      height: 25px;
    }
    /*background-position-y: ;*/
  }
}

//price table & card styles
.dragons-fight
.price-table-card {
  box-shadow: none;
  background-color: unset;
  border: 1px solid transparent;
  background: var(--primary-secondary-gradient-border);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-radius: 36px;
  margin-top: 26px;
  @media screen and (max-width: 510px) {
    margin-top: 25px;
    border-radius: 18px;
    height: 400px;
  }
  .sort-wrapper {
    padding: 2px;
    .search-bar {
      box-shadow: none;
      background: var(--primary-secondary-gradient-border);
      height: 40px;
      width: 400px;
      @media screen and (max-width: 580px) {
       width: 100%;
      }
      div:first-of-type {
        .MuiInputBase-root {
          input {
            direction: rtl;
            color: var(--text-color);
          }
        }
      }
      .MuiButtonBase-root {
        .MuiIconButton-label {
          color: var(--text-color);
          .MuiSvgIcon-root {
            @media screen and (max-width: 510px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .price-table {
    @media screen and (max-width: 510px) {
      height: calc(100vh - 365px);
    }
    .price-table-head tr th {
      background-color: var(--bg-light);
      font-weight: 700;
      font-size: 14px;
      color: var(--text-color) !important;
      border-bottom: 1px solid var(--price-table-border);
      opacity: 0.8;
      @media screen and (max-width: 510px) {
        font-size: 11px;
      }
      :first-child {
        border-top-right-radius: 10px;
      }

      :last-child {
        border-top-left-radius: 10px;
      }
    }

    .price-table-body {
      tr td  .metric {
        color: var(--secondary-color);
        margin: 0 0 0 4px;
        @media screen and (max-width: 580px ) {
          margin: 0 0 0 1px;

        }
      }
      .coin-cell {
        white-space: nowrap;
        display: flex;
        justify-content: flex-start;
        @media screen and (max-width: 510px ) {
        align-items: center;
          text-align: inherit;
          gap: 3px;
        }
        .coin-logo {
          margin: 8px;
          background-size: 100%;
          background-repeat: no-repeat;
          @media screen and (max-width: 510px ) {
           margin: 2px;
            width: 25px;
            height: 25px;
          }

        }

        .coin-name {
          .name {
            font-weight: 700;
            font-size: 16px;
            @media screen and (max-width: 510px ) {
              font-size: 11px;
            }
          }

          .symbol {
            font-size: 12px;
            @media screen and (max-width: 510px ) {
              font-size: 10px;
            }
          }
        }
      }
      tr .MuiTableCell-root {
        border-bottom: 1px solid var(--price-table-border);
      }
      .price-wrapper {
        direction: ltr;
        .metric {
          color: var(--white);
        }
      }
      .trade-buttons {
        display: flex;
        justify-content: center;
        gap: 10px;
        @media screen and (max-width: 510px ) {
          gap: 6px;
        }

        .buy-btn {
          background-image: var(--secondary-btn-gradient);
          @media screen and (max-width: 510px ) {
            padding: 4px 7px 4px 2px;
            flex: 1;
          }
          .MuiButton-label {
            @media screen and (max-width: 510px ) {
             justify-content: space-between;
              font-size: 10px;
            }
            .price-wrapper {
              direction: ltr;
              .metric {
                color: var(--white);
              }
            }
            .title-wrapper {
              @media screen and (max-width: 510px ) {
                transform: rotate(325deg);
              }
            }
          }
        }

        .sell-btn {
          background-image: var(--primary-btn-gradient);
          @media screen and (max-width: 510px ) {
            padding: 4px 7px 4px 2px;
            flex: 1;
          }
          .MuiButton-label {
            @media screen and (max-width: 510px ) {
              justify-content: space-between;
              font-size: 10px;
            }
            .price-wrapper {
              direction: ltr;
              .metric {
                color: var(--white);
              }
            }
            .title-wrapper {
              @media screen and (max-width: 510px ) {
                transform: rotate(325deg);
              }
            }
          }
        }
      }

      tr:nth-of-type(odd) {
        background-color: var(--table-odd-bg);
        color: var(--text-color);

        td {
          //border-bottom: 2px solid var(--primary-light)
        }
      }

      tr:nth-of-type(even) {
        background-image: var(--bg-gradient);
        color: var(--text-color);

        td {
          //border-bottom: 2px solid var(--secondary-light)
        }
      }
    }
  }

}
.dragons-fight.sort-wrapper {
  background-color: var(--bg);
  border-radius: 25px;
  padding: 8px;
  margin: 19px 0 13px 0;
  @media screen and (max-width: 510px ) {
    margin: 12px 0 13px 0;
  }

  .search-bar {
    height: 35px;
    background-color: var(--bg);//darken(
    border-radius: 25px;
    box-shadow: none;
    .MuiInput-input::placeholder {
      color: var(--text-color);
      opacity: 1;
    }
    .MuiInput-input {
      text-align: right;
      color: var(--text-color);
      ::placeholder {
        color: var(--text-color);
        opacity: 1;
      }
      ::-ms-input-placeholder {
        color: var(--text-color);
      }
    }
  }
  .sort-input-wrapper {
    display: inline-flex;
    span {
      color: var(--text-color);
      display: flex;
    }
    .sort-select-input .MuiOutlinedInput-root {
      border-radius: 25px;
      fieldset {
        border: none;
      }
      .MuiOutlinedInput-inputMarginDense {
        padding-bottom: 5.5px;
        background-color: var(--bg);//darken(
        border-radius: 25px;
      }
    }
    span {
      justify-content: center; span {
      white-space: nowrap;
      margin: 0 10px;
      justify-content: center;
      align-content: center;
      align-items: center;
      display: flex;
      color: var(--text-color) !important;
    }

    }

  }
}

//User Pop up
.dragons-fight .user-pop-up-wrapper {
  padding: 12px;
  background-color: var(--bg-lighter);
  border-radius: 10px;
  .menu-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .menu-header {
      border-bottom: 2px solid var(--bg);
      font-weight: 600;
      color: var(--text-color);
      font-size: 16px;
      .user-name {
        margin: 0 0 10px 0;
        padding: 0 4px;
      }
    }
    .menu-item {
      color: var(--text-color);
      font-size: 14px;
      padding: 0 4px;
      text-decoration: none;
    }
    .menu-sign-out-btn {
      background-color: var(--secondary);
      background-image: var(--primary-btn-gradient);
      color: var(--btn-text);
      border-radius: 10px;
      height: 40px;
      padding: 8px 30px;
      box-shadow: none;
      font-size: 16px;
      margin: 4px 4px 0;
    }
  }
}

//Notifications Pop up
.dragons-fight .notice-dropdown {
  width: 275px;
  .notification-pop-up-wrapper {
    padding: 12px 10px;
    background-color: var(--field-color);
    border-radius: 10px;
    .menu-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .menu-header {
        color: var(--text-color);
        opacity: 0.8;
        font-size: 14px;
      }
      .menu-bottom {
        text-align: center;
      }
      .menu-body {
        display: flex;
        flex-direction: column;
        gap: 3px;
        overflow: auto;
        height: calc(100vh - 230px);
        color: var(--text-color);
        font-size: 12px;
        .notification-badge-title {
          display: flex;
          align-items: center;
          gap: 6px;
        }
        .media {
          margin: 0;
          background-color: var(--bg);
          padding: 4px 0;
          border-radius: 8px;
          //border-top: 1px solid var(--bg);
          .media-body {
            padding: 0 2px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .notification-message {
              font-size: 11px;
              opacity: 0.9;
              @media screen and (max-width: 510px ) {
                font-size: 11px;
              }

            }
            .notification-footer {
              font-size: 11px;
              opacity: 0.7;
              color: var(--text-color);
              text-align: end;
              @media screen and (max-width: 510px ) {
                font-size: 8px;
              }
              .notification-time {
                direction: ltr;
                text-align: left;
              }
            }
            .notification-badge-title {
              .notice-badge-wrapper {
                .MuiSvgIcon-root {
                  @media screen and (max-width: 510px ) {
                    width: 16px;
                  }
                }
              }
            }
          }
          &.seen-media {
            background-color: var(--field-color);
            border-radius: 3px;
          }
        }
      }
    }
  }
}


//Cancel Dialog
.dragons-fight .cancel-dialog {
  .cancel-dialog-inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 13px 13px;
    @media screen and (max-width: 580px) {
      gap: 10px;
    }
    .cancel-dialog-content {
      font-size: 16px;
      opacity: 0.8;
      @media screen and (max-width: 580px) {
       font-size: 13px;
      }
    }
  }}

//Notification Dialog
.dragons-fight .notification-dialog {
  .notification-dialog-title {
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    font-size: 16px;
    padding: 8px 0 0 0;
    @media screen and (max-width: 580px) {
      font-size: 13px;
    }
  }
  .notification-dialog-content-wrapper {
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 20px;
    color: var(--text-color);
    .notification-dialog-content {
      font-size: 15px;
      opacity: 0.9;
      text-align: initial;
      @media screen and (max-width: 580px) {
        font-size: 12px;
      }
    }
    .content-date {
      font-size: 12px;
      opacity: 0.8;
      //text-align: end;
      direction: ltr;
      text-align: left;
      @media screen and (max-width: 580px) {
        font-size: 9px;
      }
    }
  }
  .button-wrapper {
    padding: 0 21px 20px;
    @media screen and (max-width: 580px) {
      padding: 0 10px 8px;
    }
    .confirm-button-wrapper {
      .confirm-btn {
        background-image: var(--secondary-btn-gradient);
        color: var(--text-color);
        border-radius: 10px;
        span {
          color: var(--white);
          font-size: 16px;
          @media screen and (max-width: 1100px) {
            font-size: 12px;
          }
        }
      }
    }

  }
}

//notification
.dragons-fight .linear-notification-wrapper {
  background-color: var(--bg);
  padding: 7px 12px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin: 0 4% 21px 0.5%;
  margin-bottom: 8px;
  gap: 5px;
  @media screen and (max-width: 510px) {
    padding: 3px 8px;
    //margin: 7px 8px 5px;
  }
  .content-wrapper {
    display: flex;
    gap: 7px;
    overflow: hidden;
    align-items: center;
    width: 100%;
    .notification-bell {
      width: 30px;
      background-color: var(--white);
      z-index: 1;
      @media screen and (max-width: 510px) {
        width: 24px;
        margin-right: -3px;
      }
    }
    .notic-content {
      font-size: 16px;
      font-weight: 300;
      color: var(--text-color);
      white-space: nowrap;
      display: flex;
      gap: 24px;
      //width: 74vw;
      //overflow-x: overlay;
      //transition: width 2s;
      //animation: linearwipe 15s infinite;
      padding-right: 100%;
      animation-timing-function: linear;

      .notice-inner-content {
        gap: 6px;
        cursor: pointer;
      }
      .notification-title {
        font-size: 16px;
        font-weight: 400;
        color: var(--text-color);
        background-image: var(--fight-clear-bg);
        background-size: 100% 2px;
        background-repeat: no-repeat;
        background-position-y: bottom;
      }
      div {
        display: flex;
        align-items: center;
      }
      p {
        margin-bottom: 0;
        @media screen and (max-width: 510px) {
          font-size: 15px;
        }
      }
      @keyframes linearwipe{
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(100%, 0);
        }
      }
      @media screen and (max-width: 510px) {
        font-size: 12px;
      }
    }

  }


  .notice-close-icon {
    .close-icon {
      color: var(--background-darken5) ;
      font-size: 14px;
      background-color: var(--background-darken2);
      //padding: 2px;
      border-radius: 20px;
      @media screen and (max-width: 510px) {
        font-size: 12.5px;
      }
    }
  }
}

//Delete Credit Card Dialog
.dragons-fight .delete-dialog {
  .delete-dialog-inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 13px 13px;
    @media screen and (max-width: 580px) {
      gap: 10px;
    }
    .delete-dialog-content {
      font-size: 16px;
      opacity: 0.8;
      @media screen and (max-width: 580px) {
        font-size: 13px;
      }
    }
    .buttons-wrapper {
      display: flex;
      flex-wrap: nowrap;
      gap: 12px;
      .confirm-btn-wrapper {
        flex: 1;
        .confirm-btn {
          background-image: var(--secondary-btn-gradient);
          //color: var(--text-color);
          border-radius: 10px;
          font-size: 18px;
          span {
            .sending-button-text {
              display: flex;
              gap: 4px;
              align-items: center;
            }
            color: var(--white);
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }

      }
      .cancel-btn-wrapper {
        flex: 1;
        .cancel-btn {
          background-image: var(--button-grey-dark);
          color: var(--text-color);
          border-radius: 10px;
          font-size: 18px;
          span {
            color: var(--white);
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }}
//Panel Wallet Styles
.dragons-fight .wallet-card-wrapper {
  box-shadow: none;
  background-color: var(--bg);
  border: 1px solid transparent;
  background: var(--primary-secondary-gradient-border);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-radius: 55px;
  .wallet-card-inner {
    background-image: var(--fight-bg);
    color: var(--text-color);
    border-radius: 55px;
    .wallet-side {
      height: 0;
      .primary {
        position: relative;
        right: -65px;
        top: 24px;
        //@media screen and (max-width: 510px ) {
        //  right: -72px;
        //  top: 45px;
        //  height: 150px;
        //}
      }
      .main {
        position: relative;
        right: -200px;
        top: 1px;
        //@media screen and (max-width: 510px ) {
        //  right: -12px;
        //  top: -145px;
        //  height: 200px;
        //}
      }
    }
    .wallet-text-wrapper {
      padding: 45px 12px 7px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      min-height: 214px;
      justify-content: space-between;
      @media screen and (max-width: 510px ) {
        min-height: 200px;
        margin-bottom: -15px;
      }
      .wallet-portfilio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: var(--text-color);
          @media screen and (max-width: 510px ) {
            font-size: 20px;
          }
        }
        .amount {
          display: flex;
          gap: 6px;
          align-items: center;
          .value {
            font-size: 22px;
            font-weight: 600;
            color: var(--text-color);
            @media screen and (max-width: 510px ) {
              font-size: 18px;
            }
          }
          .metric {
            font-size: 16px;
            opacity: 0.8;
            color: var(--text-color);
            @media screen and (max-width: 510px ) {
              font-size: 14px;
            }
          }
        }
      }
      .frozen-amount {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 3px 14px;
        border-radius: 10px;
        background-image: var(--fight-bg);
        margin: auto;

        .desc {
          font-size: 13px;
          opacity: 0.9;
          color: var(--text-color);
        }
        .amount {
          display: flex;
          gap: 6px;
          align-items: center;
          .value {
            font-size: 17px;
            font-weight: 600;
            color: var(--text-color);
          }
          .metric {
            font-size: 12px;
            color: var(--text-color);
            opacity: 0.8;
          }
        }
      }
      .withdraw-limit {
        display: flex;
        align-items: center;
        justify-content: space-around;
        @media screen and (max-width: 510px ) {
          flex-flow: column;
        }
        .desc {
          font-size: 13px;
          opacity: 0.9;
          color: var(--text-color);
        }
        .amount {
          display: flex;
          gap: 6px;
          align-items: center;
          .value {
            font-size: 17px;
            font-weight: 600;
            color: var(--text-color);
          }
          .metric {
            font-size: 12px;
            color: var(--text-color);
            opacity: 0.8;
          }
        }
      }
    }
    .button-wrapper {
      height: 85px;
      //height: 100%;
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-wrap: nowrap;
      img {
        width: 100%;
        border-radius: 52px;
        /* height: 170px; */
        //position: absolute;
        //padding: 0 15px;
        //bottom: 1px;
        //left: 0;
      }
      .blue {
        color: white;
        border: 0;
        height: 84px;
        width: 57%;
        border-radius: 0 0 54px 0;
        margin-left: -25px;
        margin-right: 0px;
        background-image: var(--secondary-btn-gradient);
        -webkit-clip-path: polygon(-1% 33%, 100% 33%, 100% 100%, 20% 100%);
        clip-path: polygon(-1% 33%, 100% 33%, 100% 100%, 20% 100%);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-top: 20px;
        }
      }
      .blue-v-wrapper {
        img {
          position: absolute;
          top: 221px;
          left: 17px;
          width: 85%;
        }
      }
      //.blue::after {
      //  background-image: url("../../assets/images/V2/wallet-blue-vector.svg");
      //  content: "";
      //  display: block;
      //}
      .red {
        color: white;
        border: 0;
        height: 85px;
        width: 50%;
        border-radius: 0 0 0 51px;
        margin-right: 0px;
        background-image: var(--primary-btn-gradient);
        clip-path: polygon(0% 100%, 0% 66%, 12% 33.5%, 75.5% 32.5%, 100% 100%);
        position: absolute;
        left: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-top: 20px;
        }
      }
      .red-v-wrapper {
        img {
          position: absolute;
          left: 19px;
          width: 85%;
          top: 218px;
          margin-top: 14px;
        }
      }

      //.red::before {
      //  background-image: url("../../assets/images/V2/wallet-red-vector.svg");
      //  content: "";
      //  display: block;
      //}
    }
  }

}

//Panel PM Wallet Styles
.dragons-fight .pm-wallet-wrapper {
  box-shadow: none;
  background-color: var(--bg);
  border: 1px solid transparent;
  background: var(--bg);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-radius: 55px;
  height: 100%;
  .wallet-card-inner {
    background-image: linear-gradient(224deg, #ad0000, #ff3d3d);
    color: var(--text-color);
    border-radius: 55px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logo-wrapper {
      text-align: end;
      display: flex;
      justify-content: end;
      .inner-wrapper {
        background-color: white;
        border-radius: 39px 0;
        width: 48%;
        padding: 10px 10px 2px;
        img {
          width: 100%;
          padding: 0 7px;

        }
      }

    }
    .wallet-side {
      height: 0;
      .primary {
        position: relative;
        right: -65px;
        top: 24px;
        //@media screen and (max-width: 510px ) {
        //  right: -72px;
        //  top: 45px;
        //  height: 150px;
        //}
      }
      .main {
        position: relative;
        right: -200px;
        top: 1px;
        //@media screen and (max-width: 510px ) {
        //  right: -12px;
        //  top: -145px;
        //  height: 200px;
        //}
      }
    }
    .wallet-text-wrapper {
      padding: 12px 36px 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: space-between;
      @media screen and (max-width: 510px ) {
        padding: 20px 36px ;
        margin-bottom: -15px;
      }
      .wallet-portfilio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //align-items: center;
        gap: 16px;
        .title {
          font-size: 20px;
          font-weight: 400;
          color: var(--white);
          @media screen and (max-width: 510px ) {
            font-size: 16px;
          }
        }
        .amount {
          display: flex;
          gap: 6px;
          align-items: center;
          //padding-right: 12px;
          align-self: center;
          color: var(--white);
          .value {
            font-size: 22px;
            font-weight: 600;
            color: var(--white);
            @media screen and (max-width: 510px ) {
              font-size: 18px;
            }
          }
          .metric {
            font-size: 16px;
            opacity: 0.8;
            color: var(--white);
            @media screen and (max-width: 510px ) {
              font-size: 14px;
            }
          }
        }
      }
      .frozen-amount {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 3px 14px;
        border-radius: 10px;
        background-image: var(--fight-bg);
        margin: auto;

        .desc {
          font-size: 13px;
          opacity: 0.9;
          color: var(--text-color);
        }
        .amount {
          display: flex;
          gap: 6px;
          align-items: center;
          .value {
            font-size: 17px;
            font-weight: 600;
            color: var(--text-color);
          }
          .metric {
            font-size: 12px;
            color: var(--text-color);
            opacity: 0.8;
          }
        }
      }
      .withdraw-limit {
        display: flex;
        align-items: center;
        justify-content: space-around;
        @media screen and (max-width: 510px ) {
          flex-flow: column;
        }
        .desc {
          font-size: 13px;
          opacity: 0.9;
          color: var(--text-color);
        }
        .amount {
          display: flex;
          gap: 6px;
          align-items: center;
          .value {
            font-size: 17px;
            font-weight: 600;
            color: var(--text-color);
          }
          .metric {
            font-size: 12px;
            color: var(--text-color);
            opacity: 0.8;
          }
        }
      }
    }
    .button-wrapper {
      height: 85px;
      //height: 100%;
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-wrap: nowrap;
      img {
        width: 100%;
        border-radius: 52px;
        /* height: 170px; */
        //position: absolute;
        //padding: 0 15px;
        //bottom: 1px;
        //left: 0;
      }
      .blue {
        color: white;
        border: 0;
        height: 84px;
        width: 57%;
        border-radius: 0 0 54px 0;
        margin-left: -25px;
        margin-right: 0px;
        background-image: var(--purple-btn-gradient);
        -webkit-clip-path: polygon(-1% 33%, 100% 33%, 100% 100%, 20% 100%);
        clip-path: polygon(-1% 33%, 100% 33%, 100% 100%, 20% 100%);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-top: 20px;
        }
      }
      .blue-v-wrapper {
        img {
          position: absolute;
          top: 221px;
          left: 17px;
          width: 85%;
        }
      }
      //.blue::after {
      //  background-image: url("../../assets/images/V2/wallet-blue-vector.svg");
      //  content: "";
      //  display: block;
      //}
      .red {
        color: white;
        border: 0;
        height: 85px;
        width: 50%;
        border-radius: 0 0 0 51px;
        margin-right: 0px;
        background-image: var(--purple-btn-gradient);
        clip-path: polygon(0% 100%, 0% 66%, 12% 33.5%, 75.5% 32.5%, 100% 100%);
        position: absolute;
        left: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-top: 20px;
        }
      }
      .red-v-wrapper {
        img {
          position: absolute;
          left: 19px;
          width: 85%;
          top: 218px;
          margin-top: 14px;
        }
      }

      //.red::before {
      //  background-image: url("../../assets/images/V2/wallet-red-vector.svg");
      //  content: "";
      //  display: block;
      //}
    }
  }

}
//qr-code
.dragons-fight .qr-wrapper {
  padding: 8px;
  background-color: white;
}
//bottom Gradient
.dragons-fight .coin-card-gradient-bottom {
  height: 2px;
  background-image: var(--fight-clear-bg);
}
.dragons-fight .gradient-bottom {
  height: 2px;
  background-image: var(--fight-clear-bg);
  @media screen and (max-width: 580px) {
    height: 1px;
  }
}
//panel transaction ceiling
.dragons-fight .transaction-ceiling-wrapper {
  .transaction-ceiling-card-wrapper {
    background-color: var(--bg);
    color: var(--text-color);
    padding: 20px;
    border-radius: 50px 50px 0 0;
    @media screen and (max-width: 510px ) {
      display: flex;
      flex-direction: column;
      gap: 13px;
    }
    .heading {
      font-size: 18px;
      font-weight: 600;
      color: var(--text-color);
      @media screen and (max-width: 510px ) {
        font-size: 15px;
      }
    }
    .transaction-card-inner-wrapper {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 510px ) {
        flex-direction: column;
        gap: 14px;
      }
      .info-wrapper {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        justify-content: center;
        @media screen and (max-width: 510px ) {
          font-size: 12px;
          gap: 5px;
        }
        .info-row {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .desc {

          }
          .value-wrapper {
            display: flex;
            justify-content: end;
            gap: 4px;
            font-weight: 500;
          }
        }
      }
      .chart-btn-wrapper {
        display: flex;
        flex-direction: column;
        .chart {
          max-width: 250px;
          min-width: 250px;
          @media screen and (max-width: 510px ) {
            max-width: 280px;
            min-width: 280px;
          }
          .chartjs-tooltip {
            .chartjs-tooltip-body {
              .chartjs-tooltip-body-item {
                background-color: var(--bg);
                color: var(--text-color);
              }
            }
          }
        }
        .increase-buy-btn {
          span {
            color: var(--secondary-color) !important;
          }
        }
      }
    }

  }
}


//Authentication card
.dragons-fight .authentication-card-wrapper {
  padding: 0;
  border-radius: 55px;
  border: 1px solid transparent;
  background: var(--primary-secondary-gradient-border);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  @media screen and (max-width: 510px ) {
    padding-bottom: 25px;
  }
  .authentication-card-inner-wrapper {
    background-color: var(--bg-light);
    border-radius: 55px;
    .auth-sections {
      display: flex;
      width: 100%;
      height: 424px;
      color: var(--text-color);
      @media screen and (max-width: 510px ) {
        height: 300px;
      }
      .auth-img-wrapper-right {
        display: flex;
        flex-direction: column;
        position: absolute;
        //right: 35px;
        width: 50%;
        right: 16px;
        .mobile-section-wrapper {
          position: relative;
          .mobile-top {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 13px;
            top: 40px;
            right: 20%;
            @media screen and (max-width: 510px ) {
              top: 20px;
              gap: 8px;
              font-size: 13px;
              svg {
                width: 14px;
              }
            }
          }
          .mobile-bottom {
            position: absolute;
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            top: 107px;
            right: 20%;
            font-size: 14.5px;
            gap: 6px;
            @media screen and (max-width: 510px ) {
              font-size: 9.5px;
              top: 68px;
            }
          }
          .img-top-right {
            width: 100%;
            height: 212px;
            position: absolute;
            top: 35px;
            @media screen and (max-width: 510px ) {
              height: 196px;
              top: 0;
            }
          }
        }
        .email-section-wrapper {
          position: relative;
          .email-top {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 13px;
            top: 245px;
            right: 20%;
            @media screen and (max-width: 510px ) {
              top: 196px;
              gap: 8px;
              font-size: 13px;
              svg {
                width: 14px;
              }
            }
          }
          .email-bottom {
            position: absolute;
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            top: 313px;
            right: 20%;
            font-size: 14.5px;
            gap: 6px;
            @media screen and (max-width: 510px ) {
              top: 240px;
              font-size: 9.5px;
            }
          }
          .img-bottom-right {
            width: 100%;
            height: 212px;
            position: absolute;
            transform: scaleY(-1);
            top: 150px;
            @media screen and (max-width: 510px ) {
              height: 196px;
              top: 90px;
            }
          }
        }

      }
      .center-img-wrapper {
        margin: auto;
        z-index: 2;
        text-align: center;
        svg {
          @media screen and (max-width: 510px ) {
            width: 66px;
            height: 66px;
          }
        }
        .center-img {
          width: 150px;


        }
        .auth-title {
          text-align: center;
          @media screen and (max-width: 510px ) {
            font-size: 9px;
          }
        }
      }
      .auth-img-wrapper-left {
        position: absolute;
        display: flex;
        flex-direction: column;
        left: 18px;
        width: 50%;
        .document-section-wrapper {
          position: relative;
          .document-top {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 13px;
            top: 40px;
            right: 38%;
            @media screen and (max-width: 510px ) {
              top: 20px;
              gap: 8px;
              font-size: 13px;
              svg {
                width: 14px;
              }
            }
          }
          .document-bottom {
            position: absolute;
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            top: 107px;
            right: 38%;
            font-size: 14.5px;
            gap: 6px;
            @media screen and (max-width: 510px ) {
              top: 68px;
              font-size: 9.5px;
            }
          }
          .img-top-left {
            width: 100%;
            height: 212px;
            position: absolute;
            top: 35px;
            left: 0;
            transform: scaleX(-1);
            @media screen and (max-width: 510px ) {
              height: 196px;
              top: 0;
            }
          }
        }
        .residential-section-wrapper {
          position: relative;
          .residential-top {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 13px;
            top: 245px;
            //left: 88px;
            right: 38%;
            @media screen and (max-width: 510px ) {
              top: 196px;
              gap: 8px;
              font-size: 13px;
              svg {
                width: 14px;
              }
            }
          }
          .residential-bottom {
            position: absolute;
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            top: 313px;
            //left: 88px;
            right: 38%;
            font-size: 14.5px;
            gap: 6px;
            @media screen and (max-width: 510px ) {
              top: 240px;
              font-size: 9.5px;
            }
          }
          .img-bottom-left {
            transform: scale(-1);
            width: 100%;
            height: 212px;
            position: absolute;
            top: 150px;
            left: 0;
            @media screen and (max-width: 510px ) {
              height: 196px;
              top: 90px;
            }
          }
        }


      }
    }
  }
}

.dragons-fight .table-responsive-material {
  border: none;
  margin-bottom: 0 !important;
}

.dragons-fight .notice-table-wrapper {
  .MuiIconButton-label .MuiSvgIcon-root {
    color: var(--text-color);
  }
  .notice-text {
    color: var(--text-color);
  }
}

//
.dragons-fight .mobile-section-wrapper {
  @media screen and (max-width: 580px ) {
    padding: 0;

  }
}
.dragons-fight .mobile-notice-wrapper {
  @media screen and (max-width: 580px ) {
    padding: 5px 4px !important;

  }
}
//Wallet Summery Table
.dragons-fight .wallet-summery-table-wrapper {
  background-color: var(--bg);
  color: var(--text-color);
  padding: 20px;
  border-radius: 14px 14px 0 0;
  display: flex;
  flex-direction: column;
  gap: 13px;
  .jr-card {
    box-shadow: none;
    border-radius: 14px 14px 0 0;
    margin-bottom: 0 !important;
  }
  .heading {
    font-size: 15px;
    @media screen and (max-width: 510px ) {
     font-size: 12px;
    }
  }
  @media screen and (max-width: 510px ) {
   padding: 2px 6px;
  gap: 2px;
  }
  tbody {
    .MuiPaper-root {
      background-color: var(--table-odd-bg);
      .card-expand {
        color: var(--text-color);
      }
    }
    .MuiSvgIcon-root {
      color: var(--text-color);
    }
  }
  .bottom-link {
    a {
      text-decoration: none;
    }
  }
}

//Data Table Dashboard
.dragons-fight .datatable-wrapper {
  //.dashboard-data-table {
    .MuiTable-root {
      border-collapse: separate;
      border-spacing: 0 7px;
      margin-bottom: 0;
      .MuiTableHead-root {
         background-color: var(----table-head-bg);
         color: var(--text-color);
        tr th:first-child {
          border-radius: 0 8px 8px 0;
        }
        tr th:last-child {
          border-radius: 8px 0 0 8px;
        }
        tr th {
          border-bottom: unset;
          opacity: 0.8;
          @media screen and (max-width: 580px ) {
           font-size: 10px;

          }
        }
      }
      .MuiTableBody-root {
        td {
          border-bottom: 1px solid var(--bg-lighter);
          opacity: 0.9;
          .card {
            border: 1px solid var(--bg-lighter);
            border-radius: 14px;
          }
        }
        tr:nth-of-type(odd) {
          background-color: var(--table-odd-bg);
          color: var(--text-color);
        }
        tr:nth-of-type(even) {
          background-color: var(--bg-gradient);
          color: var(--text-color);
        }
        tr td:first-child {
          border-radius: 0 8px 8px 0;
        }
        tr td {
        border-bottom: unset;
          opacity: 0.8;
          .metric {
            color: var(--secondary-color);
            margin: 0 0 0 4px;
            @media screen and (max-width: 580px ) {
             margin: 0 0 0 1px;

            }
          }
          .MuiButtonBase-root {
            .MuiIconButton-label {
              .MuiSvgIcon-root {
                font-size: 20px;
                @media screen and (max-width: 580px ) {
                  font-size: 16px;

                }
              }
            }
          }
          @media screen and (max-width: 580px ) {
            font-size: 11px;

          }
        }
        tr td:last-child {
          border-radius: 8px 0 0 8px;
        }
      }
      .MuiToolbar-root {
        color: var(--text-color);
        .MuiSvgIcon-root, .MuiIconButton-root {
          color: var(--text-color);
        }
      }
      .MuiTableFooter-root {
        opacity: 0.8;
        tr td {
          border-bottom: unset;
          .MuiToolbar-root {
            button {
              @media screen and (max-width: 580px ) {
              padding: 8px;

              }
            }
            p {
              @media screen and (max-width: 580px ) {
                font-size: 10px;

              }
            }
          }
        }
      }
    }
  //}
}

//pagination menu
.dragons-fight .MuiPopover-root {
  .discount-menu-item {
    display: flex;
    gap: 3px;
    flex: 1;

    .discount-name {
      font-size: 12px;
      font-weight: 400;

    }
    .discount-value {
      font-size: 13px;
      font-weight: 500;
    }
    .discount-max {
      display: flex;
      gap: 3px;
      .desc-wrapper {
        display: flex;
        gap: 2px;
        .discount-max-desc {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .discount-max-desc {
        font-size: 12px;
        font-weight: 400;
      }
      .discount-max-number {
        font-size: 13px;
        font-weight: 700;
      }

    }
  }
  .MuiMenu-paper {
    background-color: var(--bg-light);
    color: var(--text-color);

  }
}

//- Bootstrap file Style
.dragons-fight {
  a,
  .card-link,
  .jr-link,
  .jr-link.text-primary {
    color: $app-primary-blue;
    &:focus,
    &:hover {
      color: darken($app-primary-blue, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-blue !important;
  }

  & .page-link {
    color: $app-primary-blue;
    &:focus,
    &:hover {
      color: $app-primary-blue;
    }
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-blue !important;
    color: $white !important;
  }

  & .btn-primary {
    background-color: $app-primary-blue;
    border-color: $app-primary-blue;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-blue, 5%) !important;
      border-color: darken($app-primary-blue, 5%) !important;
      color: $white !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #BBDEFB !important;
    }

    &.lighten-3 {
      background-color: #90CAF9 !important;
    }

    &.lighten-2 {
      background-color: #64B5F6 !important;
    }

    &.lighten-1 {
      background-color: #42A5F5 !important;
    }

    &.darken-1 {
      background-color: #1E88E5 !important;
    }

    &.darken-2 {
      background-color: #1976D2 !important;
    }

    &.darken-3 {
      background-color: #1565C0 !important;
    }

    &.darken-4 {
      background-color: #0D47A1 !important;
    }

    &.accent-1 {
      background-color: #82B1FF !important;
    }

    &.accent-2 {
      background-color: #448AFF !important;
    }

    &.accent-3 {
      background-color: #2979FF !important;
    }

    &.accent-4 {
      background-color: #2962FF !important;
    }
  }
}

//Secondary
.dragons-fight {
  .jr-link.text-secondary {
    color: $secondary-blue;
    &:focus,
    &:hover {
      color: darken($secondary-blue, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-blue !important;
  }

  //& .bg-secondary,
  //& .badge-secondary {
  //  background-color: $secondary-blue !important;
  //  color: $white !important;
  //}

  & .btn-secondary {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-blue, 5%) !important;
      border-color: darken($secondary-blue, 5%) !important;
      color: $white !important;
    }
  }
}

//_header.scss
.dragons-fight .app-main-header {
  padding: 0 50px 0 40px;
  background-color: unset !important;
  box-shadow: unset;
  @media screen and (max-width: 1100px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 580px) {
    padding: 0 0px;
  }
  &.landing-main-header {
    @media screen and (max-width: 580px) {
      padding: 0 10px;
    }
  }

  .header-logo-wrapper {
    @media screen and (min-width: 580px) {
      display: none !important;
    }
    @media screen and (max-width: 510px) {
      height: 73px;
    }
    background-color: var(--bg-lighter);
    height: 95px;
    z-index: 1300;
    border-radius: 0 0 0 49px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.fixed-logo-wrapper {
      width: 113%;
    }
    .logo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .logo-img {
        width: 60px;
        margin: auto;
        @media screen and (max-width: 510px) {
          width: 50px;
        }
      }
      .swipe-btn {
        .MuiIconButton-label {
          img {
            @media screen and (max-width: 510px) {
             width: 17px;
            }
          }
        }
        @media screen and (max-width: 580px) {
          display: block !important;
        }
        @media screen and (max-width: 1200px) {
          display: none;
        }
        &.fixed-swipe-btn {
          //transform: rotate(180deg);
        }
      }
    }
    img {
      //width: ;
    }
  }
  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
}

//home header
.dragons-fight .home-header {
  min-height: 107px !important;
  padding: 0;
  @media screen and (max-width: 768px) {
   min-height: 68px !important;
  }
  .header-burger-icon {
    min-width: 25px;
    .jr-menu-icon {
      .menu-icon {
        background-color: var(--text-color);
      }
    }
  }
.header-notifications {

  display: flex;
  gap: 25px;
  align-items: center;
  @media screen and (max-width: 768px) {
   gap: 2px;
  }
  .mode-btn {
    width: 25px;
  }
  .header-btn {
    background-image: var(--primary-btn-gradient);
    width: 120px;
    border-radius: 9px;
    font-size: 18px;
    color: var(--btn-text);
    padding: 5px 20px;
    @media screen and (max-width: 768px) {
      padding: 5px 13px;
      font-size: 13px;
    }
  }
  .app-mobile-logo {
    svg {
      height: 45px;
      width: 45px;
    }
    @media screen and (min-width: 768px) {
     display: none;
    }
  }
}
.app-main-menu{
  width: 48%;
  ul{
    display: flex;
    justify-content: space-evenly;
    li {
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
        .nav-link {
          color: var(--text-color);
          font-size: 18px;
          @media screen and (max-width: 1100px) {
           font-size: 15px;
          }
        }
      }

    }
  }

 }
}

//home header media
//@media screen and (min-width: 1700px) {
//  .dragons-fight .home-header {
//    min-height: 107px !important;
//    padding: 0;
//    .header-notifications {
//      display: flex;
//      gap: 25px;
//      align-items: center;
//      .mode-btn {
//        width: 25px;
//      }
//      .header-btn {
//        background-image: var(--primary-btn-gradient);
//        width: 120px;
//        border-radius: 9px;
//        font-size: 18px;
//        color: var(--btn-text);
//        padding: 5px 20px;
//      }
//    }
//    .app-main-menu{
//      width: 48%;
//      ul{
//        display: flex;
//        justify-content: space-evenly;
//        li {
//          display: flex;
//          align-items: center;
//          .nav-link {
//            color: var(--text-color);
//            font-size: 18px;
//          }
//        }
//      }
//
//    }
//  }
//}
//@media screen and (max-width: 1440px) and (min-width: 768px) {
//  .dragons-fight .home-header {
//    min-height: 107px !important;
//    padding: 0;
//    .header-notifications {
//      display: flex;
//      gap: 25px;
//      align-items: center;
//      .mode-btn {
//        width: 25px;
//      }
//      .header-btn {
//        background-image: var(--primary-btn-gradient);
//        width: 120px;
//        border-radius: 9px;
//        font-size: 18px;
//        color: var(--btn-text);
//        padding: 5px 20px;
//      }
//    }
//    .app-main-menu{
//      width: 48%;
//      ul{
//        display: flex;
//        justify-content: space-evenly;
//        li {
//          display: flex;
//          align-items: center;
//          .nav-link {
//            color: var(--text-color);
//            font-size: 18px;
//          }
//        }
//      }
//
//    }
//  }
//}
//@media screen and (max-width: 800px) {
//  padding-bottom: 135%;
//}

//calculator auto complete pop-over
.dragons-fight .panel-select-wallet-popover {
  .MuiAutocomplete-paper {
    margin: 0;
    .MuiAutocomplete-listbox {
      background-color: var(--bg);
      color: var(--text-color);
      padding: 0;
      .common-wallets-wrapper {
        display: flex;
        gap: 3px;
        .common-wallet-title {
          font-size: 12px;
          font-weight: 400;
        }
        .common-wallet-address {
          font-size: 12px;
          font-weight: 300;
        }
      }
      li {
        li {
          div {
            div {
              direction: rtl;
            }
          }
        }
      }


    }
  }
}
.dragons-fight .select-currency-popover {
  //background-color: var(--bg);
  //color: var(--text-color);
  //opacity: 0.9;
  min-width: 170px;
  z-index: 10;
  width: unset !important;
  .MuiAutocomplete-paper {
    margin: 0;
    .MuiAutocomplete-listbox {
      background-color: var(--bg);
      color: var(--text-color);
      padding: 0;
      li {
        padding: 2px 5px;
        .auto-complete-menu-item:hover{
          background-color: unset !important;
        }
        .auto-complete-menu-item {

          padding: 8px 3px;
          display: flex;
          gap: 3px;
          @media screen and (max-width: 580px) {
            padding: 3px 1px;
          }
          .coin-name-logo-wrapper {
            display: flex;
            gap: 3px;
            .coin-name {
              font-size: 13px;
              opacity: 0.9;
              @media screen and (max-width: 580px) {
               font-size: 11px;
              }
            }
          }
        }
      }


    }
  }


}

//panel select currency popover
.dragons-fight .panel-select-currency-popover {
  //background-color: var(--bg);
  //color: var(--text-color);
  //opacity: 0.9;
  min-width: 170px;
  z-index: 10;
  width: unset !important;
  .MuiAutocomplete-paper {
    margin: 0;
    .MuiAutocomplete-listbox {
      background-color: var(--bg);
      color: var(--text-color);
      padding: 0;
      li {
        padding:  5px;
        .auto-complete-menu-item:hover{
          background-color: unset !important;
        }
        .auto-complete-menu-item {

          padding: 8px 3px;
          display: flex;
          gap: 3px;
          @media screen and (max-width: 580px) {
            padding: 3px 1px;
          }
          .coin-name-logo-wrapper {
            display: flex;
            gap: 3px;
            .coin-name {
              font-size: 13px;
              opacity: 0.9;
              @media screen and (max-width: 580px) {
                font-size: 11px;
              }
            }
          }
        }
      }


    }
  }


}

// home greeting section
.dragons-fight .greeting-wrapper{
  background-image: url("../../assets/images/vectors/header vectors.svg");
  background-repeat: no-repeat;
  //display: flex;
  align-items: center;
  background-size: 100%;
  //min-height: 804px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    //padding: 7% 1%;
    background-image: unset !important;
  }
  .greeting-wrapper-inner {
    display: flex;
    box-sizing: border-box;
    margin: auto;
    padding: 17% 5% 21%;
    height: 120vh;
    flex-wrap: wrap;
    @media screen and (max-width: 1100px) {
      padding: 0;
      height: unset;
    }
    @media screen and (max-width: 768px) {

    }
    .home-red-img {
      width: 100%;
        @media screen and (min-width: 768px) {
          //padding: 7% 1%;
          display: none;
        }
      @media screen and (max-width: 768px) {
        margin-top: -185px;
      }
    }
    .greeting-slider-wrapper {
      .swiper {
        .swiper-pagination {
          .swiper-pagination-bullet {
            background-color: var(--text-color) !important;
            opacity: 0.7;
            &.swiper-pagination-bullet-active {
              background-color: var(--secondary-color) !important;
            }
          }
        }
      }
    }
  }

}

//calculator
.dragons-fight .greeting-calculator-wrapper {
  @media screen and (max-width: 768px) {
    margin-top: -40px;
  }
  .calculator-wrapper {
    padding: 0;
    border-radius: 55px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    box-shadow: none;
    @media screen and (max-width: 768px) {
      border-radius: 40px;
    }
    .calculator-inner-wrapper {
      background-color: var(--bg-light);
      border-radius: 55px;
      @media screen and (max-width: 768px) {
        border-radius: 40px;
      }
      .calculator {
        padding: 40px 20px;
        @media screen and (max-width: 768px) {
          padding: 8px 0;
        }
        .coin-info-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 6px;
        }
        .calculator-loading {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 39px;
        }
        .calc-form {
          color: var(--text-color);
          padding: 20px 20px 0 20px;
          .select-currency-wrapper {
            .selected-currency {
              display: flex;
              align-items: center;
              margin-right: 8px;

              .coin-logo-converter {
                width: 20px;
                height: 22px;
                min-width: 22px;
              }
              .MuiFormLabel-root {
                margin-right: -32px;
              }
              .MuiInputBase-root {
                padding: 1px;
                font-size: 14px;
                @media screen and (max-width: 780px) {
                  font-size: 12px;
                }

                input {
                  text-align: right;
                  direction: rtl;
                  padding-right: 0px;
                }
              }
            }
          }
          .MuiFormControl-root .MuiTextField-root
          label{
            color: var(--text-color);
          }
          .MuiInputBase-root{
            color: var(--text-color);
            padding: 0;
            @media screen and (max-width: 780px) {
              font-size: 12px;
            }
            input {
              padding: 10.5px;

            }
          }
          .tab span {
            font-weight: 700;
            font-size: 18px;
          }
          .coin-change-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 780px) {
              flex: 1;
              flex-direction: row;
              justify-content: center;
              gap: 10px;
            }
            .success-change-v {
              transform: rotate(180deg);
            }
            .coin-changes {
              direction: ltr;
              width: fit-content;
              background-color: var(--field-color);
              border-radius: 25px;
              padding: 1px 5px;
              font-size: 18px;
              font-weight: 700;
              align-items: center;
              display: flex;
              justify-content: center;

              .add-icon {
                color: var(--secondary-color)
              }
              .remove-icon {
                color: #DB0E0E
              }
              img {
                //TODO we should change that img
                width: 62px
              }
            }
          }
          .logo-wrapper {
            display: flex;
            justify-content: space-between;
            direction: ltr;
            .price-wrapper {
              display: flex;
              flex-direction: column;
              text-align: left;
              font-size: 14px;
              justify-content: space-evenly;
              font-weight: 700;
              padding: 0 4px 0 10px;
              flex: 1;
              opacity: 0.9;
              @media screen and (max-width: 510px) {
                font-size: 12px;
              }
              .calculator-total-title {
                direction: rtl;
                text-align: right;
              }
              .calculator-total {
                direction: rtl;
                text-align: left;
              }
            }
          }
          .MuiFormControl-root {
            background-color: var(--field-color);
            border-radius: 10px;
            .MuiOutlinedInput-notchedOutline {
              border: none;
            }
          }
          .calc-buy-btn {
            background-image: var(--secondary-btn-gradient);
            box-shadow: none;
            border-radius: 10px;
            font-size: 18px;
            @media screen and (max-width: 510px) {
              font-size: 16px;
            }
          }
          .calc-sell-btn {
            background-image: var(--primary-btn-gradient);
            box-shadow: none;
            border-radius: 10px;
            font-size: 18px;
            @media screen and (max-width: 510px) {
              font-size: 16px;
            }
          }
          .converter-total-row {
            justify-content: center;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            .metric {
              color: var(--secondary-color);
              margin: 0 0 0 4px;
            }
            @media screen and (max-width: 580px) {
             font-size: 10px;
              justify-content: space-evenly;
            }
            .equal-icon {
              color: var(--secondary-color);
              margin: 0 4px;
            }
          }
          .logo {
            width: 64px;
            height: 64px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url(https://ex24ir.com/core/storage/coins_sprite.png);
            background-position-y: 0;

          }
        }
        .calculator-tab {

          .tab {
            color: var(--text-color) !important;
          }
          .Mui-selected {
            opacity: 0.6;
          }
          .MuiTabs-indicator {
            background-color: var(--secondary-color) ;
          }
        }

      }

    }

  }
}


//swiper
.swiper .swiper-card {
  box-shadow: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 46px;
  @media screen and (max-width: 768px) {
    gap: 12px;
    padding: 7px;
  }
  .heading {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.4;
    color: var(--text-color);
    @media screen and (max-width: 768px) {
     font-size: 15px;
      text-align: center;
    }
  }
  .text{
    font-size: 16px;
    opacity: 0.8;
    font-weight: 400;
    color: var(--text-color);
    @media screen and (max-width: 768px) {
      font-size: 12px;
      text-align: center;
    }
  }
  .slider-btn-wrapper {
    display: flex;
    gap: 25px;
    @media screen and (max-width: 768px) {
     gap: 15px;
      justify-content: center;
    }
    .sign-up-btn {
      background-image: var(--button-grey-dark);
      font-size: 18px;
      font-weight: 700;
      padding: 9px 22px;
      border-radius: 10px;
      color: var(--btn-text);
      @media screen and (max-width: 768px) {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
    .login-btn {
      background-image: var(--primary-btn-gradient);
      font-size: 18px;
      font-weight: 700;
      padding: 9px 22px;
      border-radius: 10px;
      color: var(--btn-text);
      @media screen and (max-width: 768px) {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }
}

// coin show swiper
.dragons-fight
.coin-view-wrapper {
  padding: 20px 0;
  .swiper {
    .swiper-wrapper {
      height: 120px;
      .swiper-slide {
        display: flex;
        gap: 30px;
        height: 120px;
        @media screen and (max-width: 768px) {
          gap: 7px;
        }
        .coin-card-wrapper {
          flex: 1;
          border-radius: 7px;
          width: fit-content;
          border-bottom: 2px solid var(--secondary);
          &.change-plus {
            border-bottom: 2px solid var(--secondary-color);
          }
          .coin-card {
            background-image: var(--secondary-glow);
            box-shadow: none;
            background-color: unset;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .coin-card-body {
              display: flex;
              padding: 10px 10px 0 10px;
              justify-content: space-between;
              .coin-changes-wrapper {
                text-align: center;
                .coin-changes {
                  direction: ltr;
                  width: fit-content;
                  background-color: var(--secondary-more-light);
                  color: var(--text-color);
                  border-radius: 25px;
                  padding: 0 3px;
                  align-items: center;
                  display: flex;
                  justify-content: center;
                  font-size: 13px;
                  height: 21px;
                  @media screen and (max-width: 580px) {
                    font-size: 7px;
                    height: 18px;
                  }
                  .add-icon {
                    color: var(--secondary-color);
                    font-size: 14px;
                  }
                  .remove-icon {
                    color: #DB0E0E
                  }
                }
                .coin-changes-v {
                  @media screen and (max-width: 580px) {
                    width: 15px;
                  }
                  &.success-change-v {
                    transform: rotate(180deg);
                  }
                }
              }
              .coin-name-wrapper{
                display: flex;
                align-items: center;
                .coin-name-symbol {
                  display: flex;
                  flex-direction: column;
                  .coin-name {
                    font-size: 16px;
                    font-weight: 500;
                    opacity: 0.9;
                    color: var(--text-color);
                    @media screen and (max-width: 768px) {
                      font-size: 10px;
                    }
                  }
                  .coin-symbol {
                    font-size: 10px;
                    opacity: 0.8;
                    color: var(--text-color);
                    @media screen and (max-width: 768px) {
                      font-size: 10px;
                    }
                  }
                }
                .show-coin-logo {
                  //background-position-y: 0;
                  height: 40px;
                  width: 40px;
                  background-size: 100%;
                  background-repeat: no-repeat;
                  background-image: url("https://ex24ir.com/core/storage/coins_sprite.png");
                  @media screen and (max-width: 580px) {
                    height: 20px;
                    width: 20px;
                  }
                }
                .coin-change-wrapper {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .coin-changes {
                    direction: ltr;
                    width: fit-content;
                    background-color: var(--field-color);
                    border-radius: 25px;
                    padding: 1px 5px;
                    font-size: 18px;
                    font-weight: 700;
                    align-items: center;
                    display: flex;
                    justify-content: center;

                    .add-icon {
                      color: var(--secondary-color)
                    }
                    .remove-icon {
                      color: #DB0E0E
                    }
                  }
                }
                //padding: 10px 10px 0 10px;
              }

            }
            .price-wrapper {
              display: flex;
              margin: 0 8px 8px;
              @media screen and (max-width: 580px) {
                margin: 0 2px 2px;
              }
              .buy-price-wrapper {
                width: 50%;
                display: flex;
                justify-content: space-between;
                padding: 0 5px;
                @media screen and (max-width: 580px) {
                  flex-direction: column;
                  text-align: center;
                }
                .buy-label {
                  font-size: 13px;
                  color: var(--secondary-light);
                  @media screen and (max-width: 580px) {
                    font-size: 11px;
                  }
                }
                .price {
                  font-weight: 500;
                  font-size: 14px;
                  color: var(--text-color);
                  @media screen and (max-width: 580px) {
                    font-size: 10px;
                  }
                }
              }
              .sell-price-wrapper {
                width: 50%;
                display: flex;
                justify-content: space-between;
                padding: 0 5px;
                @media screen and (max-width: 580px) {
                  flex-direction: column;
                  text-align: center;
                }
                .sell-label {
                  font-size: 13px;
                  color: var(--primary-light);
                  @media screen and (max-width: 580px) {
                    font-size: 11px;
                  }
                }
                .price {
                  font-weight: 500;
                  font-size: 14px;
                  color: var(--text-color);
                  @media screen and (max-width: 580px) {
                    font-size: 10px;
                  }
                }
              }
            }

          }
        }

      }
    }
  }
}



//General Ticket styles
.dragons-fight .chat-module-box {
  background-color: transparent;
  box-shadow: none;
  gap: 20px;
  @media screen and (max-width: 580px) {
    padding: 0 10px;
  }
  .chat-box {
    flex: 1;
    height: calc(100vh - 130px);
    /* height: 100%; */
    overflow: hidden;
    padding: 0;
    border-radius: 35px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    @media screen and (max-width: 580px) {
      height: calc(100dvh - 90px);
    }
    @media screen and (max-width: 768px) {
      border-radius: 20px;
    }
    .tickets-btn {
      background-image: var(--primary-btn-gradient);
    }
    .chat-box-main {
      height: 100%;
      .chat-main {
        justify-content: space-between;
        height: 100%;
        .chat-main-header {
          border-bottom: unset;
          padding: 12px 20px 0 ;
          width: 100%;
          background-color: var(--bg);
          border-bottom: unset;
          @media screen and (max-width: 768px) {
           padding: 5px 13px 0;
          }
          .chat-main-header-inner-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            .chat-back-btn {
              .back-btn {
               color: var(--primary);
              }
            }
            .chat-close-btn-wrapper {
              .chat-close-btn {
                background-image: var(--primary-btn-gradient);
                color: var(--btn-text);
               .MuiButton-label {
                 @media screen and (max-width: 768px) {
                  font-size: 11px;
                 }
               }
              }
            }
            .chat-header-info {
              display: flex;
              flex-wrap: wrap;
              flex: 1;
              padding: 0 15px;
              justify-content: space-between;
              .chat-info-row {
                display: flex;
                gap: 5px;
                .info-title {
                  color: var(--secondary);
                  font-size: 12px;
                  @media screen and (max-width: 768px) {
                    font-size: 10px;
                  }
                }
                .info-value {
                  color: var(--text-color);
                  opacity: 0.9;
                  font-size: 12px;
                  direction: ltr;
                  @media screen and (max-width: 768px) {
                    font-size: 10px;
                  }
                }
              }

            }
          }
        }
        .chat-list-scroll {
          background-image: var(--fight-bg);
          height: calc(93vh - 258px) !important;
          width: 85% !important;
          margin: 0 auto;
          border-radius: 12px;
          @media screen and (max-width: 768px) {
            width: 93% !important;
          }
          .chat-main-content {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 8px 0;
            .chat-item {
              padding: 0 15px;
              align-items: baseline;
              @media screen and (max-width: 768px) {
               padding: 0 5px;
              }
              .chat-profile {
                width: 30px;
              }
              .chat-bubble {
                background-color: var(--bg-lighter);
                border-radius: 5px;
                width: 50%;
                padding: 5px 5px 2px;

                .chat-img {
                  width: 100%;
                  border-radius: 12px;
                }
                .chat-message {
                  color: var(--text-color);
                  opacity: 0.9;
                  font-size: 13px;
                  font-weight: 400;
                  @media screen and (max-width: 768px) {
                    font-size: 11px;
                  }
                }
                .chat-footer {
                  display: flex;
                  align-items: center;
                  justify-content: end;
                  gap: 3px;
                  &.admin-chat-footer {
                    justify-content: start;
                  }
                  .chat-seen {
                    font-size: 11px;
                    font-weight: 500;
                    color: var(--text-color);
                    opacity: 0.9;
                    @media screen and (max-width: 768px) {
                      font-size: 8px;
                    }
                  }
                  .chat-time {
                    font-size: 10px;
                    color: var(--primary);
                    direction: ltr;
                    @media screen and (max-width: 768px) {
                      font-size: 8px;
                    }
                  }
                }
              }
            }

          }

        }
        .chat-main-footer {
          width: 85%;
          margin: 0 auto;
          background-color: var(--bg);
          gap: 6px;
          padding:  0;
          border-top: unset;
          @media screen and (max-width: 768px) {
           width: 93%;
            margin-top: 5px;
          }

          .ticket-compose-wrapper {
            display: flex;
            width: 100%;
            gap: 10px;
            .textarea-wrapper {
              flex: 1;
              .chat-text-area {
                width: 100%;
                height: 100% !important;
                background-color: transparent;//var(--bg-lighter);
                border-radius: 5px;
                padding: 0 7px;
                border: none;
                color: var(--text-color);
              }
            }
            .buttons-wrapper {
              display: flex;
              //flex-direction: column;
              gap: 0;
              justify-content: center;
              @media screen and (max-width: 1200px) {
               flex-direction: column-reverse;
              }
              .file-btn {
                color: var(--secondary-color);
                //background-image: var(--primary-btn-gradient);
              }
              .submit-btn {
                color: var(--secondary-color);
                //background-image: var(--secondary-btn-gradient);
              }
            }
          }
          .chat-bottom-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding:  0 0 3px 0;
            .file-badge {
              border-radius: 30px;
              background-color: var(--bg-lighter);
              color: var(--text-color);
              font-size: 10px;
              padding: 0 5px 0 0;
              @media screen and (max-width: 768px) {
                font-size: 8px;
              }
              .close-badge {
                color: var(--secondary);
                padding: 3px;
                //background-color: var(--secondary);
              }
            }
            .chat-limit {
              color: var(--text-color);
              opacity: 0.8;
              font-size: 12px;
              @media screen and (max-width: 768px) {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
    .app-wrapper {
      padding: 0;
      background-color: var(--bg-light);
      //border-radius: 55px;
      display: flex;
      justify-content: center;
      text-align: center;
      height: calc(100% - 45px);
      overflow-y: auto;

      .common-questions-wrapper {

        //margin-top: -23px;
        .common-questions-inner-wrapper {
          padding: 20px;
          flex-direction: column;
          gap: 20px;
          @media screen and (max-width: 580px) {
           padding: 0;
          }
          .qa-title {
            font-size: 15px;
            font-weight: 500;
            color: var(--text-color);
            text-align: right;
            @media screen and (max-width: 580px) {
             font-size: 14px;
            }
          }
          .qa-list {
            .MuiCollapse-root {
              color: var(--text-color);
              font-size: 12px;
              font-weight: 300;
              opacity: 0.8;
              @media screen and (max-width: 580px) {
                font-size: 9px;
              }
            }
            .MuiButtonBase-root {
              font-size: 13px;
              color: var(--text-color);
              opacity: 0.7;
              @media screen and (max-width: 580px) {
                font-size: 10px;
              }
              .MuiListItemText-root {
                font-weight: 500;
              }
              img {
                width: 14px;
                @media screen and (max-width: 580px) {
                  width: 10px;
                }
                &.opened {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }
  .chat-sidenav {
    flex: 0.6;
    z-index: -2;
    min-width: unset;
    border-left: unset;
  }
}


//tickets drawer
.dragons-fight .tickets-drawer {
  .MuiDrawer-paper {
    background: transparent;
    background-image: unset !important;
    box-shadow: none;
    .chat-side-nav-wrapper {
      .chat-tabs-section-wrapper {

      }
    }
  }
}
//Chat Side Nav
.dragons-fight .chat-side-nav-wrapper {
  @media screen and (max-width: 1200px) {
   margin: 4px 4px 0 0;
  }
  .chat-tabs-section-wrapper {
    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;
      .MuiTabs-root {
        .MuiTabs-indicator {
          background-color: transparent;
        }
        .MuiTabs-flexContainer {
          background-color: var(--bg);
          .MuiTab-root {

            @media screen and (max-width: 1100px) {
              min-width: unset !important;
            }
            min-width: unset !important;
            .MuiTab-wrapper {
              flex-direction: row;
              gap: 4px;
              font-size: 12px;
              padding: 5px 15px;
              border-radius: 25px;
              background: var(--bg-gradient-light);
              color: #868e96;
              @media screen and (max-width: 1200px) {
                font-size: 11px;
                padding: 5px 10px;
                white-space: nowrap;
              }
              @media screen and (max-width: 470px) {
                font-size: 9px;
                flex-direction: column;
              }
              .tab-label {
                display: flex;
                gap: 10px;
                .name-wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  align-items: flex-start;
                  .title {
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--text-color);
                  }
                  .situation {
                    font-size: 14px;
                    font-weight: 500;
                  }
                  .text-danger {
                    color: var(--danger-text);
                  }
                  .text-primary {
                    color: var(--secondary);
                  }
                  .text-warning {
                    color: var(--warning-text);
                  }
                  .text-success {
                    color: var(--success-solid);
                  }

                }
              }
              img {
                width: 30px;
                @media screen and (max-width: 1100px) {
                  width: 15px;
                }
                @media screen and (max-width: 470px) {
                  width: 12px;
                }
              }
            }
            &.Mui-selected {
              .MuiTab-wrapper {
                background: unset;
                background-color: var(--bg-lighter);
                border-radius: 25px;
                padding: 5px 15px;
                //width: fit-content;
                font-size: 13px;
                font-weight: 700;
                color: var(--text-color);

                @media screen and (max-width: 1200px) {
                  font-size: 10px;
                  padding: 5px 10px;
                  white-space: nowrap;
                }
                @media screen and (max-width: 470px) {
                  font-size: 10px;
                  border-radius: 10px;
                  flex-direction: column;

                }
              }

            }
          }
        }
      }
    }
    .borders-wrapper {
      display: flex;
      justify-content: space-between;
      z-index: -1;
      margin-top: -34px;
      position: inherit;
      padding: 0 32px;
      img {
        height: 100px;
      }
      @media screen and (max-width: 1200px) {
       padding: 0;
      }

    }
    .MuiFormControl-root {
      background-color: var(--field-color);
      border-radius: 10px;

      .MuiFormLabel-root {
        color: var(--text-color);
        padding: 2px 12px;
        background-color: var(--field-color);
        border-radius: 9px;
        font-size: 16px !important;
        top: -2px;
        opacity: 1;
        @media screen and (max-width: 1100px) {
          font-size: 13px !important;
        }
        @media screen and (max-width: 510px) {
          font-size: 11px !important;
        }
        span {
          font-size: 16px;
          @media screen and (max-width: 1100px) {
            font-size: 13px !important;
          }
          @media screen and (max-width: 510px) {
            font-size: 11px !important;
          }
        }
      }
      .MuiInputBase-root {
        color: var(--text-color);
        padding-left: 0;
        //font-size: 18px;
        @media screen and (max-width: 780px) {
          font-size: 12px;
        }
        .MuiOutlinedInput-inputAdornedEnd {
          padding-left: 12px;
        }
        .input-end-adornment {
          background-color: var(--input-adornment-bg);
          padding: 10.5px 10px;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-color);
          border-radius: 10px 0 0 10px;
          &.next-end-adornment {
            border-radius: 0;
          }
          &.factor-end-adornment {
            padding: 7.5px 7px;
          }
          .MuiIconButton-label {
            .MuiSvgIcon-root {
              @media screen and (max-width: 510px) {
                font-size: 13px;
              }
            }
          }
          @media screen and (max-width: 1100px) {
            font-size: 10px;
            padding: 10.5px 4px;
          }
          :first-child {
            border-radius: 0;
          }
        }
        .input-end-adornment:last-child {
          border-radius: 10px 0 0 10px;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: unset;
          border-color: transparent;
        }
        .MuiOutlinedInput-root {
          .Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: transparent;
            }
          }
        }
      }
    }
    .tab-inside {
      padding: 0;
      border-radius: 35px;
      border: 1px solid transparent;
      background: var(--primary-secondary-gradient-border);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      margin-top: -23px;
      @media screen and (max-width: 1200px) {
       margin-top: 64px;
        border-radius: 20px;
      }
      .tab-inside-inner-wrapper {
        background-color: var(--bg-light);
        border-radius: 35px;
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 20px;
        flex-direction: column;
        gap: 20px;
        @media screen and (max-width: 1200px) {
          border-radius: 20px;
          gap: 10px;
          padding: 6px 1px;
        }
        .warning-box {
          background-color: var(--danger-bg);
          color: var(--danger-text);
          font-size: 13px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 10px;
          align-items: center;
          border-radius: 10px;
          text-align: initial;
          @media screen and (max-width: 1100px) {
            font-size: 10px;
            padding: 3px;
          }

        }
        .user-info-auth-wrapper {
          .info-form {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .inputs-wrapper {
              display: flex;
              flex-wrap: wrap;
              gap: 15px;

              div {
                flex: 1;
              }
            }
            .uploads-wrapper {
              display: flex;
              flex-wrap: wrap;
              gap: 15px;
              .upload-item {
                flex: 1;
                .MuiDropzoneArea-root {
                  background-color: transparent;
                  border-color: var(--secondary);
                  border-radius: 20px;
                  border-width: 2px;
                  padding: 4px;
                  @media screen and (max-width: 510px) {
                    min-height: 110px;
                    .MuiDropzonePreviewList-imageContainer {
                      bottom: -16px;
                    }
                  }
                  .MuiDropzonePreviewList-root {
                    .MuiChip-label {
                      max-width: 200px;
                    }
                    .MuiChip-root {
                      color: var(--text-color);
                      border-color: var(--text-color);
                      background-color: var(--bg);
                      svg {
                        color: var(--text-color);
                      }
                    }
                  }
                  .MuiDropzoneArea-textContainer {
                    .MuiTypography-root {
                      position: absolute;
                      top: 50%;
                      /* right: 25%; */
                      width: 100%;
                      .upload-text {
                        justify-content: center;
                        color: var(--text-color);
                        font-weight: 600;
                        font-size: 15px;
                        @media screen and (max-width: 510px) {
                          font-size: 11px;
                        }
                      }
                    }
                    height: 100%;
                    p {
                      margin: 0;
                    }

                    .MuiDropzoneArea-icon {
                      color: #182f4559;
                      width: 100%;
                      min-height: 243px;
                      @media screen and (max-width: 768px) {
                        min-height: unset;
                      }
                    }
                  }
                }
              }
            }
            .auth-confirm-btn-wrapper {
              .confirm-btn {
                background-image: var(--secondary-btn-gradient);
                //color: var(--text-color);
                border-radius: 10px;
                span {
                  color: var(--white);
                  font-size: 16px;
                  @media screen and (max-width: 1100px) {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        .react-swipeable-view-container {
          .chat-sidenav-scroll {
            height: calc(100vh - 326px) !important;
            >div:first-of-type {
              overflow: hidden auto !important;
            }
            @media screen and (max-width: 1100px) {
              height: calc(100vh - 153px) !important;
            }
            .chat-user {
              gap: 10px;
              @media screen and (max-width: 1100px) {
               gap: 4px;
              }
              .chat-user-item {
                padding: 0;
                border-bottom: unset;
                .chat-user-row {
                  display: flex;
                  gap: 10px;
                  padding: 4px 16px;
                  @media screen and (max-width: 1100px) {
                    gap: 3px;
                    padding: 4px 9px 4px 16px;
                  }
                  .chat-unit-wrapper {
                    //flex: 0.1;
                    display: flex;
                    flex-direction: column;
                    color: var(--text-color);

                  }
                  .chat-desc-wrapper {
                    flex: 1;
                    text-align: initial;
                    color: var(--text-color);
                    font-size: 13px;
                    font-weight: 300;
                    opacity: 0.9;
                    .unit-title {
                      font-weight: 500;
                    }
                    @media screen and (max-width: 1100px) {
                      font-size: 11px;
                    }
                  }
                  .chat-additional-info {
                    flex: 0.3;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    @media screen and (max-width: 1100px) {
                      gap: 5px;
                    }
                    .date-wrapper {
                      font-size: 12px;
                      direction: ltr;
                      color: var(--secondary-color);
                      white-space: nowrap;
                      @media screen and (max-width: 1100px) {
                        font-size: 9px;
                      }
                    }
                    .status-wrapper {
                      font-size: 12px;
                      @media screen and (max-width: 1100px) {
                        font-size: 9px;
                      }
                      .success-text {
                        color: var(--success-solid);
                      }
                      .warning-text {
                        color: var(--warning-text);
                      }
                      .danger-text {
                        color: var(--danger-text);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

//Compose Ticket
.dragons-fight  .confirm-btn-wrapper {
  .confirm-btn {
    background-image: var(--secondary-btn-gradient);
    //color: var(--text-color);
    border-radius: 10px;
    span {
      color: var(--white);
      font-size: 16px;
      @media screen and (max-width: 1100px) {
        font-size: 12px;
      }
    }
  }
}
.dragons-fight .compose-dialog-wrapper {
  padding: 10px 35px 20px;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
.title {
  font-weight: 500;
  font-size: 16px;
  color: var(--text-color);
  padding: 0 0 15px 0;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}
  .text-limit {
    color: var(--text-color);
    font-size: 14px;
    opacity: 0.9;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  .compose-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    @media screen and (max-width: 768px) {
      gap: 7px;
    }
    .main-form-wrapper {
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      justify-content: space-between;


    }
    .upload-wrapper {
      flex: 1 1 25%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
       flex: 0.5 1 25%;
      }
      .MuiDropzoneArea-root {
        background-color: transparent;
        border-color: var(--secondary);
        border-radius: 20px;
        min-height: unset;
        padding: 4px;
        .MuiDropzonePreviewList-root {
          .MuiChip-root {
            color: var(--text-color);
            border-color: var(--text-color);
            svg {
              color: var(--text-color);
            }
          }
        }
        .MuiDropzoneArea-textContainer {
          .MuiTypography-root {
            position: absolute;
            top: 50%;
            /* right: 25%; */
            width: 100%;
            .upload-text {
              justify-content: center;
              color: var(--text-color);
              font-weight: 600;
              font-size: 15px;
              opacity: 0.8;
              @media screen and (max-width: 510px) {
                font-size: 11px;
              }
            }
          }
          height: 100%;
          p {
            margin: 0;
          }

          .MuiDropzoneArea-icon {
            color: #182f4559;
            width: 100%;
            min-height: 198px;
            @media screen and (max-width: 768px) {
             min-height: 145px;
            }
          }
        }
      }
      .button-wrapper {
        .confirm-btn {
          background-image: var(--secondary-btn-gradient);
          //color: var(--text-color);
          border-radius: 10px;
          span {
            color: var(--white);
            font-size: 16px;
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.dragons-fight .add-wallet-dialog-wrapper {
  padding: 10px 35px 20px;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color);
    padding: 0 0 15px 0;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .text-limit {
    color: var(--text-color);
    font-size: 14px;
    opacity: 0.9;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  .add-wallet-form-wrapper {
    //display: flex;
    width: 100%;
    gap: 13px;
    @media screen and (max-width: 768px) {
      gap: 7px;
    }
    .main-form-wrapper {
      flex-direction: column;
      display: flex;
      gap: 10px;
      height: 100%;
      justify-content: space-between;
      .button-wrapper {
        .confirm-btn {
          background-image: var(--secondary-btn-gradient);
          //color: var(--text-color);
          border-radius: 10px;
          span {
            color: var(--white);
            font-size: 16px;
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }
      }
      .wallet-input-wrapper {
        flex: 1;
      }
      .inputs-wrapper  {
        display: flex;
        gap: 10px;
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
        .title-input-wrapper {
          flex: 1;
          .MuiFormControl-root {
            .MuiInputBase-root {
              input {
                text-align: right;
                direction: rtl;
              }
            }
          }
        }
        .network-input-wrapper {
          flex: 1;
          .MuiAutocomplete-root {
            .selected-currency {
              .MuiFormControl-root {
                .MuiInputBase-root {
                  input {
                    padding-left: 40px;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
}

//Profile Setting Section
.dragons-fight .profile-section {
  .common-wallets-red-border-wrapper {
    width: fit-content;
    position: inherit;
    z-index: -1;
    margin: -114px auto -114px 270px;
  }
  .red-border-wrapper {
    width: fit-content;
    position: inherit;
    z-index: -1;
    margin: -114px auto -114px 0;
  }
  .blue-border-wrapper {
    width: fit-content;
    margin: -114px 0;
    z-index: -1;
    position: inherit;
  }
  .change-password-wrapper {
    height: 100%;
    padding: 0;
    border-radius: 55px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    @media screen and (max-width: 580px) {
      border-radius: 30px;
    }
    .change-password-inner-wrapper {
      background-color: var(--bg-light);
      border-radius: 55px;
      display: flex;
      height: 100%;
      justify-content: space-between;
      //text-align: center;
      padding: 20px;
      flex-direction: column;
      gap: 20px;
      @media screen and (max-width: 580px) {
        border-radius: 30px;
        gap: 10px;
        padding: 10px;
      }
      .pass-input .MuiSvgIcon-root {
        color: var(--text-color);
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color);
        opacity: 0.9;
        @media screen and (max-width: 580px) {
         font-size: 15px;
        }
      }
      .button-wrapper {
        .secondary-btn {
          background-image: var(--primary-btn-gradient);
          color: var(--text-color);
          border-radius: 10px;
          span {
            color: var(--white);
            font-size: 16px;
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .login-setting-wrapper {
    padding: 0;
    height: 100%;
    border-radius: 55px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    @media screen and (max-width: 580px) {
      border-radius: 30px;
    }
    .login-setting-inner-wrapper {
      height: 100%;
      justify-content: space-between;
      background-color: var(--bg-light);
      border-radius: 55px;
      display: flex;
      //justify-content: center;
      //text-align: center;
      padding: 20px;
      flex-direction: column;
      gap: 20px;
      @media screen and (max-width: 580px) {
       gap: 10px;
        border-radius: 30px;
        padding: 10px;
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color);
        opacity: 0.9;
        @media screen and (max-width: 580px) {
         font-size: 15px;
        }

      }
      .alert-box {
        background-color: var(--bg);
        color: var(--secondary);
        font-size: 13px;
        font-weight: 300;
      }
      .two-step-wrapper {
       .MuiList-root {
         .MuiListItem-container {
           .MuiListItemSecondaryAction-root {
             .MuiSwitch-root {
               .MuiSwitch-track {
                 background-color: var(--danger-bg) !important;
                 opacity: 1;
               }
               .MuiButtonBase-root {
                 .MuiIconButton-label {
                   color: var(--secondary) !important;

                 }
               }
             }
           }
         }
       }
      }
      .two-step-method-wrapper {
       .MuiFormControl-root {
         background-color: transparent;
         .MuiFormGroup-root{
           flex-direction: row;
         }
       }
      }
      .button-wrapper {
        .confirm-btn {
          background-image: var(--secondary-btn-gradient);
          //color: var(--text-color);
          border-radius: 10px;
          span {
            color: var(--white);
            font-size: 16px;
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .profile-card-wrapper {
    background-color: var(--bg);
    color: var(--text-color);
    padding: 20px;
    //border-radius: 50px 50px 0 0;
    display: flex;
    justify-content: center;
    gap: 17px;
    @media screen and (max-width: 580px) {
     padding: 8px;
      gap: 3px;
    }
    .profile-info-section {
      display: flex;
      font-size: 15px;
      direction: ltr;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 580px) {
        font-size: 11px;
        text-align: center;
      }
    }
    .profile-img-wrapper {
      .profile-img-wrapper {
        border: 1px dashed var(--secondary-color);
        border-radius: 40px;
        padding: 6px;
        @media screen and (max-width: 580px) {
          border-radius: 50px;
        }
        svg {
          width: 53px;
          @media screen and (max-width: 580px) {
            width: 45px;
          }
        }
      }
    }
  }
  .common-wallets-table-container {
    padding: 0;
    border-radius: 45px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    @media screen and (max-width: 580px) {
      border-radius: 30px;
    }
    .common-wallets-table-inner-wrapper {
      background-color: var(--bg-light);
      border-radius: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 8px 12px 23px;
      gap: 20px;
      text-align: initial;
      @media screen and (max-width: 1100px) {
        padding: 12px;
      }
      @media screen and (max-width: 580px) {
        padding: 10px;
        gap: 12px;
        border-radius: 30px;
      }
      .common-wallets-title-wrapper {
        display: flex;
        //justify-content: space-between;
        gap: 10px;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        color: var(--text-color);
        margin: 6px 10px 0;

        @media screen and (max-width: 1100px) {
          font-size: 14px;
          margin: 3px 7px 0;
        }
        @media screen and (max-width: 580px) {
          font-size: 13px;
          margin: 4px 5px 0;
        }
      }

    }
  }
}
//Notification Slider
.dragons-fight .notification-swiper {
  .notification-card-wrapper {
    padding: 0;
    border-radius: 32px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    @media screen and (max-width: 580px) {
      border-radius: 30px;
    }
    .notification-card-inner-wrapper {
      background-color: var(--bg-light);
      border-radius: 32px;
      display: flex;
      justify-content: center;
      padding: 8px 12px 23px;
      flex-direction: column;
      gap: 20px;
      text-align: initial;
      @media screen and (max-width: 1100px) {
        padding: 12px;
      }
      @media screen and (max-width: 580px) {
        padding: 10px;
        gap: 12px;
        border-radius: 30px;
      }
      .notification-title {
        font-size: 15px;
        font-weight: 500;
        color: var(--text-color);
        @media screen and (max-width: 1100px) {
          font-size: 14px;
        }
        @media screen and (max-width: 580px) {
          font-size: 13px;
        }
      }
      p {
        font-size: 13px;
        margin-bottom: 0;
        opacity: 0.9;
        @media screen and (max-width: 1100px) {
          font-size: 10px;
          font-weight: 300;
          text-align: justify;
          line-height: 2.1;
        }
      }
      .notification-desc {
        font-size: 13px;
        font-weight: 300;
        color: var(--text-color);
        opacity: 0.9;
        @media screen and (max-width: 580px) {
          font-size: 10px;
        }
        p {
          margin-bottom: 0;
          opacity: 0.9;
          @media screen and (max-width: 1100px) {
            font-size: 12px;
            font-weight: 300;
            text-align: justify;
            line-height: 2.1;
          }
        }

      }
    }
  }
  .swiper-pagination {
    bottom: 0;
    .swiper-pagination-bullet {
      background-color: var(--text-color) !important;
      opacity: 0.7;
      &.swiper-pagination-bullet-active {
        background-color: var(--secondary-color) !important;
      }
    }
  }
}


.dragons-fight {
  .selfie-sample-dialog {
    .MuiDialog-container {
      .MuiPaper-root:first-of-type {
        margin: 32px 43px;
        .MuiCardHeader-root {
          display: none;
          .MuiTypography-root {
            color: var(--text-color);
            font-size: 16px;
            @media screen and (max-width: 580px) {
              font-size: 12px;
            }
          }
          .MuiCardContent-root {
            font-size: 13px !important;
          }
        }
        .MuiCard-root {
          background-color: transparent;
        }
      }
    }


    .MuiTypography-root {
      color: var(--text-color);
    }
  }
}

//Date Picker
.dragons-fight .JDatePicker {
  z-index: 9999999 !important;
  box-shadow: none !important;
  background-color: var(--bg) !important;
  border-radius: 21px !important;
  border: 1px dotted var(--bg-lighter)!important;
  .JC-Section {

  }
  .JC-Section-Year {
    border-radius: 20px !important;
    background-color: var(--secondary) !important;
  }
  .JC-days {
    background-color: var(--bg-lighter) !important;
    .holder {
      .day-items:hover {
        background-image: var(--secondary) !important;
      }
      .day-items.selected {
        background-image: var(--secondary) !important;
      }
      .day-items.today {
        background-color: var(--secondary) !important;
      }
      .day-items {
        color: var(--text-color) !important;
        opacity0.9
        :hover {
          background-color: var(--secondary) !important;
        }
        .selected {
          background-color: var(--secondary) !important;
        }
        .today {
          background-color: var(--secondary) !important;
        }
      }
    }
  }
  .JC-Buttons {
    button {
      border-radius: 18px;
      background-color: var(--primary-btn-gradient) !important;
      color: var(--white)!important;
      border: 1px solid var(--bg)!important;
    }

  }

}

.dragons-fight .remaining-buy-progress {
  .progress {
    height: 100%;
  }
  p {
    font-size: 13px !important;
    color: var(--text-color)
  }
}
//sending
.dragons-fight .progress-btn-inner {
  display: flex;
  align-items: center;
  .sending-btn-text {
    color: var(--white) !important;
    opacity: 0.8;
    font-size: 14px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 580px) {
      font-size: 12px;
    }
  }
}


//Authorization
.dragons-fight {
  .auth-steps {
    .swal2-popup {
      background-color: var(--bg);
      z-index: unset !important;
      padding: 0;
      .swal2-header {
        .swal2-title {
          color: var(--text-color);
          font-size: 16px;
        }
      }
      .swal2-content {
        color: var(--text-color);
        opacity: 0.9;
        font-size: 14px;
      }
    }
    .first-section-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 50%;
      text-align: initial;
      margin: auto;
      @media screen and (max-width: 768px) {
        width: 85%;
      }
      .desc {
        font-size: 15px;
        font-weight: 500;
        color: var(--text-color);
        opacity: 0.9;
        @media screen and (max-width: 768px) {
         font-size: 11px;
        }
      }
      .confirm-btn-wrapper {
        .confirm-btn {
          background-image: var(--secondary-btn-gradient);
          //color: var(--text-color);
          border-radius: 10px;
          span {
            color: var(--white);
            font-size: 16px;
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }
      }
    }
    .second-section-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 50%;
      text-align: initial;
      margin: auto;
      @media screen and (max-width: 768px) {
        width: 85%;
      }
      .desc {
        font-size: 15px;
        font-weight: 500;
        color: var(--text-color);
        opacity: 0.9;
        @media screen and (max-width: 768px) {
          font-size: 11px;
        }
      }
      .buttons-wrapper {
        display: flex;
        flex-wrap: nowrap;
        gap: 5px;
        .confirm-btn-wrapper {
          flex: 2;
          .confirm-btn {
            background-image: var(--secondary-btn-gradient);
            //color: var(--text-color);
            border-radius: 10px;
            span {
              .sending-button-text {
                display: flex;
                gap: 4px;
                align-items: center;
              }
              color: var(--white);
              @media screen and (max-width: 1100px) {
                font-size: 12px;
              }
            }
          }

        }
        .cancel-btn-wrapper {
          flex: 1;
          .cancel-btn {
            background-image: var(--button-grey-dark);
            color: var(--text-color);
            border-radius: 10px;
            span {
              color: var(--white);
              @media screen and (max-width: 1100px) {
                font-size: 12px;
              }
            }
          }
        }
      }

    }
    .warning-box {
      margin-bottom: 15px;
    }
  }
  .auth-tabs-section-wrapper {
    @media screen and (max-width: 580px) {
      padding: 10px;
    }
    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;
      .MuiTabs-root {
        .MuiTabs-indicator {
          background-color: transparent;
        }
        .MuiTabs-flexContainer {
          background-color: transparent;
          .MuiTab-root {
            min-width: unset !important;
            flex: 1;
            padding: 6px 6px;
            .MuiTab-wrapper {
              flex-direction: row;
              gap: 4px;
              font-size: 14px;
              padding: 5px 15px;
              @media screen and (max-width: 1100px) {
                font-size: 11px;
              }
              @media screen and (max-width: 470px) {
                font-size: 9px;
                flex-direction: column;
              }
              @media screen and (max-width: 880px) {
               padding: 2px 5px;
              }
              .tab-label {
                display: flex;
                gap: 10px;
                align-items: center;
                width: 100%;
                @media screen and (max-width: 880px) {
                 gap: 5px;
                  flex-direction: column;
                  align-items: center;
                }
                .auth-level {
                  padding: 7px 10px;
                  text-align: initial;
                  font-size: 12px;
                  color: var(--white);
                  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
                  flex: 1;
                  background-image: var(--primary-btn-gradient);
                  &.verified-auth-level {
                    background-image: var(--secondary-btn-gradient);
                  }
                  @media screen and (max-width: 880px) {
                   padding: 3px 10px 3px 0;
                    font-size: 7px;
                    width: 100%;
                  }
                }
                .img-section-wrapper {
                  flex: 1;
                  display: flex;
                  gap: 7px;
                  svg {
                    @media screen and (max-width: 880px) {
                      width: 18px;
                    }
                  }
                  .name-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    @media screen and (max-width: 880px) {
                      gap: 2px;

                    }
                    align-items: center;
                    .title {
                      font-size: 16px;
                      font-weight: 600;
                      color: var(--text-color);
                      @media screen and (max-width: 880px) {
                        font-size: 12px;
                      }
                    }
                    .situation {
                      font-size: 14px;
                      font-weight: 500;
                      @media screen and (max-width: 880px) {
                        font-size: 10px;
                      }
                    }
                    .text-danger {
                      color: var(--danger-text);
                    }
                    .text-primary {
                      color: var(--secondary);
                    }
                    .text-warning {
                      color: var(--warning-text);
                    }
                    .text-success {
                      color: var(--success-solid);
                    }

                  }
                }

              }
              img {
                width: 30px;
                @media screen and (max-width: 1100px) {
                  width: 15px;
                }
                @media screen and (max-width: 470px) {
                  width: 12px;
                }
              }
            }
            &.Mui-selected {
              .MuiTab-wrapper {
                background-image: var(--fight-bg);
                border-radius: 25px;
                padding: 5px 15px;
                //width: fit-content;
                font-size: 15px;
                font-weight: 700;
                @media screen and (max-width: 1100px) {
                  font-size: 11px;
                }
                @media screen and (max-width: 580px) {
                  font-size: 10px;
                  flex-direction: column;
                  border-radius: 10px;
                  padding: 2px 5px;
                }
              }

            }
          }
        }
      }
    }
    .borders-wrapper {
      display: flex;
      justify-content: space-between;
      z-index: -1;
      margin-top: -22px;
      margin-bottom: -2px;
      position: inherit;
      padding: 0 32px;
      img {
        height: 100px;
      }
    }
    .MuiFormControl-root {
      background-color: var(--field-color);
      border-radius: 10px;

      .MuiFormLabel-root {
        color: var(--text-color);
        padding: 2px 12px;
        background-color: var(--field-color);
        border-radius: 9px;
        font-size: 16px !important;
        top: -2px;
        opacity: 1;
        @media screen and (max-width: 1100px) {
          font-size: 13px !important;
        }
        @media screen and (max-width: 510px) {
          font-size: 11px !important;
        }
        span {
          font-size: 16px;
          @media screen and (max-width: 1100px) {
            font-size: 13px !important;
          }
          @media screen and (max-width: 510px) {
            font-size: 11px !important;
          }
        }
      }
      .MuiInputBase-root {
        color: var(--text-color);
        padding-left: 0;
        &.MuiOutlinedInput-adornedStart  {
          padding-right: 0;
        }

        @media screen and (max-width: 780px) {
          font-size: 12px;
        }
        //font-size: 18px;
        .MuiOutlinedInput-inputAdornedEnd {
          padding-left: 12px;
        }
        .input-end-adornment {
          background-color: var(--input-adornment-bg);
          padding: 10.5px 10px;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-color);
          border-radius: 10px 0 0 10px;
          &.next-end-adornment {
            border-radius: 0;
          }
          &.factor-end-adornment {
            padding: 7.5px 7px;
          }
          .MuiIconButton-label {
            .MuiSvgIcon-root {
              @media screen and (max-width: 510px) {
                font-size: 13px;
              }
            }
          }
          @media screen and (max-width: 1100px) {
            font-size: 10px;
            padding: 10.5px 4px;
          }
          :first-child {
            border-radius: 0;
          }
        }
        .input-end-adornment:last-child {
          border-radius: 10px 0 0 10px;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: unset;
          border-color: transparent;
        }
        .MuiOutlinedInput-root {
          .Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: transparent;
            }
          }
        }
      }
    }
    .tab-inside {
      padding: 0;
      border-radius: 55px;
      border: 1px solid transparent;
      background: var(--primary-secondary-gradient-border);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      margin-top: -23px;
      @media screen and (max-width: 580px) {
        border-radius: 30px;
      }
      @media screen and (max-width: 510px) {
        margin-top: 60px;
      }
      .tab-inside-inner-wrapper {
        background-color: var(--bg-light);
        border-radius: 55px;
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 20px;
        flex-direction: column;
        gap: 20px;
        @media screen and (max-width: 580px) {
          border-radius: 30px;
          padding: 16px 10px;
        }
        .progress-box {
          background-color: var(--warning-bg);
          color: var(--text-color);
          font-size: 13px;
          font-weight: 400;
          display: flex;
          //flex-direction: column;
          gap: 5px;
          padding: 10px;
          align-items: center;
          border-radius: 10px;
          text-align: initial;
          //align-items: flex-start;
          @media screen and (max-width: 580px) {
            font-size: 10px;
            padding: 3px;
          }
          img {
            width: 30px;
            @media screen and (max-width: 580px) {
              width: 14px;
            }
          }
        }
        .warning-box {
          background-color: var(--danger-bg);
          color: var(--danger-text);
          font-size: 13px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 10px;
          //align-items: center;
          border-radius: 10px;
          text-align: initial;
          align-items: flex-start;
          @media screen and (max-width: 580px) {
            font-size: 10px;
            padding: 3px;
          }
        }
        .user-info-auth-wrapper {
          .info-form {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .inputs-wrapper {
              display: flex;
              flex-wrap: wrap;
              gap: 15px;
              justify-content: center;
              @media screen and (max-width: 510px) {
                gap: 12px;
              }
              &.postal-code-inputs-wrapper {
                flex-direction: column;
              }
              .address-input {
                flex: 1;
                width: 100%;
                .MuiFormControl-root {
                  .MuiInputBase-root {
                    input {
                      direction: rtl;
                    }
                  }
                }
              }
              .phone-postal-code-wrapper {
                display: flex;
                width: 100%;
                gap: 5px;
                .phone-input {
                  flex: 1;
                }
                .postal-code-input {
                  flex: 1;
                }
              }
              .province-city-wrapper {
                display: flex;
                width: 100%;
                gap: 5px;
                @media screen and (max-width: 375px) {
                 flex-direction: column;
                }
                .province-input {
                  flex: 1;
                }
                .city-input {
                  flex: 1;
                }
              }
              //.phone-input {
              //  flex: 1 1 24%;
              //  input {
              //    direction: ltr;
              //    text-align: left;
              //  }
              //}
              //.postal-code-input {
              //  flex: 1 1 24%;
              //  input {
              //    direction: ltr;
              //    text-align: left;
              //  }
              //}
              //.address-input {
              //  flex: 3 3 34%;
              //  input {
              //    direction: rtl;
              //    text-align: right;
              //  }
              //}
              .gender-input {
                width: 100px;
                @media screen and (max-width: 510px) {
                  width: 46%;
                }
              }
              .first-name-input {
                width: 24%;
                @media screen and (max-width: 510px) {
                  width: 46%;
                }
              }
              .last-name-input {
                width: 24%;
                @media screen and (max-width: 510px) {
                  width: 46%;
                }
              }
              .father-name-input {
                width: 24%;
                @media screen and (max-width: 510px) {
                  width: 46%;
                }
              }
              .birthdate-input {
                width: 28%;
                @media screen and (max-width: 510px) {
                  width: 46%;
                }
                .MuiFormControl-root {
                  .MuiInputBase-root {
                    .date-input-with-dialog-main {
                      .date-input-with-dialog-calendar {
                        top: calc(50% - 0.5em) !important;
                      }
                      .date-input-with-dialog-empty {
                        top: calc(50% - 0.5em) !important;
                      }
                      .date-input-with-dialog-input-buttons {
                        width: 16px !important;
                        max-height: 16px !important;
                        color: var(--text-color) !important;
                        background-color: var(--bg) !important;
                        border: 1px solid var(--secondary) !important;
                      }
                    }
                  }
                }
              }
              .certificate-id-input {
                width: 28%;
                @media screen and (max-width: 510px) {
                  width: 46%;
                }
              }
              .national-code-input {
                width: 28%;
                @media screen and (max-width: 510px) {
                  width: 46%;
                }
              }
              //div {
              //  flex: 1;
              //}
            }
            .uploads-wrapper {
              display: flex;
              flex-wrap: wrap;
              gap: 15px;
              .upload-item {
                flex: 1 1 32%;
                .MuiDropzoneArea-root {
                  background-color: transparent;
                  border-color: var(--secondary);
                  border-radius: 20px;
                  border-width: 2px;
                  padding: 4px;
                  @media screen and (max-width: 510px) {
                    min-height: 110px;
                    .MuiDropzonePreviewList-imageContainer {
                      bottom: -16px;
                    }
                  }
                  .MuiDropzonePreviewList-root {
                    .MuiChip-label {
                      max-width: 200px;
                      font-size: 13px;
                      padding: 0;
                      @media screen and (max-width: 510px) {
                       max-width: 100px;
                        font-size: 10px;
                      }
                    }
                    .MuiChip-root {
                      color: var(--text-color);
                      border-color: var(--text-color);
                      background-color: var(--bg);
                      opacity: 0.8;
                      height: 24px;
                      svg {
                        color: var(--text-color);
                        margin: 0;
                      }
                    }
                  }
                  .MuiDropzoneArea-textContainer {
                    .MuiTypography-root {
                      position: absolute;
                      top: 50%;
                      /* right: 25%; */
                      width: 100%;
                      .upload-text {
                        justify-content: center;
                        color: var(--text-color);
                        font-weight: 600;
                        font-size: 15px;
                        @media screen and (max-width: 510px) {
                          font-size: 11px;
                        }
                      }
                    }
                    height: 100%;
                    p {
                      margin: 0;
                    }

                    .MuiDropzoneArea-icon {
                      color: #182f4559;
                      width: 100%;
                      min-height: 243px;
                      @media screen and (max-width: 768px) {
                        min-height: unset;
                      }
                    }
                  }
                }
              }
            }
            .auth-confirm-btn-wrapper {
              .confirm-btn {
                background-image: var(--secondary-btn-gradient);
                color: var(--white);
                border-radius: 10px;
                span {
                  color: var(--white);
                  font-size: 16px;
                  @media screen and (max-width: 1100px) {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }

      }
    }
  }
  .alert-box {
    background-color: var(--bg);
    color: var(--secondary);
    font-size: 13px;
    font-weight: 300;
  }
}


//rules Dialog

//Deposit Dialog
.dragons-fight .deposit-tabs-section-wrapper {
  .tabs-wrapper {
    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        @media screen and (max-width: 510px) {
          min-width: unset !important;
        }

      }
    }
  }
  .wallet-balance-wrapper {
    padding: 20px;
    text-align: center;
    background-image: var(--fight-bg);
    margin: 20px;
    display: flex;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 7px;
    color: var(--text-color);
    @media screen and (max-width: 1100px) {
     padding: 7px;
      margin: 10px 20px;

    }
    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      img {
        width: 30px;
        @media screen and (max-width: 1100px) {
         width: 15px;

        }
      }
      .title {
        font-size: 14px;
        font-weight: 300;
        @media screen and (max-width: 1100px) {
        font-size: 11px;

        }
      }
    }
    .value-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      .value {
        font-size: 16px;
        font-weight: 700;
        @media screen and (max-width: 1100px) {
          font-size: 14px;

        }
      }
      .metric {
        font-size: 13px;
        font-weight: 300;
        @media screen and (max-width: 1100px) {
          font-size: 10px;

        }
      }
    }
  }
  .online-form-wrapper {
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 17px;
    @media screen and (max-width: 510px) {
      gap: 10px;
      margin: 7px;
    }
    .rules-check-wrapper {
      text-align: right;
      font-size: 12px;
      @media screen and (max-width: 510px) {
        padding-bottom: 8px;
      }
    }
    .inputs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 510px) {
        gap: 6px;
      }
      .desc {
        color: var(--text-color);
        font-weight: 300;
        font-size: 13px;
        padding: 0 8px 14px 0;
        white-space: nowrap;
        text-align: initial;
        opacity: 0.7;
        @media screen and (max-width: 1100px) {
          font-size: 10px;
        }
        @media screen and (max-width: 470px) {
          font-size: 8px;
        }
      }
      .card-amount-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        @media screen and (max-width: 510px) {
          gap: 6px;
          flex-direction: column;
        }
        .select-card-input {
          flex: 1;
        }
        .amount-input {
          flex: 1;
        }
      }
      .gate-way-wrapper {
        flex: 1;
        .gate-way-radios-wrapper {
          width: fit-content;
          margin: auto;

          padding: 0;
          height: 100%;
          border-radius: 25px;
          border: 1px solid transparent;
          background: var(--primary-secondary-gradient-border);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          @media screen and (max-width: 580px) {
            border-radius: 10px;
          }

          .gate-ways-inner-wrapper {
            padding: 10px 15px;
            height: 100%;
            justify-content: space-between;
            background-image: var(--bg-gradient);
            background-color: var(--bg-light);
            border-radius: 25px;
            @media screen and (max-width: 580px) {
              //gap: 10px;
              border-radius: 10px;
              padding: 7px;
            }
            .MuiFormControl-root {
              width: fit-content;
              background-color: transparent;
              .MuiFormGroup-root {
                flex-direction: unset;
                flex-wrap: wrap;
                justify-content: center;
                gap: 10px;
                @media screen and (max-width: 510px) {
                  gap: 5px;
                }
                .MuiFormControlLabel-root {
                  flex-direction: row;
                  border-radius: 16px;
                  padding: 10px 0 10px 10px;
                  align-items: center;
                  margin: 0;
                  background-color: var(--bg-lighter);

                  @media screen and (max-width: 510px) {
                    padding: 6px;
                    border-radius: 10px;
                  }
                  @media screen and (max-width: 580px) {
                    flex: 1;
                    justify-content: space-around;
                  }
                  .MuiButtonBase-root {
                    @media screen and (max-width: 580px) {
                      padding: 3px;
                    }
                    .MuiIconButton-label {
                      .MuiSvgIcon-root {
                        color: var(--text-color);
                        @media screen and (max-width: 580px) {
                          font-size: 14px;
                        }
                      }
                    }
                  }
                  .MuiTypography-root {
                    .gate-way-label {
                      .gate-way-logos {
                        width: 60px;
                        height: 60px;
                        @media screen and (max-width: 510px) {
                          width: 28px;
                          height: 28px;
                        }
                      }
                      .gate-way-title {
                        color: var(--text-color);
                        font-size: 14px;
                        @media screen and (max-width: 510px) {
                          font-size: 11px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }
    }
    .bottom-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .card-direct-wrapper {
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 17px;
    @media screen and (max-width: 510px) {
      gap: 10px;
      margin: 7px;
    }
    .rules-check-wrapper {
      text-align: right;
      font-size: 12px;
      @media screen and (max-width: 510px) {
        padding-bottom: 8px;
      }
    }
    .inputs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 510px) {
        gap: 6px;
      }
      .desc {
        color: var(--text-color);
        font-weight: 300;
        font-size: 13px;
        padding: 0 8px 14px 0;
        white-space: nowrap;
        text-align: initial;
        opacity: 0.7;
        @media screen and (max-width: 1100px) {
          font-size: 10px;
        }
        @media screen and (max-width: 470px) {
          font-size: 8px;
        }
      }
    }
    .bottom-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 510px) {
        gap: 6px;
      }
    }
  }
  .offline-wrapper {
    padding: 2px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 510px) {
      gap: 10px;
      padding: 7px;
    }
    .gate-way-wrapper {
      flex: 1;
      .desc {
        text-align: start;
      }
      .generate-btn {
        background-image: var(--primary-btn-gradient);
        box-shadow: none;
        border-radius: 16px;
        display: flex;
      }
      .gate-way-radios-wrapper {
        width: fit-content;
        margin: auto;

        padding: 0;
        height: 100%;
        border-radius: 25px;
        border: 1px solid transparent;
        background: var(--primary-secondary-gradient-border);
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        @media screen and (max-width: 580px) {
          border-radius: 10px;
        }

        .gate-ways-inner-wrapper {
          padding: 10px 15px;
          height: 100%;
          justify-content: space-between;
          background-image: var(--bg-gradient);
          background-color: var(--bg-light);
          border-radius: 25px;
          @media screen and (max-width: 580px) {
            //gap: 10px;
            border-radius: 10px;
            padding: 7px;
          }
          .MuiFormControl-root {
            width: fit-content;
            background-color: transparent;
            .MuiFormGroup-root {
              flex-direction: unset;
              flex-wrap: wrap;
              justify-content: center;
              gap: 10px;
              @media screen and (max-width: 510px) {
                gap: 5px;
              }
              .MuiFormControlLabel-root {
                flex-direction: row;
                border-radius: 16px;
                padding: 10px 0 10px 10px;
                align-items: center;
                margin: 0;
                background-color: var(--bg-lighter);

                @media screen and (max-width: 510px) {
                  padding: 6px;
                  border-radius: 10px;
                }
                @media screen and (max-width: 580px) {
                  flex: 1;
                  justify-content: space-around;
                }
                .MuiButtonBase-root {
                  @media screen and (max-width: 580px) {
                    padding: 3px;
                  }
                  .MuiIconButton-label {
                    .MuiSvgIcon-root {
                      color: var(--text-color);
                      @media screen and (max-width: 580px) {
                        font-size: 14px;
                      }
                    }
                  }
                }
                .MuiTypography-root {
                  .gate-way-label {
                    .gate-way-logos {
                      width: 60px;
                      height: 60px;
                      @media screen and (max-width: 510px) {
                        width: 28px;
                        height: 28px;
                      }
                    }
                    .gate-way-title {
                      color: var(--text-color);
                      font-size: 14px;
                      @media screen and (max-width: 510px) {
                        font-size: 11px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }
  .offline-form-wrapper{
    padding: 2px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 510px) {
      gap: 10px;
      padding: 7px;
    }
    .desc-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .offline-desc-wrapper {
        padding: 0;
        border-radius: 35px;
        border: 1px solid transparent;
        background: var(--primary-secondary-gradient-border);
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        //margin-top: -23px;
        &.little {
          border-radius: 23px;
          @media screen and (max-width: 1200px) {
            border-radius: 18px;
          }
        }
        @media screen and (max-width: 1200px) {
          //margin-top: 64px;
          border-radius: 20px;

        }

        .offline-desc-inner-wrapper {
         background-image: var(--bg-gradient);
          color: var(--text-color);
          opacity: 0.8;
          border-radius: 35px;
          text-align: initial;
          padding: 15px;
          font-size: 13px;
          &.little {
            border-radius: 23px;
          }
          @media screen and (max-width: 1200px) {
            border-radius: 20px;
            gap: 10px;
            padding: 12px 9px;
          }
          @media screen and (max-width: 580px) {
           font-size: 10px;
          }
        }
      }
    }
    .offline-desc {
      background-color: var(--bg-light);
      color: var(--text-color);
    }
    .rules-check-wrapper {
      text-align: right;
      font-size: 12px;
      @media screen and (max-width: 510px) {
        padding-bottom: 8px;
      }
    }
    .inputs-wrapper {
      .card-amount-input {
        display: flex;
        gap: 5px;
        width: 100%;
        .select-card-input {
          flex: 1;
        }
        .amount-input {
          flex: 1;
        }
        @media screen and (max-width: 580px) {
         flex-direction: column;
        }
      }
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 510px) {
        gap: 6px;
      }
      .desc {
        color: var(--text-color);
        font-weight: 300;
        font-size: 13px;
        padding: 0 8px 14px 0;
        white-space: nowrap;
        text-align: initial;
        opacity: 0.7;
        @media screen and (max-width: 1100px) {
          font-size: 10px;
        }
        @media screen and (max-width: 470px) {
          font-size: 8px;
        }
      }
    }
    .bottom-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 510px) {
        gap: 6px;
      }
    }
  }
}


//select gate way
.dragons-fight #menu-gate_id {
  .MuiPaper-root {
    background-color: var(--bg-light);
    color: var(--text-color);
    opacity: 0.8;
    ul li {
      @media screen and (max-width: 510px) {
        font-size: 12px;
      }
    }
  }
}
//select network
.dragons-fight #menu-network {
  .MuiPaper-root {
    background-color: var(--bg-light);
    color: var(--text-color);
    opacity: 0.8;
    ul li {
      @media screen and (max-width: 510px) {
        font-size: 12px;
      }
      img {
        max-width: 80px;
        @media screen and (max-width: 510px) {
         max-width: 50px;
        }
      }
    }
  }
}

//Withdraw Dialog
.dragons-fight .withdraw-dialog-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  @media screen and (max-width: 510px) {
    gap: 4px;
    padding: 5px;
  }
  .withdraw-dialog-head {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color);
    @media screen and (max-width: 510px) {
      gap: 0;

    }
    .close-btn {
      color: var(--text-color);
    }
    .heading-title {
      font-weight: 600;
      font-size: 16px;
      @media screen and (max-width: 510px) {
        font-size: 13px;
      }
    }
  }
  .wallet-balance-wrapper {
    padding: 20px;
    text-align: center;
    background-image: var(--fight-bg);
    margin: 20px;
    display: flex;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 7px;
    color: var(--text-color);
    @media screen and (max-width: 1100px) {
      padding: 7px;
      margin: 10px 20px;

    }
    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      img {
        width: 30px;
        @media screen and (max-width: 1100px) {
          width: 15px;

        }
      }
      .title {
        font-size: 14px;
        font-weight: 300;
        @media screen and (max-width: 1100px) {
          font-size: 11px;

        }
      }
    }
    .value-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      .value {
        font-size: 16px;
        font-weight: 700;
        @media screen and (max-width: 1100px) {
          font-size: 14px;

        }
      }
      .metric {
        font-size: 13px;
        font-weight: 300;
        @media screen and (max-width: 1100px) {
          font-size: 10px;

        }
      }
    }
  }
  .withdraw-radio-buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    .title {
      font-weight: 500;
      font-size: 15px;
      color: var(--text-color);
      @media screen and (max-width: 510px) {
        font-size: 12px;
      }
    }
    .withdraw-radio-buttons {
      .radios-wrapper {
        display: flex;
      }
    }

  }
  .hints-wrapper {
    background-image: var(--bg-gradient-light);
    font-size: 14px;
    font-weight: 500;
    border-radius: 32px;
    padding: 24px;
    color: var(--text-color);
    opacity: 0.9;
    text-align: initial;
    @media screen and (max-width: 510px) {
      padding: 9px;
      font-size: 9px;
      border-radius: 15px;
    }
  }
  .rules-check-wrapper {
    padding-bottom: 4px;
    text-align: right;
    font-size: 12px;
    @media screen and (max-width: 510px) {
      padding-bottom: 12px;
    }
  }
  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .select-card-input {
      .badge-pill {
        width: 115px;
        height: 25px;
      }
    }
    .amount-max-amount-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .amount-input {
        flex: 1;
      }
      .max-amount-wrapper {
        display: flex;
        flex: 1;
        justify-content: center;
        gap: 5px;
        align-items: center;
        text-decoration: none;
        @media screen and (max-width: 510px) {
        flex-direction: column;
        }
        .value-wrapper {
        display: flex;
          gap: 4px;
          align-items: center;
        }
        .title {
          font-weight: 500;
          color: var(--text-color);
          font-size: 13px;
          opacity: 0.9;
          @media screen and (max-width: 510px) {
           font-size: 11px;
          }
        }
        .amount-value {
          text-decoration: none;
          color: var(--success-solid);
          background-color: var(--success-linear-gradient);
          font-size: 16px;
          font-weight: 700;
          @media screen and (max-width: 510px) {
            font-size: 14px;
          }
        }
        .metric {
          color: var(--text-color);
          font-size: 12px;
          font-weight: 300;
          opacity: 0.8;
          @media screen and (max-width: 510px) {
            font-size: 10px;
          }
        }
      }
    }

    .desc {
      color: var(--text-color);
      font-weight: 300;
      font-size: 13px;
      padding: 0 8px 14px 0;
      white-space: nowrap;
      text-align: initial;
      opacity: 0.7;
      @media screen and (max-width: 1100px) {
        font-size: 10px;
      }
      @media screen and (max-width: 470px) {
        font-size: 8px;
        padding: 0 8px 10px 0;
      }
    }

  }

}

//Factor
.dragons-fight .factor-details-list {
  .factor-details-list-item {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color);
    @media screen and (max-width: 580px) {
     font-size: 14px;
    }
    .expandMore {
      transform: rotate(180deg);
    }
  }
  .details-wrapper {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    gap: 10px;
    padding: 10px;

    .detail-item {
      width: 30%;
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--text-color);
      @media screen and (max-width: 768px) {
       width: 47%;
      }
      .title {
        font-size: 14px;
        font-weight: 300;
        opacity: 0.9;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
      .value {
        font-size: 13px;
        font-weight: 500;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
      .total-value {
        display: flex;
        gap: 4px;
        flex-flow: column;
      }
      .time-value {
        direction: ltr;
        font-size: 13px;
        @media screen and (max-width: 768px) {
          font-size: 11px;
        }
      }
      .metric {
        font-weight: 300;
        font-size: 13px;
        @media screen and (max-width: 768px) {
          font-size: 10px;
        }
      }
    }
    .big-detail-item {
      width: 100%;
      display: flex;
      align-items: baseline;
      gap: 8px;
      color: var(--text-color);
      .wallet-group-wrapper {
        display: flex;
        gap: 3px;
        flex-direction: column;
        justify-content: left;
        flex: 1;
        .add-wallet-btn {
          width: fit-content;
          margin-right: auto;
          .MuiButton-label {
            color: var(--primary) !important;
            @media screen and (max-width: 768px) {
              font-size: 10px;
              font-weight: 600;
            }
          }
        }
      }
      .title {
        font-size: 14px;
        font-weight: 300;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
      .MuiFormControl-root {
        .MuiInputBase-root {
          font-size: 13px;
        }
      }
    }
    .factor-text-field {
      background-image: var(--fight-bg);
    }
  }
  .supplementary-details {
    padding: 10px;
    border-radius: 10px;
    background-image: var(--fight-bg);
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    .supplementary-title {
      font-size: 15px;
      font-weight: 600;
      color: var(--text-color);
    }
    .supplementary-rows {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .webmoney-input {
        flex: 1;
        flex-basis: 45%;
      }
      .voucher-input {
        flex: 1;
      }
    }

  }
  .payment-info-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 7px;
    flex-direction: column;
    .payment-info {
      border-radius: 10px;
      border: 1px solid transparent;
      background: var(--primary-secondary-gradient-border);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      padding: 10px;
    }

  }
  .wallet-info-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 7px;
    flex-direction: column;
    .wallet-info {
      border-radius: 10px;
      border: 1px solid transparent;
      background: var(--primary-secondary-gradient-border);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      padding: 10px;
    }
  }
}

//Badges
.dragons-fight {
  .success-badge {
    color: var(--success-solid);
    background: var(--success);
    font-weight: 400;
    font-size: 13px;
    padding: 5px 10px;
    min-width: 70px;
    border-radius: 50px;
    @media screen and (max-width: 768px) {
      font-size: 9px;
      min-width: 50px;
      padding: 3px;
    }
  }
  .success10-badge {
    background-color: var(--success-bg);
    color: var(--success-solid);
    font-weight: 400;
    font-size: 13px;
    padding: 5px 10px;
    min-width: 70px;
    border-radius: 50px;
    @media screen and (max-width: 768px) {
      font-size: 9px;
      min-width: 50px;
      padding: 3px;
    }
  }
  .danger-badge {
    background-color: var(--danger-bg);
    color: var(--danger-text);
    font-weight: 400;
    font-size: 13px;
    padding: 5px 10px;
    min-width: 70px;
    border-radius: 50px;
    @media screen and (max-width: 768px) {
      font-size: 9px;
      min-width: 50px;
      padding: 3px;
    }
  }
  .warning-badge {
    background-color: var(--warning-bg);
    color: var(--warning-text);
    font-weight: 400;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 50px;
    min-width: 70px;
    @media screen and (max-width: 768px) {
      font-size: 9px;
      min-width: 50px;
      padding: 3px;
    }
  }
  .primary-badge {
    background-color: var(--primary-bg);
    color: var(--primary-text);
    font-weight: 400;
    font-size: 13px;
    padding: 5px 10px;
    min-width: 70px;
    border-radius: 50px;
    @media screen and (max-width: 768px) {
      font-size: 9px;
      min-width: 50px;
      padding: 3px;
    }
  }
}

//Buy Dialog
.dragons-fight .buy-dialog {
  .step-contents-wrapper {
    padding: 0 30px 30px 30px;
    @media screen and (max-width: 510px) {
     padding: 18px 10px 10px 10px;
    }
    .step-contents {
      .first-step-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        @media screen and (max-width: 510px) {
          gap: 8px;
        }
        .withdraw-radio-buttons-wrapper {
          display: flex;
          align-items: center;
          gap: 15px;
          .title {
            font-weight: 500;
            font-size: 15px;
            color: var(--text-color);
            @media screen and (max-width: 510px) {
              font-size: 12px;
            }
          }
          .withdraw-radio-buttons {
            .radios-wrapper {
              display: flex;
            }
          }

        }
        .inventory-box-wrapper {
          background-color: var(--bg);
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          gap: 12px;
          @media screen and (max-width: 510px) {
          padding: 3px;
            gap: 3px;
          }
          .wallet-coin-inventory {
            display: flex;
            gap: 5px;
            flex: 1;
            @media screen and (max-width: 440px) {
             flex-direction: column;
              flex: 1;
            }
            .wallet-inventory {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              .title {
                font-size: 15px;
                font-weight: 500;
                color: var(--text-color);
                opacity: 0.8;
                @media screen and (max-width: 510px) {
                  font-size: 10px;
                }
              }
              .value-wrapper {
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: center;
                .value {
                  font-size: 16px;
                  font-weight: 700;
                  color: var(--text-color);
                  @media screen and (max-width: 510px) {
                    font-size: 12px;
                  }
                }
                .metric {
                  font-size: 12px;
                  font-weight: 300;
                  opacity: 0.9;
                  color: var(--text-color);
                  @media screen and (max-width: 510px) {
                    font-size: 8px;
                  }
                }
              }
            }
            .coin-inventory {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              .title {
                font-size: 15px;
                font-weight: 500;
                opacity: 0.8;
                color: var(--text-color);
                @media screen and (max-width: 510px) {
                  font-size: 10px;
                }
              }
              .value-wrapper {
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: center;
                .value {
                  font-size: 16px;
                  font-weight: 700;
                  color: var(--text-color);
                  @media screen and (max-width: 510px) {
                    font-size: 12px;
                  }
                }
                .metric {
                  font-size: 12px;
                  font-weight: 300;
                  color: var(--text-color);
                  opacity: 0.9;
                  @media screen and (max-width: 510px) {
                    font-size: 8px;
                  }
                }
              }
            }
          }

          .progress-bar-wrapper {
            flex: 1;
            background-image: var(--fight-bg);
            padding: 10px 35px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
            @media screen and (max-width: 510px) {
             padding: 5px 10px;
            }
            .MuiCircularProgress-colorPrimary{
              color: var(--secondary);
            }
            .seconds {
              color: var(--secondary);
              font-weight: 500;
              font-size: 15px;
              @media screen and (max-width: 510px) {
                font-size: 13px;
              }
            }
            .desc {
              color: var(--text-color);
              font-weight: 300;
              font-size: 13px;
              @media screen and (max-width: 510px) {
                font-size: 11px;
              }
            }

          }
        }
        .inputs-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          justify-content: space-between;
          @media screen and (max-width: 510px) {
            gap: 11px;
          }
          .currency-input {
            width: 47%;

          }
          .destination-account-input {
            width: 47%;
            .MuiAutocomplete-root {
              .destination-wallet {
                .MuiFormControl-root {
                  .MuiInputBase-root {
                    padding: 0 !important;
                  }
                }
              }
            }


          }
          .destination-wallet-input {
            width: 47%;

          }
          .total-amount-wrapper {
            width: 100%;
            display: flex;
            gap: 5px;
            @media screen and (max-width: 510px) {
              font-size: 14px;
            }
            .main-discounted, .total {
              display: flex;
              gap: 4px;
            }
          }
          .network-input {
            width: 47%;
          }
          .wallet-tag-input {
            width: 47%;
          }
          .amount-input {
            width: 47%;
          }
          .price-input {
            width: 47%;
          }
          .discount-input {
            width: 47%;
            .MuiFormControl-root {
              .MuiInputBase-root {
                .MuiSelect-root {
                  .discount-menu-item {
                    display: flex;
                    gap: 3px;
                    flex: 1;

                    .discount-name {
                      font-size: 12px;
                      font-weight: 400;

                    }
                    .discount-value {
                      font-size: 13px;
                      font-weight: 500;
                    }
                    .discount-max {
                      display: flex;
                      gap: 3px;
                      @media screen and (max-width: 510px) {
                        display: none;
                      }
                      .desc-wrapper {
                        display: flex;
                        gap: 2px;
                        .discount-max-desc {
                          font-size: 12px;
                          font-weight: 400;
                        }
                      }
                      .discount-max-desc {
                        font-size: 12px;
                        font-weight: 400;
                      }
                      .discount-max-number {
                        font-size: 13px;
                        font-weight: 700;
                      }

                    }
                  }
                }
              }
            }

          }
          .discount-code-input {
            width: 47%
          }
          .commission-input {
            width: 47%;
          }
          .total-amount-input {
            width: 100%;
          }
          .wm-first-name-input {
            width: 47%;
          }
          .wm-last-name-input {
            width: 47%;
          }
          .wm-id-input {
            width: 47%;
          }
        }
        .buttons-wrapper {

        }
      }
      .second-step-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .inventory-box-wrapper {
          .inventory-box-inner-wrapper {
            background-color: var(--bg);
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            flex-wrap: wrap;
            @media screen and (max-width: 510px) {
              flex-wrap: nowrap;
            }
            .info-wrapper {
              flex: 2;
              display: flex;
              flex-wrap: wrap;

              .wallet-inventory {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                @media screen and (max-width: 510px) {
                  width: 100%;
                  flex-direction: row;
                }
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                  opacity: 0.8;
                  @media screen and (max-width: 510px) {
                    font-size: 10px;
                    flex: 1;
                    text-align: initial;
                  }
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  @media screen and (max-width: 510px) {
                   flex: 1;
                  }
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 11px;
                    }
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                    opacity: 0.9;
                    @media screen and (max-width: 510px) {
                      font-size: 8px;
                    }
                  }
                }
              }
              .payable-amount {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                @media screen and (max-width: 510px) {
                  width: 100%;
                  flex-direction: row;
                }
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                  opacity: 0.8;
                  @media screen and (max-width: 510px) {
                    font-size: 10px;
                    flex: 1;
                    text-align: initial;
                  }
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  @media screen and (max-width: 510px) {
                   flex: 1;
                  }
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 11px;
                    }
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                    opacity: 0.9;
                    @media screen and (max-width: 510px) {
                      font-size: 8px;
                    }
                  }
                }
              }
              .network-cost {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                @media screen and (max-width: 510px) {
                  width: 100%;
                  flex-direction: row;
                }
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                  opacity: 0.8;
                  @media screen and (max-width: 510px) {
                    font-size: 10px;
                    flex: 1;
                    text-align: initial;
                  }
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  @media screen and (max-width: 510px) {
                   flex: 1;
                    direction: ltr;
                  }
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 11px;
                    }
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    opacity: 0.9;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 8px;
                    }
                  }
                }
              }
              .coin-amount {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                @media screen and (max-width: 510px) {
                  width: 100%;
                  flex-direction: row;
                }
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                  opacity: 0.8;
                  @media screen and (max-width: 510px) {
                    font-size: 10px;
                    flex: 1;
                    text-align: initial;
                  }
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  direction: ltr;
                  @media screen and (max-width: 510px) {
                    flex: 1;
                  }
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 11px;
                    }
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                    opacity: 0.9;
                    @media screen and (max-width: 510px) {
                      font-size: 8px;
                    }
                  }
                }
              }
            }
            .timer-wrapper {
              flex: 1;
              background-image: var(--fight-bg);
              padding: 10px 35px;
              border-radius: 10px;
              display: flex;
              justify-content: space-between;
              gap: 10px;
              align-items: center;
              @media screen and (max-width: 510px) {
                padding: 5px 10px;
              }
              .MuiCircularProgress-colorPrimary{
                color: var(--secondary);
              }
              .seconds {
                color: var(--secondary);
                font-weight: 500;
                font-size: 14px;
                @media screen and (max-width: 510px) {
                  font-size: 13px;
                }
              }
              .desc {
                color: var(--text-color);
                font-weight: 300;
                font-size: 13px;
                @media screen and (max-width: 510px) {
                  font-size: 10px;
                }
              }
            }
          }
        }
        .payment-section-wrapper {
          display: flex;
          flex-direction: column;
          gap: 5px;
          text-align: initial;
          @media screen and (max-width: 510px) {
           gap: 1px;
          }
          .title {
            font-size: 15px;
            font-weight: 500;
            color: var(--text-color);
            @media screen and (max-width: 510px) {
             font-size: 11px;
            }
          }
          .radio-groups-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .payment-radio-buttons {

              .radios-wrapper {
                display: flex;
              }
            }
            .checkout-amount-radio-buttons {
              display: flex;
              flex-direction: column;
              align-items: center;
              .radios-wrapper {
                .all-amount-radio-wrapper {
                  @media screen and (max-width: 510px) {
                    text-align: initial;
                  }
                }
              }
            }
          }
          .payment-radio-buttons {
            .radios-wrapper {
              display: flex;
            }
          }
        }

      }
    }
  }
}

//Wallet Tabs
.dragons-fight .wallet-tabs-wrapper {
  background-color: var(--bg);
  border-radius: 10px 10px 0 0;
  .MuiPaper-root {
    background-color: var(--pure-bg) !important;
    color: var(--text-color) !important;
    box-shadow: none;
    border-radius: 10px 10px 0 0;
    .MuiTabs-root {
      position: relative;
      @media screen and (max-width: 510px) {
        min-height: 33px;
      }
      &.selected-tab-1 {
        .tab-rial {
          border-radius: 20px 10px 0  0;
          @media screen and (max-width: 510px) {
            border-radius: 10px 10px 0  0;
          }
        }
        .tab-pm {
          border-radius:  0 0 20px 0;
          @media screen and (max-width: 510px) {
            border-radius:  0 0 10px 0;
          }
        }
      }
      &.selected-tab-2 {
        .tab-pm {
          border-radius: 0 0 20px 20px;
          @media screen and (max-width: 510px) {
            border-radius: 0 0 10px 10px;
          }
        }
        .tab-rial {
          border-radius: 0 10px 0 20px;
          @media screen and (max-width: 510px) {
            border-radius: 0 10px 0 10px;
          }
        }
        .tab-none {
          border-radius: 0 0 20px 0;
          flex: 1;
          background-color: var(--pure-bg);
          z-index: 1;
          @media screen and (max-width: 510px) {
            border-radius: 0 0 10px 0;
          }
        }
      }
      .colored-bg {
        position: absolute;
        right: 0;
        left: 0;
        height: 47%;
        bottom: 0;
        background-color: var(--bg);
      }
      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }
        width: unset;
        flex: unset;
        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            &.Mui-selected {
              background-color: var(--bg);
              border-radius: 10px 10px 0 0;
            }
          }
        }
      }
      .tab {
        color: var(--text-color);
        z-index: 1;
        background-color: var(--pure-bg);
        opacity: 1;
        padding: 0 40px;
        @media screen and (max-width: 510px) {
          min-height: 33px;
          padding: 0 10px;
        }
        .MuiTab-wrapper {
          white-space: nowrap;
        }

        span {
          font-size: 14px;
          @media screen and (max-width: 510px) {
            font-size: 10px;
          }
        }
        &.Mui-selected {
          background-color: var(--bg);
          border-radius: 16px 16px 0 0;
          span {
            font-weight: 500;
            font-size: 14px;
            @media screen and (max-width: 510px) {
              font-size: 11px;
            }

          }
        }
      }
    }
  }
}

//Deposit Crypto Dialog
.dragons-fight .deposit-crypto-dialog {
  .step-contents-wrapper {
    padding: 0 30px 30px 30px;
    @media screen and (max-width: 510px) {
      padding: 18px 10px 10px 10px;
    }
    .step-contents {
      .first-step-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        @media screen and (max-width: 510px) {
          gap: 8px;
        }
        .pm-select-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 12px 0;
          .desc {
            text-align: start;
            color: var(--text-color);
            font-size: 13px;
            font-weight: 500;
          }
          .radio-group-wrapper {
            width: 100%;
            .radios-wrapper {
              display: flex;
              gap: 10px;
              .voucher-radio-wrapper {
                flex: 1;
                border-radius: 5px;
                background-color: var(--bg-light);
                margin: 0;
                &.activated {
                  background-image: var(--fight-bg);
                }
              }
              .pm-radio-wrapper {
                flex: 1;
                border-radius: 5px;
                background-color: var(--bg-light);
                margin: 0;
                &.activated {
                  background-image: var(--fight-bg);
                }
              }
            }
          }
        }
        .inventory-box-wrapper {
          background-color: var(--bg);
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          gap: 12px;
          @media screen and (max-width: 510px) {
            padding: 3px;
            gap: 3px;
          }
          .wallet-coin-inventory {
            display: flex;
            gap: 5px;
            flex: 1;
            @media screen and (max-width: 440px) {
              flex-direction: row;
              flex: 1;
            }
            .wallet-inventory {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              .title {
                font-size: 15px;
                font-weight: 500;
                color: var(--text-color);
                opacity: 0.8;
                @media screen and (max-width: 510px) {
                  font-size: 10px;
                }
              }
              .value-wrapper {
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: center;
                .value {
                  font-size: 16px;
                  font-weight: 700;
                  color: var(--text-color);
                  @media screen and (max-width: 510px) {
                    font-size: 12px;
                  }
                }
                .metric {
                  font-size: 12px;
                  font-weight: 300;
                  opacity: 0.9;
                  color: var(--text-color);
                  @media screen and (max-width: 510px) {
                    font-size: 8px;
                  }
                }
              }
            }
            .coin-inventory {
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              .title {
                font-size: 15px;
                font-weight: 500;
                opacity: 0.8;
                color: var(--text-color);
                @media screen and (max-width: 510px) {
                  font-size: 10px;
                }
              }
              .value-wrapper {
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: center;
                .value {
                  font-size: 16px;
                  font-weight: 700;
                  color: var(--text-color);
                  @media screen and (max-width: 510px) {
                    font-size: 12px;
                  }
                }
                .metric {
                  font-size: 12px;
                  font-weight: 300;
                  color: var(--text-color);
                  opacity: 0.9;
                  @media screen and (max-width: 510px) {
                    font-size: 8px;
                  }
                }
              }
            }
          }

          .progress-bar-wrapper {
            flex: 1;
            //background-image: var(--fight-bg);
            //padding: 10px 35px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            gap: 10px;
            align-items: center;
            @media screen and (max-width: 510px) {
              //padding: 5px 10px;
            }
            img {
              width: 47%;
            }
            .MuiCircularProgress-colorPrimary{
              color: var(--secondary);
            }
            .seconds {
              color: var(--secondary);
              font-weight: 500;
              font-size: 15px;
              @media screen and (max-width: 510px) {
                font-size: 13px;
              }
            }
            .desc {
              color: var(--text-color);
              font-weight: 300;
              font-size: 13px;
              @media screen and (max-width: 510px) {
                font-size: 11px;
              }
            }

          }
        }
        .inputs-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          justify-content: space-between;
          @media screen and (max-width: 510px) {
            gap: 11px;
          }
          .currency-input {
            width: 47%;

          }
          .vouchers-wrapper {
            display: flex;
            gap: 10px;
            width: 100%;
            .voucher-num-input {
             flex: 1;
            }
            .voucher-code-input {
              flex: 1;
            }
          }

          .amount-input {
            width: 47%;
          }
          .amount-max-amount-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 94%;
            .amount-input {
              flex: 1;
            }
            .max-amount-wrapper {
              display: flex;
              flex: 1;
              justify-content: center;
              gap: 5px;
              align-items: center;
              text-decoration: none;
              margin-bottom: 20px;
              @media screen and (max-width: 510px) {
                flex-direction: column;
              }
              .value-wrapper {
                display: flex;
                gap: 4px;
                align-items: center;
              }
              .title {
                font-weight: 500;
                color: var(--text-color);
                font-size: 13px;
                opacity: 0.9;
                @media screen and (max-width: 510px) {
                  font-size: 11px;
                }
              }
              .amount-value {
                text-decoration: none;
                color: var(--success-solid);
                background-color: var(--success-linear-gradient);
                font-size: 16px;
                font-weight: 700;
                @media screen and (max-width: 510px) {
                  font-size: 14px;
                }
              }
              .metric {
                color: var(--text-color);
                font-size: 12px;
                font-weight: 300;
                opacity: 0.8;
                @media screen and (max-width: 510px) {
                  font-size: 10px;
                }
              }
            }
          }
          .total-amount-wrapper {
            width: 100%;
            display: flex;
            gap: 5px;
            @media screen and (max-width: 510px) {
              font-size: 14px;
            }
            .main-discounted, .total {
              display: flex;
              gap: 4px;
            }
          }
          .destination-account-input {
            width: 47%;
            .MuiAutocomplete-root {
              .destination-wallet {
                .MuiFormControl-root {
                  .MuiInputBase-root {
                    padding: 0;
                    button {
                      padding: 8px;
                    }
                  }
                }
              }
            }
          }

        }
        .buttons-wrapper {

        }
      }
      .second-step-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .inventory-box-wrapper {
          .inventory-box-inner-wrapper {
            background-color: var(--bg);
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            flex-wrap: wrap;
            @media screen and (max-width: 510px) {
              flex-wrap: nowrap;
            }
            .info-wrapper {
              flex: 2;
              display: flex;
              flex-wrap: wrap;

              .wallet-inventory {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                @media screen and (max-width: 510px) {
                  width: 100%;
                  flex-direction: row;
                }
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                  opacity: 0.8;
                  @media screen and (max-width: 510px) {
                    font-size: 10px;
                    flex: 1;
                    text-align: initial;
                  }
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  @media screen and (max-width: 510px) {
                    flex: 1;
                  }
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 11px;
                    }
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                    opacity: 0.9;
                    @media screen and (max-width: 510px) {
                      font-size: 8px;
                    }
                  }
                }
              }
              .payable-amount {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                @media screen and (max-width: 510px) {
                  width: 100%;
                  flex-direction: row;
                }
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                  opacity: 0.8;
                  @media screen and (max-width: 510px) {
                    font-size: 10px;
                    flex: 1;
                    text-align: initial;
                  }
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  @media screen and (max-width: 510px) {
                    flex: 1;
                  }
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 11px;
                    }
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                    opacity: 0.9;
                    @media screen and (max-width: 510px) {
                      font-size: 8px;
                    }
                  }
                }
              }
              .network-cost {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                @media screen and (max-width: 510px) {
                  width: 100%;
                  flex-direction: row;
                }
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                  opacity: 0.8;
                  @media screen and (max-width: 510px) {
                    font-size: 10px;
                    flex: 1;
                    text-align: initial;
                  }
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  @media screen and (max-width: 510px) {
                    flex: 1;
                    direction: ltr;
                  }
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 11px;
                    }
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    opacity: 0.9;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 8px;
                    }
                  }
                }
              }
              .coin-amount {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                @media screen and (max-width: 510px) {
                  width: 100%;
                  flex-direction: row;
                }
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                  opacity: 0.8;
                  @media screen and (max-width: 510px) {
                    font-size: 10px;
                    flex: 1;
                    text-align: initial;
                  }
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  direction: ltr;
                  @media screen and (max-width: 510px) {
                    flex: 1;
                  }
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                    @media screen and (max-width: 510px) {
                      font-size: 11px;
                    }
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                    opacity: 0.9;
                    @media screen and (max-width: 510px) {
                      font-size: 8px;
                    }
                  }
                }
              }
            }
            .timer-wrapper {
              flex: 1;
              background-image: var(--fight-bg);
              padding: 10px 35px;
              border-radius: 10px;
              display: flex;
              justify-content: space-between;
              gap: 10px;
              align-items: center;
              @media screen and (max-width: 510px) {
                padding: 5px 10px;
              }
              .MuiCircularProgress-colorPrimary{
                color: var(--secondary);
              }
              .seconds {
                color: var(--secondary);
                font-weight: 500;
                font-size: 14px;
                @media screen and (max-width: 510px) {
                  font-size: 13px;
                }
              }
              .desc {
                color: var(--text-color);
                font-weight: 300;
                font-size: 13px;
                @media screen and (max-width: 510px) {
                  font-size: 10px;
                }
              }
            }
          }
        }
        .payment-section-wrapper {
          display: flex;
          flex-direction: column;
          gap: 5px;
          text-align: initial;
          @media screen and (max-width: 510px) {
            gap: 1px;
          }
          .title {
            font-size: 15px;
            font-weight: 500;
            color: var(--text-color);
            @media screen and (max-width: 510px) {
              font-size: 11px;
            }
          }
          .radio-groups-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .payment-radio-buttons {

              .radios-wrapper {
                display: flex;
              }
            }
            .checkout-amount-radio-buttons {
              display: flex;
              flex-direction: column;
              align-items: center;
              .radios-wrapper {
                .all-amount-radio-wrapper {
                  @media screen and (max-width: 510px) {
                    text-align: initial;
                  }
                }
              }
            }
          }
          .payment-radio-buttons {
            .radios-wrapper {
              display: flex;
            }
          }
        }

      }
    }
  }
}

//Sell Dialog
.dragons-fight .sell-dialog {
  .step-contents-wrapper {
    padding: 0 30px 30px 30px;
    @media screen and (max-width: 510px) {
      padding: 18px 10px 10px 10px;
    }
    .step-contents {
      .withdraw-radio-buttons-wrapper {
        display: flex;
        align-items: center;
        gap: 15px;
        .title {
          font-weight: 500;
          font-size: 15px;
          color: var(--text-color);
          @media screen and (max-width: 510px) {
            font-size: 12px;
          }
        }
        .withdraw-radio-buttons {
          .radios-wrapper {
            display: flex;
          }
        }

      }
      .first-step-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        @media screen and (max-width: 510px) {
         gap: 8px;
        }
        .inventory-box-wrapper {
          background-color: var(--bg);
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          @media screen and (max-width: 510px) {
            padding: 3px;
          }
          .wallet-inventory {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .title {
              font-size: 15px;
              font-weight: 500;
              color: var(--text-color);
              opacity: 0.8;
              @media screen and (max-width: 510px) {
                font-size: 11px;
              }
            }
            .value-wrapper {
              display: flex;
              gap: 5px;
              align-items: center;
              justify-content: center;
              .value {
                font-size: 16px;
                font-weight: 700;
                color: var(--text-color);
                @media screen and (max-width: 510px) {
                  font-size: 13px;
                }
              }
              .metric {
                font-size: 12px;
                font-weight: 300;
                opacity: 0.9;
                color: var(--text-color);
                @media screen and (max-width: 510px) {
                  font-size: 9px;
                }
              }
            }
          }
          .coin-inventory {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .title {
              font-size: 15px;
              font-weight: 500;
              opacity: 0.8;
              color: var(--text-color);
              @media screen and (max-width: 510px) {
                font-size: 11px;
              }
            }
            .value-wrapper {
              display: flex;
              gap: 5px;
              align-items: center;
              justify-content: center;
              .value {
                font-size: 16px;
                font-weight: 700;
                color: var(--text-color);
                @media screen and (max-width: 510px) {
                  font-size: 13px;
                }
              }
              .metric {
                font-size: 12px;
                font-weight: 300;
                color: var(--text-color);
                opacity: 0.9;
                @media screen and (max-width: 510px) {
                  font-size: 9px;
                }
              }
            }
          }
          .progress-bar-wrapper {
            flex: 1;
            background-image: var(--fight-bg);
            padding: 10px 35px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
            @media screen and (max-width: 510px) {
              padding: 5px 10px;
            }
            .MuiCircularProgress-colorPrimary{
              color: var(--secondary);
            }
            .seconds {
              color: var(--secondary);
              font-weight: 500;
              font-size: 15px;
              @media screen and (max-width: 510px) {
                font-size: 13px;
              }
            }
            .desc {
              color: var(--text-color);
              font-weight: 300;
              font-size: 13px;
              @media screen and (max-width: 510px) {
                font-size: 11px;
              }
            }

          }
        }
        .inputs-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          justify-content: space-between;
          .currency-input {
            width: 47%;
          }
          .destination-account-input {
            width: 47%;
          }
          .destination-wallet-input {
            width: 47%;
          }
          .network-input {
            width: 47%;
          }
          .wallet-tag-input {
            width: 47%;
          }
          .amount-input {
            width: 47%;
          }
          .price-input {
            width: 47%;
          }
          .commission-input {
            width: 47%;
          }
          .total-amount-input {
            width: 100%;
          }
          .wm-first-name-input {
            width: 47%;
          }
          .wm-last-name-input {
            width: 47%;
          }
          .wm-id-input {
            width: 47%;
          }
        }
      }
      .second-step-wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        @media screen and (max-width: 580px) {
         gap: 5px;
        }
        .timer-desc-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .warning-box {
            flex: 2;
          }
          .timer-wrapper {
            flex: 1;
            background-image: var(--fight-bg);
            padding: 10px 35px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
            @media screen and (max-width: 510px) {
              padding: 5px 10px;
            }
            .MuiCircularProgress-colorPrimary{
              color: var(--secondary);
              width: 42px !important;
              height: 42px !important;
            }
            .seconds {
              color: var(--secondary);
              font-weight: 500;
              font-size: 14px;
              @media screen and (max-width: 510px) {
                font-size: 13px;
              }
            }
            .desc {
              color: var(--text-color);
              font-weight: 300;
              font-size: 13px;
              @media screen and (max-width: 510px) {
                font-size: 10px;
              }
            }
          }
        }
        .qr-inputs-wrapper {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          align-items: center;
          gap: 20px;
          @media screen and (max-width: 580px) {
            gap: 7px;
            flex-direction: column;
          }
          .inputs-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            @media screen and (max-width: 580px) {
               width: 100%;
              gap: 10px;
             }
            .desc {
              @media screen and (max-width: 580px) {
               display: none;
              }
            }
            .kucoin-check {
              color: var(--text-color);
              font-weight: 500;
              font-size: 14px;
              @media screen and (max-width: 580px) {
               text-align: initial;
              }
              .MuiFormControlLabel-root {
                margin: 0;
                @media screen and (max-width: 580px) {
                 font-size: 10px;
                }
                .MuiButtonBase-root {
                  color: var(--secondary);
                  @media screen and (max-width: 580px) {
                    text-align: initial;
                    padding: 2px 10px 2px 3px;
                  }
                }
              }
            }
            .desc {
              color: var(--text-color);
              font-size: 13px;
              font-weight: 300;
              margin-bottom: 9px;
            }
          }
          .qr-wrapper {
            .wallet-qr {
              @media screen and (max-width: 580px) {
               width: 110px;
                height: 110px;
              }
            }
          }

        }
        .hints-wrapper {
          background-image: var(--bg-gradient-light);
          font-size: 12px;
          font-weight: 500;
          border-radius: 10px 12px;
          padding: 24px;
          color: var(--text-color);
          opacity: 0.8;
          text-align: initial;
          @media screen and (max-width: 510px) {
            padding: 9px;
            font-size: 9px;
            border-radius: 15px;
          }
        }
        .inventory-box-wrapper {
          .inventory-box-inner-wrapper {
            background-color: var(--bg);
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            flex-wrap: wrap;
            .info-wrapper {
              flex: 2;
              display: flex;
              flex-wrap: wrap;
              .wallet-inventory {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                  }
                }
              }
              .payable-amount {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                  }
                }
              }
              .network-cost {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                  }
                }
              }
              .coin-amount {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 50%;
                .title {
                  font-size: 15px;
                  font-weight: 500;
                  color: var(--text-color);
                  white-space: nowrap;
                }
                .value-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  .value {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--text-color);
                  }
                  .metric {
                    font-size: 12px;
                    font-weight: 300;
                    color: var(--text-color);
                  }
                }
              }
            }
            .timer-wrapper {
              flex: 1;
              background-image: var(--fight-bg);
              padding: 10px 35px;
              border-radius: 10px;
              display: flex;
              justify-content: space-between;
              gap: 10px;
              align-items: center;
              .MuiCircularProgress-colorPrimary{
                color: var(--secondary);
              }
              .seconds {
                color: var(--secondary);
                font-weight: 500;
                font-size: 14px;
              }
              .desc {
                color: var(--text-color);
                font-weight: 300;
                font-size: 13px;
              }
            }
          }
        }
        .payment-section-wrapper {
          display: flex;
          flex-direction: column;
          gap: 5px;
          text-align: initial;
          @media screen and (max-width: 510px) {
            gap: 1px;
          }
          .title {
            font-size: 15px;
            font-weight: 500;
            color: var(--text-color);
            @media screen and (max-width: 510px) {
              font-size: 11px;
            }
          }
          .radio-groups-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .payment-radio-buttons {

              .radios-wrapper {
                display: flex;
              }
            }
            .checkout-amount-radio-buttons {
              display: flex;
              flex-direction: column;
              align-items: center;
              .radios-wrapper {
                .all-amount-radio-wrapper {
                  @media screen and (max-width: 510px) {
                    text-align: initial;
                  }
                }
              }
            }
          }
          .payment-radio-buttons {
            .radios-wrapper {
              display: flex;
            }
          }
        }
        .inputs-wrapper {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          .voucher-num-input {
            flex: 1;
          }
          .voucher-code-input {
            flex: 1;
          }
          .ps-voucher-code-input {
            flex: 1;
          }
        }

      }
    }
  }
}


.dragons-fight .user-level-wrapper {
   background-image: var(--fight-bg);
   padding: 25px 0 25px 25px;
  @media screen and (max-width: 510px) {
    padding: 25px 25px 25px 25px;
  }
   //display: flex;
   //flex-direction: column;
  border-radius: 55px;
  gap: 18px;
  .title-wrapper {
    font-size: 17px;
    font-weight: 700;
    color: var(--text-color);
    @media screen and (max-width: 510px) {
      padding-right: 0;
    }
    padding-right: 25px;
  }
  .info-wrapper {
    display: flex;
    gap: 16px;
    @media screen and (max-width: 510px) {
      gap: 18px;
      flex-flow: column;
    }

    .progress-side {
      flex: 1;
      .linear-bars-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .bar {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .bar-title {
            font-size: 14px;
            font-weight: 500;
            color: var(--text-color);
            display: flex;
            gap: 4px;
            .more-desc {
              font-size: 12px;
              font-weight: 400;
              color: var(--text-color);
            }
          }
          .bar-wrapper  {
            display: flex;
            flex-direction: row-reverse;
            gap: 3px;
            align-items: center;
            .MuiLinearProgress-root {
              width: 100%;
              height: 15px;
              border-radius: 20px;
              background-color: lighten(#0993D7, 50%);
              .MuiLinearProgress-bar {
                background-color: var(--primary);
                text-align: left;
                display: flex;
                justify-content: end;
                align-items: center;
                //height: inherit;

                .linear-progress-value {
                  background-color: var(--primary);
                  color: var(--white);
                  font-size: 12px;
                  font-weight: 500;
                  //position: absolute;
                  //top: 21%;
                  padding-left: 1px;
                }
              }
            }
            .min-value {
              color: var(--text-color);
              font-size: 12px;
              font-weight: 600;
            }
            .max-value {
              color: var(--text-color);
              font-size: 12px;
              font-weight: 600;
            }
          }
        }

      }
      .score-sum-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .value {
          color: var(--white);
          background-image: var(--secondary-btn-gradient);
          padding: 5px 25px;
          border-radius: 18px;
        }
      }
    }

    .info-side {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-evenly;
      .level-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .img-wrapper {
          display: flex;
          justify-content: center;
          img {
            width: 100px;
          }
        }
        .level-name {
          font-weight: 600;
          font-size: 15px;
          color: var(--text-color);
          text-align: center;
        }
      }
      .level-remaining {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: center;
        padding: 0 15px 0 0;
        .level-heading {
          font-size: 13px;
          font-weight: 400;
          color: var(--text-color);
        }
        .level-desc {
          font-size: 12px;
          font-weight: 300;
          color: var(--text-color);
        }
      }

    }
  }



}


//Collapsed Drawer
.dragons-fight .MuiDrawer-modal {
  .MuiDrawer-paper {
    background-color: var(--bg);

    ul li a {
      color: var(--text-color);
    }
  }
}

//Credit Card
.dragons-fight .credit-card-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  //align-items: center;
  .credit-card-img {
    width: 25px;
    @media screen and (max-width: 510px) {
      width: 15px;
    }
  }
  .credit-card-inner-wrapper {
    display: flex;
    //flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    @media screen and (max-width: 510px) {
      padding: 0 6px;
    }
    @media screen and (max-width: 880px) {
      flex-direction: column;
    }
    .form-side {
      //flex: 1;
      .add-card-form-wrapper {
        flex: 1;
        .add-credit-card-wrapper {
          display: flex;
          flex-direction: column;
          gap: 7px;
          padding: 10px;
          @media screen and (max-width: 580px) {
            padding: 0;
          }
          .credit-card-sample {
            box-shadow: none;
            background-color: var(--bg);
            border: 1px solid transparent;
            background: var(--primary-secondary-gradient-border);
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            border-radius: 25px;
            .credit-card-sample-inner {
              background-image: var(--fight-bg);
              color: var(--text-color);
              border-radius: 25px;
              padding: 15px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              .name-icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .bank-name{
                  font-size: 18px;
                  font-weight: 600;
                  color: var(--text-color);
                  @media screen and (max-width: 510px) {
                    font-size: 16px;
                  }
                }
              }
              .card-number-wrapper {
                font-size: 22px;
                font-weight: 500;
                color: var(--text-color);
                text-align: center;
                @media screen and (max-width: 510px) {
                  font-size: 18px;
                }
              }
              .card-sheba-wrapper {
                font-size: 18px;
                font-weight: 300;
                color: var(--text-color);
                text-align: center;
                white-space: nowrap;
                @media screen and (max-width: 510px) {
                  font-size: 14px;
                }
              }
              .user-name {
                font-size: 20px;
                font-weight: 500;
                color: var(--text-color);
                text-align: start;
                @media screen and (max-width: 510px) {
                  font-size: 16px;
                }
              }
            }
          }
          .add-card-form {
            display: flex;
            flex-direction: column;
            gap: 7px;
            .confirm-btn-wrapper {
              .confirm-btn {
                background-image: var(--secondary-btn-gradient);
                //color: var(--text-color);
                border-radius: 10px;
                span {
                  color: var(--white);
                  font-size: 16px;
                  @media screen and (max-width: 1100px) {
                    font-size: 12px;
                  }
                }
              }
            }

          }
        }
      }
    }
    .table-side {
      //flex: 2;
      .cards-table-wrapper {
        flex: 2;
      }
    }


  }
}

//Referral Section
.dragons-fight .referral-card-section-mobile {
  @media screen and (max-width: 510px) {
    padding: 0 0 7px 0;

  }
  .referral-card-section-wrapper {
    box-shadow: none;
    border-radius: 35px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 580px) {
     border-radius: 20px;
    }
    .referral-card-inner-wrapper {
      background-color: var(--bg-light);
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      border-radius: 35px;
      @media screen and (max-width: 580px) {
        border-radius: 20px;
        gap: 5px;
      }
      .referral-code-link-wrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 30px 30px 0 30px;
        @media screen and (max-width: 580px) {
          gap: 10px;
          padding: 15px 15px 0 15px;
        }
        .referral-title {
          font-weight: 500;
          font-size: 18px;
          color: var(--text-color);
          opacity: 0.8;
          @media screen and (max-width: 580px) {
           font-size: 15px;
          }
        }
        .referral-code-wrapper {
          border: 1px dashed var(--secondary);
          padding: 10px 20px;
          border-radius: 7px;
          width: 100%;
          text-align: center;
          color: var(--text-color);
          font-size: 16px;
          @media screen and (max-width: 580px) {
            padding: 7px 20px;
          }
        }
        .referral-link-input {
          .MuiFormControl-root {
            .MuiInputBase-root {
              background-image: var(--fight-bg);
            }
          }
        }
      }
      .referral-bottom-wrapper {
        display: flex;
        gap: 10px;
        padding: 0 30px 0 0;
        flex-wrap: wrap;
        @media screen and (max-width: 580px) {
         padding: 0 5px 0 0;
        }
        .referral-bottom-right {
          flex: 0.6;
          display: flex;
          flex-direction: column;
          gap: 20px;
          @media screen and (max-width: 580px) {
           flex: 0.8;
            gap: 5px;
          }
          .referral-info-wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
            @media screen and (max-width: 580px) {
              gap: 6px;
            }
            .referral-info-section {
              display: flex;
              flex-direction: column;
              gap: 8px;
              .info-row {
                display: flex;
                gap: 15px;
                @media screen and (max-width: 580px) {
                  gap: 6px;
                }
                .referral-title {
                  font-size: 13px;
                  font-weight: 300;
                  color: var(--text-color);
                  opacity: 0.8;
                  @media screen and (max-width: 580px) {
                    font-size: 11px;
                  }
                }
                .referral-value {
                  display: flex;
                  gap: 3px;
                  .value {
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--text-color);
                    @media screen and (max-width: 580px) {
                      font-size: 12px;
                    }
                  }
                  .metric {
                    font-weight: 300;
                    font-size: 11px;
                    color: var(--text-color);
                    opacity: 0.9;
                    @media screen and (max-width: 580px) {
                      font-size: 8px;
                    }
                  }
                }
              }
            }
          }
          .transfer-wallet {
            display: flex;
            flex-direction: column;
            gap: 5px;
            @media screen and (max-width: 580px) {
              flex: 1;
            }
            .transfer-title {
              color: var(--secondary-color);
              font-size: 13px;
            }
            .confirm-btn {
              background-image: var(--secondary-btn-gradient);
              //color: var(--text-color);
              border-radius: 10px;
              span {
                color: var(--white);
                @media screen and (max-width: 1100px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .referral-bottom-left {
          flex: 0.4;
          @media screen and (max-width: 580px) {
            flex: 0.2;
          }
          .img-wrapper {
            @media screen and (max-width: 580px) {
              margin: -35px 0px;
              svg {
                max-width: 150px;
              }
            }
          }
        }
      }

    }
  }
}

//Notifications
.dragons-fight .notification-tables-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  .table-wrapper {
    flex: 1;
    .table-header-title {
      color: var(--text-color);
    }
  }
}
//Snack bar Notifications
.dragons-fight .notification-container {
  width: 400px;
  @media screen and (max-width: 580px) {
   width: 315px;
  }
  .notification {
    box-shadow: none;
    padding: 12px 12px 12px 58px;
    border-radius: 15px;
    opacity: 1;
    @media screen and (max-width: 580px) {
      padding: 8px 8px 8px 58px;
    }
    &.notification-error {
      background-color: var(--notif-danger-bg);
      color: var(--text-color);
    }
    &.notification-success {
      background-color: var(--notif-success-bg);
      color: var(--text-color);
    }
    .notification-message {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .title {
        font-size: 15px;
        @media screen and (max-width: 580px) {
          font-size: 12px;
        }
      }
      .message {
        opacity: 0.9;
        font-size: 13px;
        @media screen and (max-width: 580px) {
          font-size: 10px;
        }
      }
    }
  }
}


//Text Field
.dragons-fight
.MuiFormControl-root {
  background-color: var(--field-color);
  border-radius: 10px;

  .MuiFormLabel-root {
    color: var(--text-color);
    padding: 2px 12px;
    background-color: var(--field-color);
    border-radius: 9px;
    font-size: 16px !important;
    top: -2px;
    opacity: 1;
    @media screen and (max-width: 1100px) {
      font-size: 13px !important;
    }
    @media screen and (max-width: 510px) {
      font-size: 11px !important;
    }
    span {
      font-size: 16px;
      @media screen and (max-width: 1100px) {
        font-size: 13px !important;
      }
      @media screen and (max-width: 510px) {
        font-size: 11px !important;
      }
    }
  }
  .MuiInputBase-root {
    color: var(--text-color);
    padding-left: 0;
    .MuiSelect-root {
      .credit-card-wrapper {
        display: flex;
        align-items: center;
        color: var(--text-color);
        gap: 3px;
        flex-direction: row;
        .credit-card-img {
          width: 20px;
        }
        .account-num {
          opacity: 0.8;
          font-size: 13px;
        }
        .credit-card-num {
          opacity: 0.9;
          font-size: 10px;
        }
      }
    }
    .MuiSelect-icon {
      color: var(--text-color);
    }
    &.MuiOutlinedInput-adornedStart  {
      padding-right: 0;
    }
    @media screen and (max-width: 780px) {
      font-size: 12px;
    }

    input:-internal-autofill-selected,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      //appearance: menulist-button;
      background-image: none !important;
      background-color: var(--border-color)!important;
      color: var(--text-color) !important;
      transition-delay: 9999s;
      transition-property: background-color, color;

    }
    input::placeholder {
      @media screen and (max-width: 510px) {
        font-size: 8px !important;
        margin-top: 10px;
        margin-right: -5px;
      }
    }
    .MuiOutlinedInput-inputAdornedEnd {
      padding-left: 12px;
    }
    .input-end-adornment {
      background-color: var(--input-adornment-bg);
      padding: 10.5px 10px;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
      border-radius: 10px 0 0 10px;
      &.next-end-adornment {
        border-radius: 0;
      }
      &.factor-end-adornment {
        padding: 7.5px 7px;
      }
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          @media screen and (max-width: 510px) {
            font-size: 17px;
          }
        }
      }
      @media screen and (max-width: 1100px) {
        font-size: 10px;
        padding: 10.5px 4px;
      }
      :first-child {
        border-radius: 0;
      }
    }
    .input-end-adornment:last-child {
      border-radius: 10px 0 0 10px;
    }
    .input-start-adornment {
      background-color: var(--input-adornment-bg);
      padding: 10.5px 10px;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
      border-radius: 0 10px 10px 0;
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          @media screen and (max-width: 510px) {
            font-size: 17px;
          }
        }
      }
      @media screen and (max-width: 1100px) {
        font-size: 10px;
        padding: 10.5px 4px;
      }
      :first-child {
        border-radius: 0;
      }
    }
    .input-start-adornment:last-child {
      border-radius: 0 10px 10px 0;
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: unset;
      border-color: transparent;
    }
    .MuiOutlinedInput-root {
      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
    }
  }
}
.buttons-wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  .confirm-btn-wrapper {
    flex: 2;
    .confirm-btn {
      background-image: var(--secondary-btn-gradient);
      //color: var(--text-color);
      border-radius: 10px;
      span {
        .sending-button-text {
          display: flex;
          gap: 4px;
          align-items: center;
        }
        color: var(--white);
        @media screen and (max-width: 1100px) {
          font-size: 12px;
        }
      }
    }

  }
  .cancel-btn-wrapper {
    flex: 1;
    .cancel-btn {
      background-image: var(--button-grey-dark);
      color: var(--text-color);
      border-radius: 10px;
      span {
        color: var(--white);
        font-size: 16px;
        @media screen and (max-width: 1100px) {
          font-size: 12px;
        }
      }
    }
  }
}
.MuiFormGroup-root {
  .MuiFormControlLabel-root {
    .MuiRadio-root{
      color: var(--primary);
    }
    .MuiFormControlLabel-label {
      color: var(--text-color);
      font-size: 14px !important;
      @media screen and (max-width: 510px) {
        font-size: 11px !important;
      }
    }
    &.Mui-disabled {
      .MuiRadio-root {
        color: var(--bg-lighter);
      }
      .MuiFormControlLabel-label {
        color: var(--bg-lighter);
      }
    }
  }
}
.error-box {
  background-color: var(--danger-bg);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 400;
  display: block;
  gap: 5px;
  padding: 5px;
  align-items: center;
  border-radius: 10px;
  text-align: initial;
  @media screen and (max-width: 580px) {
    font-size: 9px;
  }
  img {
    @media screen and (max-width: 580px) {
      width: 10px;
    }
  }
}
.warning-box {
  background-color: var(--danger-bg);
  color: var(--danger-text);
  font-size: 13px;
  font-weight: 400;
  display: flex;
  gap: 5px;
  padding: 10px;
  //align-items: center;
  border-radius: 10px;
  flex-direction: column;
  @media screen and (max-width: 580px) {
    font-size: 11px;
    gap: 3px;
    padding: 3px;
  }
}

.dragons-fight .app-sidebar {
  .app-sidebar-content ul.nav-menu {
    li {
      cursor: pointer;
    }
  }
}

.dragons-fight .sending-btn-text {
  color: var(--white) !important;
  display: flex;
  gap: 5px;
  align-items: center;
  opacity: 0.8;
}
// Dialogs
.dragons-fight .MuiBackdrop-root {
  backdrop-filter: blur(3px);
}
.dragons-fight .MuiDialog-container {
  @media screen and (max-width: 470px) {
    width: 100%;
    justify-content: space-between;
  }
  .MuiDialog-paperWidthSm{
    max-width: 740px;
  }
  .MuiDialog-paperFullWidth {
    @media screen and (max-width: 470px) {
     width: calc(100% - 30px);
    }
  }
  .MuiDialog-paper {
    padding: 0;
    margin: 32px 0;
    border-radius: 36px;
    border: 1px solid transparent;
    background: var(--primary-secondary-gradient-border);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    text-align: center;
    @media screen and (max-width: 1100px) {
      border-radius: 22px;
    }
    .MuiDialogTitle-root {
      @media screen and (max-width: 510px) {
        padding: 14px;
      }
      .MuiButtonBase-root {
        @media screen and (max-width: 510px) {
          padding: 3px;
        }
      }
    }
    div, p {
      color: var(--text-color);
      //width: 100%;
    }
    .MuiStepper-root {
      background-color: var(--bg-light);
      @media screen and (max-width: 510px) {
        padding: 0;
      }
      .MuiStep-root {
        .MuiStepConnector-root {
          top: 24%;
          @media screen and (max-width: 580px) {
           top: 41%;
          }
          .MuiStepConnector-line {
            border-top-width: 4px;
            border-color: var(--border-color);
          }
        }
        .MuiStepLabel-root {
          .MuiStepLabel-iconContainer {
            border: 4px solid var(--border-color) ;
            border-radius: 50px;
            padding: 3px;
            z-index: 2;
            .MuiStepIcon-completed {
              color: var(--secondary);
            }
            .MuiStepIcon-root.MuiStepIcon-active {
              color:  var(--secondary);
            }
            .MuiStepIcon-root.been-active {
              color:  var(--secondary);
            }
            .MuiSvgIcon-root {
              border: 2px solid var(--secondary);
              border-radius: 50px;
              width: 30px;
              height: 30px;
              //.MuiStepIcon-active {
              //  color: var(--secondary);
              //}
            }
          }
          .MuiStepLabel-labelContainer {
            margin-top: 16px;
            @media screen and (max-width: 510px) {
             display: none;
            }
            .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
              margin-top: unset;
            }
            .MuiStepLabel-label {
              color: var(--text-color);
              font-size: 13px;
              margin-top: unset;
            }
            .MuiStepLabel-active {
              font-size: 14px !important;
              background-color: var(--border-color);
              width: fit-content;
              align-items: center;
              margin: auto;
              padding: 2px 7px;
              border-radius: 20px;
            }

          }
        }
        &.active {
          .MuiStepConnector-root {
            .MuiStepConnector-line {
              border-color: var(--secondary);
            }
          }
        }
        &.been-active {
          .MuiStepConnector-root {
            .MuiStepConnector-line {
              border-color: var(--secondary);
            }
          }
        }
      }
    }
    .MuiFormControl-root {
      background-color: var(--field-color);
      border-radius: 10px;

      .MuiFormLabel-root {
        color: var(--text-color);
        padding: 2px 12px;
        background-color: var(--field-color);
        border-radius: 9px;
        font-size: 16px !important;
        top: -2px;
        opacity: 1;
        @media screen and (max-width: 1100px) {
          font-size: 13px !important;
        }
        @media screen and (max-width: 510px) {
          font-size: 11px !important;
        }
        span {
          font-size: 16px;
          @media screen and (max-width: 1100px) {
            font-size: 13px !important;
          }
          @media screen and (max-width: 510px) {
            font-size: 11px !important;
          }
        }
      }
      .MuiInputBase-root {
        color: var(--text-color);
        padding-left: 0;
        font-size: 16px;
        //padding: 0;
        &.MuiOutlinedInput-adornedStart  {
          padding-right: 0;
        }
        @media screen and (max-width: 780px) {
          font-size: 11px;
        }
        .MuiOutlinedInput-inputAdornedEnd {
          padding-left: 12px;
          @media screen and (max-width: 780px) {
            padding-left: 5px;
          }
        }
        .input-end-adornment {
          background-color: var(--input-adornment-bg);
          padding: 10.5px 10px;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-color);
          border-radius: 10px 0 0 10px;
          &.next-end-adornment {
            border-radius: 0;
          }
          &.factor-end-adornment {
            padding: 7.5px 7px;
          }
          .MuiIconButton-label {
            .MuiSvgIcon-root {
              @media screen and (max-width: 510px) {
                font-size: 13px;
              }
            }
          }
          @media screen and (max-width: 1100px) {
            font-size: 10px;
            padding: 10.5px 4px;
          }
          :first-child {
            border-radius: 0;
          }
        }
        .input-end-adornment:last-child {
          border-radius: 10px 0 0 10px;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: unset;
          border-color: transparent;
        }
        .MuiOutlinedInput-root {
          .Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: transparent;
            }
          }
        }
      }
    }
    .buttons-wrapper {
      display: flex;
      flex-wrap: nowrap;
      gap: 12px;
      align-items: center;
      .confirm-btn-wrapper {
        flex: 2;
        .confirm-btn {
          background-image: var(--secondary-btn-gradient);
         //color: var(--text-color);
          border-radius: 10px;
          font-size: 18px;
          .sending-button-text {
            display: flex;
            gap: 4px;
            align-items: center;
          }
          span {
            .sending-button-text {
              display: flex;
              gap: 4px;
              align-items: center;
            }
            color: var(--white);
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }

      }
      .cancel-btn-wrapper {
        flex: 1;
        .cancel-btn {
          background-image: var(--button-grey-dark);
          color: var(--text-color);
          border-radius: 10px;
          font-size: 18px;
          span {
            color: var(--white);
            @media screen and (max-width: 1100px) {
              font-size: 12px;
            }
          }
        }
      }
    }
    .rules-wrapper {
      background-image: var(--bg-gradient-light);
      color: var(--text-color);
      border-radius: 12px 12px ;
      .rules-list {
        padding: 0;
        .rules-list-item {
          .MuiListItemText-root {
            .rules-check {
              padding: 3px 7px 3px 3px;
              color: var(--secondary);
              &.Mui-checked {
                color: var(--secondary);
              }
            }
          }
        }
        @media screen and (max-width: 1100px) {
          font-size: 10px;
        }
        .MuiSvgIcon-root {
          @media screen and (max-width: 1100px) {
            font-size: 14px;
          }
        }
        .MuiCollapse-root {
          .rules-card {
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            opacity: 0.8;
            text-align: initial;
            font-size: 13px;
            color: var(--text-color);
            @media screen and (max-width: 1100px) {
              font-size: 10px;
              padding: 2px 6px;

            }
          }
        }
      }

    }
    .MuiFormGroup-root {
      .MuiFormControlLabel-root {
        .MuiRadio-root{
          color: var(--primary);
        }
        .MuiFormControlLabel-label {
          color: var(--text-color);
          font-size: 14px !important;
          @media screen and (max-width: 510px) {
            font-size: 11px !important;
          }
        }
        &.Mui-disabled {
          .MuiRadio-root {
            color: var(--bg-lighter);
          }
          .MuiFormControlLabel-label {
            color: var(--bg-lighter);
          }
        }
      }
    }
    .MuiPaper-root {
      .MuiTabs-root {
        .MuiTabs-indicator {
          background-color: transparent;
        }
        .MuiTabs-flexContainer {
          background-color: var(--bg);
          .MuiTab-root {
              min-width: unset !important;
              @media screen and (max-width: 1100px) {
                min-width: unset !important;
              }

            .MuiTab-wrapper {
              flex-direction: row;
              gap: 4px;
              font-size: 14px;
              @media screen and (max-width: 1100px) {
                font-size: 11px;
              }
              @media screen and (max-width: 470px) {
                font-size: 9px;
                flex-direction: column;

              }
              img {
                width: 30px;
                @media screen and (max-width: 1100px) {
                  width: 15px;
                }
                @media screen and (max-width: 470px) {
                  width: 12px;
                }
              }
            }
            &.Mui-selected {
              .MuiTab-wrapper {
                background-image: var(--fight-bg);
                border-radius: 25px;
                padding: 5px 10px;
                //width: fit-content;
                font-size: 15px;
                font-weight: 700;
                @media screen and (max-width: 1100px) {
                  font-size: 12px;
                }
                @media screen and (max-width: 470px) {
                  font-size: 10px;
                  flex-direction: column;
                  border-radius: 10px;
                }
              }

            }
          }
        }
      }
    }
    .amount-equal {
      background-image: var(--success-linear-gradient);
      color: var(--success-solid);
      font-size: 13px;
      font-weight: 400;
      display: flex;
      gap: 5px;
      padding: 19px 5px 5px 5px;
      flex: 0.5;
      width: fit-content;
      align-items: center;
      border-radius: 10px;
      margin-top: -14px;
      @media screen and (max-width: 1100px) {
        font-size: 10px;
        width: 76%;
      }
    }
    .error-box {
      background-color: var(--danger-bg);
      color: var(--text-color);
      font-size: 12px;
      font-weight: 400;
      display: block;
      gap: 5px;
      padding: 5px;
      align-items: center;
      border-radius: 10px;
      text-align: initial;
      @media screen and (max-width: 1100px) {
        font-size: 9px;
      }
      img {
        @media screen and (max-width: 1100px) {
          width: 10px;
        }
      }
    }
    .warning-box {
      background-color: var(--danger-bg);
      color: var(--danger-text);
      font-size: 13px;
      font-weight: 400;
      display: flex;
      gap: 5px;
      padding: 10px;
      align-items: center;
      border-radius: 10px;
      @media screen and (max-width: 1100px) {
        font-size: 9px;
        padding: 3px;
      }
    }

  }
}
.dragons-fight .MuiDialog-container:before {
  content: "";
  background-image: url("../../assets/images/V2/dialog-red-vector.svg?v=2");
  width: calc(50%);
  height: 50%;
  background-repeat: no-repeat;
  margin: 0 0 0 -5.6vw;
  background-position-y: center;
  background-size: 103% 100%;
  @media screen and (max-width: 800px) {
    width: calc(16%);
  }
  @media screen and (max-width: 470px) {
    width: calc(8%);
    height: 16%;
  }
}
.dragons-fight .MuiDialog-container:after {
  content: "";
  background-image: url("../../assets/images/V2/dialog-blue-vector.svg");
  margin: 0 -7vw 0 0;
  //min-width: 405px;
  background-position-y: center;
  background-size: 101% 100%;
  width: calc(50%);
  height: 50%;
  background-repeat: no-repeat;
  @media screen and (max-width: 800px) {
    width: calc(16%);
  }
  @media screen and (max-width: 470px) {
    width: calc(8%);
    height: 16%;
  }
}




//Advantages swiper
.dragons-fight .advantages-slider-wrapper {
  background-image:   url("../../assets/images/common/advtangesRedThinV.svg");
  background-position: center;
  background-repeat: no-repeat;
  .advantage-slider {
    padding: 50px 0;
    @media screen and (max-width: 580px) {
     padding: 20px 0;
    }
    .swiper-wrapper {
      .swiper-slide {
        .advantage-card {
          box-shadow: none;
          border-radius: 35px;
          border: 1px solid transparent;
          background: var(--primary-secondary-gradient-border);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          .advantage-card-inner {
            padding: 20px;
            display: flex;
            background-color: var(--bg-light);
            align-items: center;
            @media screen and (max-width: 580px) {
             padding: 5px;
            }
            .img-wrapper {
              width: 50%;
              @media screen and (max-width: 580px) {
               width: 45%;
              }
              .advantage-card-img {
                @media screen and (max-width: 580px) {
                  width: 146px;
                  height: 146px;
                }
              }
            }
            .text-wrapper {
              color: var(--text-color);
              .title {
                font-weight: 700;
                font-size: 18px;
                color: var(--text-color);
                @media screen and (max-width: 580px) {
                 font-size: 15px;
                }
              }
              .description {
                font-size: 14px;
                font-weight: 400;
                color: var(--text-color);
                opacity: 0.8;
                @media screen and (max-width: 580px) {
                  font-size: 12px;
                }
              }
            }
          }

        }
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: var(--text-color) !important;
        opacity: 0.7;
        &.swiper-pagination-bullet-active {
          background-color: var(--secondary-color) !important;
        }
      }
    }
  }
}



//Advantages Cards
.dragons-fight .advantages-card-wrapper {
  background-image: url("../../assets/images/V2/vertical-borders.svg");
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  //min-height: 801px;
  background-size: 104% 100%;
  background-position: center;
  height: fit-content;
  .advantages-card {
    box-sizing: border-box;
    //height: 61vw;
    width: 100%;
    margin: auto;
    padding: 2% 0 10%;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 28px;
    @media screen and (max-width: 580px) {
      gap: 4px;
    }
  }
  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    padding-bottom: 4%;
    color: var(--text-color);
    line-height: 1.4;
    @media screen and (max-width: 580px) {
     font-size: 16px;
    }
  }
  .description {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    color: var(--text-color);
    @media screen and (max-width: 580px) {
      font-size: 12px;
    }
  }
  .cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    @media screen and (max-width: 580px) {
     gap: 2px;
      flex-direction: column;
    }
    .advantage-card {
      width: 25%;
      box-shadow: none;
      padding: 10px 20px;
      background-image: var(--secondary-glow);
      background-color: unset;
      @media screen and (max-width: 580px) {
        width: 100%;
        padding: 3px 20px;
      }
      .advantage-card-inner {
        padding: 10px 20px;
        @media screen and (max-width: 580px) {
          padding: 3px 20px;
        }
        .card-img-wrapper {
          text-align: center;
        }
        .card-title {
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          color: var(--text-color);
          opacity: 0.9;
          @media screen and (max-width: 580px) {
          font-size: 14px;
          }
        }
        .card-description {
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: var(--text-color);
          opacity: 0.8;
          @media screen and (max-width: 580px) {
            font-size: 10px;
          }
        }
      }

    }
  }
}

//home-price table
.dragons-fight .price-table-wrapper {
  .heading {
    font-weight: 700;
    font-size: 36px;
    line-height: 2;
    color: var(--text-color);
    @media screen and (max-width: 580px) {
      font-size: 16px;
    }
  }
  .description {
    font-size: 14px;
    margin-top: 10px;
    color: var(--text-color);
  }
  .price-table-card {
    box-shadow: none;
    background-color: unset;
    .sort-wrapper {
      .search-bar {
        box-shadow: none;
        background-color: var(--bg);
        height: 40px;
        div:first-of-type {
          .MuiInputBase-root {
            input {
              direction: rtl;
              color: var(--text-color);
            }
          }
        }
        .MuiButtonBase-root {
          .MuiIconButton-label {
            color: var(--text-color);
            .MuiSvgIcon-root {
              @media screen and (max-width: 510px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .price-table-wrapper .price-table {
      .price-table-head tr th{
        background-color: var(--bg-light);
        border-bottom: 2px solid var(--secondary-light);
        font-weight: 700;
        font-size: 14px;
        color: var(--text-color) !important;
        opacity: 1;
        .search-bar-wrapper {
          background-color: var(--border-color);
          margin: 5px;
          box-shadow: none;
          border-radius: 30px;
          width: 500px;
          color: var(--text-color);
          border-top-left-radius: 30px !important;
          border-top-right-radius: 30px !important;
          @media screen and (max-width: 580px) {
            height: 45px;
            width: 98%;
            margin: 3px;
            border-radius: 20px;
            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
          }
          .MuiButtonBase-root {
            padding: 2px;
            color: var(--text-color);
            .MuiIconButton-label {
              .MuiSvgIcon-root {
                @media screen and (max-width: 580px) {
                  font-size: 16px;
                }
              }
            }
          }
          div:first-of-type {
            margin: auto 5px;
            .MuiInputBase-root {
              input {
                padding: 6px 0 7px;
                color: var(--text-color);
                direction: rtl;
              }
            }
          }
        }
        @media screen and (max-width: 510px) {
          font-size: 11px;
        }
        :first-child {
          border-top-right-radius: 10px;
        }
        :last-child {
          border-top-left-radius: 10px;
        }
      }
      .price-table-body {
        .inventory {
          @media screen and (max-width: 510px) {
            font-size: 11px;
          }
        }
        .coin-cell {
          white-space: nowrap;
          display: flex;
          justify-content: flex-start;
          height: 100%;
          .coin-logo {
            margin: 8px;
            @media screen and (max-width: 510px) {
              margin: 4px;
            }
          }
          .coin-name {
            .name {
              font-weight: 700;
              font-size: 16px;
              @media screen and (max-width: 510px) {
                font-size: 10px;
              }
            }
            .symbol {
              font-size: 12px;
              @media screen and (max-width: 510px) {
                font-size: 9px;
              }
            }
          }
        }
        .trade-buttons {
          display: flex;
          justify-content: center;
          gap: 10px;
          .MuiButtonBase-root {
            .MuiButton-label {
              @media screen and (max-width: 510px) {
               flex-direction: column-reverse;
                font-size: 10px;
                line-height: 1.5;
              }
              .title-wrapper {
                @media screen and (max-width: 510px) {
                  transform: unset;

                }
              }
            }
          }
          .buy-btn {
            background-image: var(--secondary-btn-gradient);
            @media screen and (max-width: 510px) {
             padding: 0;
            }
          }
          .sell-btn {
            background-image: var(--primary-btn-gradient);
            @media screen and (max-width: 510px) {
              padding: 0;
            }
          }
        }
        tr td {
          border-bottom: unset;
          opacity: 0.8;
          .metric {
            color: var(--secondary-color);
            margin: 0 0 0 4px;
            @media screen and (max-width: 580px ) {
              margin: 0 0 0 1px;

            }
          }
          .MuiButtonBase-root {
            .MuiIconButton-label {
              .MuiSvgIcon-root {
                font-size: 20px;
                @media screen and (max-width: 580px ) {
                  font-size: 16px;

                }
              }
            }
          }
          @media screen and (max-width: 580px ) {
            font-size: 11px;

          }
        }
        tr:nth-of-type(odd) {
          background-image: var(--bg-gradient);
          color: var(--text-color);
          td {
            border-bottom: 1px solid var(--primary-light)
          }
        }
        tr:nth-of-type(even) {
          background-image: var(--bg-gradient);
          color: var(--text-color);
          td {
            border-bottom: 1px solid var(--secondary-light)
          }
        }
      }
    }

  }
}

//Latest News Slider
.dragons-fight .news-slider-wrapper {
  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
  }
  .description {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
  }
  .news-slider {
    padding: 0 0 50px 0;
    .swiper-wrapper {
      .swiper-slide {
        .news-card {
          box-shadow: none;
          border-radius: 35px;
          border: 1px solid transparent;
          background: var(--primary-secondary-gradient-border);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          display: flex;
          flex-direction: column;

          .news-card-extended {
            background-image: var(--bg-img-black);
            background-repeat: no-repeat;
            background-size: cover;
            .date {
              padding: 15px 15px 0 15px;
              color: var(--secondary-color);
            }
            .text-wrapper {
              color: var(--text-color);
              padding: 5px 15px;
              .title {
                font-weight: 700;
                font-size: 18px;
                text-align: center;
              }
              .description {
                font-size: 14px;
                font-weight: 400;
              }
            }
            .news-action-wrapper {
              display: flex;
              .news-btn {
                width: 40%;
                background-image: var(--secondary-btn-gradient);
                border-radius: 250px 10px;
              }
              .actions {
                width: 60%;
                text-align: center;
                color: var(--text-color);
                display: flex;
                justify-content: center;
                .likes{
                   margin: 0 8px;
                  .likes-count {
                    margin: 0 4px;
                  }
                }
                .seens {
                   margin: 0 8px;
                  .seens-count {
                    margin: 0 4px;
                  }
                }
              }
            }
          }

        }
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: var(--text-color) !important;
        opacity: 0.7;
        &.swiper-pagination-bullet-active {
          background-color: var(--secondary-color) !important;
        }
      }
    }
  }
}

//Guide Slider
.dragons-fight .guide-slider-wrapper {
  padding: 50px 0;
  @media screen and (max-width: 580px) {
    padding: 10px 0;
  }
  .heading {
    font-weight: 700;
    font-size: 36px;
    color: var(--text-color);
    padding: 30px 0 20px 0;
    @media screen and (max-width: 580px) {
     padding: 10px 0;
      font-size: 16px;
    }
  }
  .description {
    font-size: 14px;
    margin-top: 10px;
    color: var(--text-color);
    @media screen and (max-width: 580px) {
      font-size: 12px;
      margin-top: 5px;
    }
  }
  .guide-slider {
    height: fit-content;
    @media screen and (max-width: 580px) {
      max-height: 600px;

    }
    //padding: 0 0 50px 0;
    .swiper-wrapper {
      //max-height: 400px;
      @media screen and (max-width: 580px) {
        //max-height: 420px;
      }
      .swiper-slide {
        height: fit-content !important;
        //max-height: 400px;
        @media screen and (max-width: 580px) {
          //max-height: 420px;
        }
        @media screen and (max-width: 580px) {
          font-size: 12px;
          margin-top: 5px;
        }
        .guide-card {
          flex: 1;
          box-shadow: none;
          border-radius: 35px;
          border: 1px solid transparent;
          background: var(--secondary-glow);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          display: flex;
          flex-direction: column;
          //height: 100%;
          .guide-inner-wrapper {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 580px) {
              flex-direction: column-reverse;
            }
            .text-wrapper {
              color: var(--text-color);
              padding: 5px 50px;
              display: flex;
              justify-content: start;
              align-items: flex-start;
              flex-flow: column;
              font-size: 20px;
              /* justify-content: space-between; */
              gap: 18px;
              @media screen and (max-width: 580px) {
                font-size: 15px;
                gap: 8px;
                padding: 5px 10px;
              }
              p {
                font-size: 13px;
                opacity: 0.9;
                color: var(--text-color);
                padding-bottom: 5px;
                white-space: pre-wrap;
                @media screen and (max-width: 580px) {
                  font-size: 10px;
                  font-weight: 500;
                }
              }

            }
            .img-wrapper {
              margin: 50px;
              @media screen and (max-width: 580px) {
                margin: 5px;
              }
              img {
                max-width: 365px;
                @media screen and (max-width: 580px) {
                  max-width: 280px;
                }
              }
            }
          }
        }
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: var(--text-color) !important;
        opacity: 0.7;
        &.swiper-pagination-bullet-active {
          background-color: var(--secondary-color) !important;
        }
      }
    }

  }
}


//User Comments Slider
.dragons-fight .user-comments-slider-wrapper {
  .heading {
    font-weight: 700;
    font-size: 36px;
    color: var(--text-color);
    text-align: center;
    @media screen and (max-width: 580px) {
     font-size: 16px;
    }
  }
  .description {
    font-size: 14px;
    margin-top: 10px;
    color: var(--text-color);
    text-align: center;
    @media screen and (max-width: 580px) {
      font-size: 13px;
    }
  }
  .user-comments-slider {
    padding: 0 0 50px 0;
    @media screen and (max-width: 580px) {
      padding: 0;
    }
    .swiper-wrapper {
      .swiper-slide-prev {
        border-radius: 35px;
        transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(1) !important;
        @media screen and (max-width: 580px) {
         transform: unset;
        }
      }
      .swiper-slide-next {
        border-radius: 35px;
        transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(1) !important;
        @media screen and (max-width: 580px) {
          transform: unset;
        }
      }
      .swiper-slide-active {
      }
      .swiper-slide {
        .user-comment-card {
          box-shadow: none;
          border-radius: 35px;
          border: 1px solid transparent;
          background: var(--primary-secondary-gradient-border);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 580px) {
            min-height: 203px;
          }
          .user-comments-inner-wrapper {
            background-color: var(--bg-light);
            height: 100%;
            border-radius: 35px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .box-img {
              display: flex;
              justify-content: center;
              .img {
                width: 40%;
                text-align: center;
                background-image: var(--primary-light-gradient);
                border-radius: 0 0 20px 20px;
                 img {
                   width: 60px;
                   @media screen and (max-width: 580px) {
                    width: 40px;
                     height: 40px;
                   }
                 }
              }
            }
            .card-heading {
              font-size: 20px;
              font-weight: 700;
              text-align: center;
              color: var(--text-color);
              @media screen and (max-width: 580px) {
               font-size: 14px;
              }
            }
            .card-description {
              font-size: 12px;
              padding: 0 50px;
              color: var(--text-color);
              @media screen and (max-width: 580px) {
                font-size: 9px;
                padding: 0 5px;
              }
            }
            .star-wrapper {
              text-align: center;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              img {
                width: 28px;
                transform: rotate(20deg);
                @media screen and (max-width: 580px) {
                  width: 18px;
                }
              }
            }
        }
      }
    }

  }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: var(--text-color) !important;
        opacity: 0.7;
        &.swiper-pagination-bullet-active {
          background-color: var(--secondary-color) !important;
        }
      }
    }
}
}

//Footer Home
.dragons-fight .footer-wrapper{
  .home-footer-wrapper {
    .home-footer {
      display: flex;
      background-color: var(--bg-light);
      @media screen and (max-width: 880px) {
        flex-direction: column;
        gap: 13px;
      }
      .links-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        flex-direction: column;
        gap: 40px;
        @media screen and (max-width: 880px) {
          background-color: var(--bg-light);
          gap: 10px;
        }
        .site-signs-wrapper {
           display: flex;
          flex-wrap: wrap;
          padding: 0 20px;
          gap: 13px;
          align-items: center;
          justify-content: center;
          a {
            img {
              max-width: 70px;
              &.little-img {
                transform: scale(1.3);
              }
              @media screen and (max-width: 1100px) {
                width: 50px;
              }
              @media screen and (max-width: 440px) {
                width: 35px;
              }
            }
          }

        }
        .links {
          display: flex;
          flex-direction: column;
          gap: 20px;
          @media screen and (max-width: 880px) {
            gap: 12px;
            flex-direction: row;
            font-size: 12px;
          }
          .links-heading {
            font-weight: 700;
            color: var(--text-color);
            @media screen and (max-width: 580px) {
             display: none;
            }
          }
          span {
            color: var(--text-color);
          }
        }


      }
      .about-us-wrapper {
        display: flex;
        flex: 3;
        img {
          @media screen and (max-width: 880px) {
            display: none;
          }
          //height: 300px;
        }
        .about-us-inner {
          display: flex;
          flex-direction: column;
          gap: 20px;
          justify-content: center;
          @media screen and (max-width: 880px) {
            gap: 8px;
            padding-top: 10px;

          }
          .heading{
            font-weight: 700;
            font-size: 18px;

            text-align: center;
            color: var(--text-color);
            @media screen and (max-width: 880px) {
              font-size: 15px;
            }
          }
          .description {
            opacity: 0.8;
            font-size: 10px;
            text-align: center;
            color: var(--text-color);
            @media screen and (max-width: 880px) {
              font-size: 8px;
              padding: 0 20px;
            }
          }
          .buttons-wrapper {
            display: flex;
            justify-content: space-evenly;
            @media screen and (max-width: 880px) {
              justify-content: center;
            }
            .MuiButtonBase-root {
              .MuiButton-label {
                @media screen and (max-width: 880px) {
                  font-size: 9px;
                }
              }
            }
            .tether-btn {
              background-image: var(--primary-btn-gradient);
            }
            .pm-btn {
              background-image: var(--secondary-btn-gradient);
            }
          }
        }
      }
      .additional-info-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        @media screen and (max-width: 880px) {
          gap: 10px;
        }
        .additional-info {
          display: flex;
          flex-direction: column;
          gap: 20px;
          @media screen and (max-width: 880px) {
            font-size: 13px;
            flex-direction: row;
            gap: 8px;
            padding: 0 10px;
            flex-wrap: wrap;
            justify-content: center;
          }
          .info-row {
            display: flex;
            gap: 5px;
            align-items: center;
            //flex: 1;
            .img-title-wrapper {
              display: flex;
              gap: 2px;
              flex: 1;
              .title {
                color: var(--text-color);
                opacity: 0.8;
                font-size: 12px;
                //flex: 1;
                padding-top: 2px;
                @media screen and (max-width: 580px) {
                  font-size: 10px;
                }
              }
              img {
                width: 30px;
                @media screen and (max-width: 580px) {
                  width: 17px;
                }
              }
            }
            .desc {
              //flex: 1;
              font-size: 13px;
              opacity: 0.9;
              color: var(--text-color);
              @media screen and (max-width: 580px) {
                font-size: 11px;
              }

            }

          }
        }
        .social-media-links-wrapper {
          display: flex;
          gap: 10px;
          @media screen and (max-width: 880px) {
            gap: 3px;
          }
          .media-wrapper {

          }
        }
      }
    }
    .footer-bottom {
      background-color: var(--bg);
      display: flex;
      justify-content: space-between;
      padding: 15px 6%;
      @media screen and (max-width: 880px) {
        flex-direction: column;
        padding: 15px 1%;
        align-items: center;
        gap: 8px;
      }
      .footer-bottom-wrapper {
        .bottom-info {
          display: flex;
          gap: 30px;
          @media screen and (max-width: 580px) {
            gap: 10px;
          }
          .info {
            color: var(--text-color);
            opacity: 0.9;
            font-size: 14px;
            @media screen and (max-width: 580px) {
             font-size: 9px;
            }
          }
        }
      }
      .footer-copy-right {
        font-size: 15px;
        opacity: 0.8;
        color: var(--text-color) !important;
        @media screen and (max-width: 580px) {
          font-size: 11px;
        }
      }
    }
  }

}

//home sidebar
.dragons-fight .app-sidebar-content {
  .side-nav {
    .collapsed-drawer-logo-wrapper {
      padding: 10px 0;
      display: flex;
      justify-content: center;
      img {
        width: 60px;
      }
    }
    .logo-wrapper {
      margin: 15px auto 0;
      img {
        width: 55px;
      }
    }
    .app-main-menu {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: var(--text-color);
          }
        }
      }
    }
  }
}


//_right-sidebar.scss
.dragons-fight .color-theme-header {
  background-color: $app-primary-blue;
}

//_sidebar.scss
.dragons-fight .app-sidebar {

  .logo-wrapper {
    background-color: var(--field-color);
    width: 146%;
    height: 95px;
    z-index: 1300;
    border-radius: 0 0 0 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 579px) {
      display: none;
    }
    &.fixed-logo-wrapper {
      width: 113%;
    }
    .logo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .logo-img {
        width: 60px;
        margin: auto;
      }
      .swipe-btn {
        @media screen and (max-width: 579px) {
         display: block !important;
        }
        @media screen and (max-width: 1200px) {
          display: none;
        }
       &.fixed-swipe-btn {
         transform: rotate(180deg);
       }
      }
    }
    img {
      //width: ;
    }
  }
  .side-nav {
    top: 90px;
    background-color: var(--bg) !important;
    color: var(--text-color) !important;
    @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));
    .logo-wrapper {
      margin: 15px auto 0;
      img {
        width: 55px;
      }
    }
    .scrollbar {
      margin-top: 10px;
    }

    & .user-profile {
      background-color: $white;
      margin-right: 20px;
      margin-left: 20px;
      padding-left: 0;
      padding-right: 0;
      border-bottom: $jr-border;
      position: relative;
      z-index: 2;
    }

    & .customizer {
      border-bottom: solid 1px lighten($sidebar-text-blue, 35%);
    }

    & .user-detail {
      & .user-name {
        color: $app-primary-blue;
      }
    }
  }
}


.dragons-fight ul.nav-menu li button,
.dragons-fight ul.nav-menu li a {
  color: $sidebar-text-blue;
  .side-bar-content {
    display: flex;
    gap: 5px;
  }
}

.dragons-fight ul.nav-menu {
  .side-bar-icon {
    width: 20px;
    //margin-left: 3px;
  }
  & .nav-header {
    color: $sidebar-text-blue;
  }

  & li.menu + .nav-header {
    border-color: $gray-400;
  }
}

.dragons-fight ul.nav-menu > li.open > button,
.dragons-fight ul.nav-menu > li > button:hover,
.dragons-fight ul.nav-menu > li > button:focus,
.dragons-fight ul.nav-menu > li.open > a,
.dragons-fight ul.nav-menu > li > a:hover,
.dragons-fight ul.nav-menu > li > a:focus {
  background-image: var(--bg-gradient);
  background-color: unset;
  color: var(--text-color);
}

.dragons-fight ul.nav-menu li.menu.open > a {
  border-color: $app-primary-blue;
}

.dragons-fight ul.nav-menu li.menu > button:focus:before,
.dragons-fight ul.nav-menu li.menu > button:hover:before,
.dragons-fight ul.nav-menu li.menu > a:focus:before,
.dragons-fight ul.nav-menu li.menu > a:hover:before {
  color: var(--text-color);
}

.dragons-fight ul.nav-menu li ul {
  background-color: transparent;
}

.dragons-fight ul.nav-menu li.menu > button:before,
.dragons-fight ul.nav-menu li.menu > a:before {
  color: $sidebar-text-blue;
}

.dragons-fight ul.nav-menu li.menu.open > a:before,
.dragons-fight ul.nav-menu li.menu > a:focus:before,
.dragons-fight ul.nav-menu li.menu li > a:hover:before,
.dragons-fight ul.nav-menu li.menu.open > button:before,
.dragons-fight ul.nav-menu li.menu > button:focus:before,
.dragons-fight ul.nav-menu li.menu li > button:hover:before {
  color: var(--text-color);
}

.dragons-fight ul.nav-menu li.menu .sub-menu li > a:before {
  color: $sidebar-text-blue;
}

.dragons-fight ul.nav-menu li.menu.open .sub-menu li > a:after {
  background-color: $app-primary-blue;
}

.dragons-fight ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: $sidebar-blue;
  color: $app-primary-blue;
}

.dragons-fight ul.nav-menu li.menu .sub-menu li > a:hover,
.dragons-fight ul.nav-menu li.menu .sub-menu li > a:focus,
.dragons-fight ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dragons-fight ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dragons-fight ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-image: var(--bg-gradient);
  background-color: unset;
  color: var(--text-color);
}

/*Header top Navbar Styles*/
.blue .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $app-primary-blue;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $app-primary-blue;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-blue;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $app-primary-blue;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $app-primary-blue;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $app-primary-blue;
    }
  }
}

.blue .app-top-nav {

  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-blue;
    }
  }
}

.blue .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

.blue .header-notifications .app-notification {
  & .jr-list-link {

    @include hover-focus-active {
      color: $app-primary-blue;
      border-color: $app-primary-blue;
    }
  }
}

//_app-module.scss
.blue .module-nav {
  & li a {
    &.active {
      border-color: $app-primary-blue;
    }
  }
}

//_calendar.scss
.blue .rbc-event {
  background-color: $app-primary-blue;
}

.blue .rbc-event.rbc-selected {
  background-color: darken($app-primary-blue, 10%);
}

.blue .rbc-slot-selection {
  background-color: rgba($app-primary-blue, 0.7);
}

.blue .rbc-toolbar button:active,
.blue .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:active:hover,
.blue .rbc-toolbar button.rbc-active:hover,
.blue .rbc-toolbar button:active:focus,
.blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:focus {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:hover {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

//_chat.scss
.blue .chat-sidenav-title {
  color: $app-primary-blue;
}

.blue .chat-user-item {
  &.active,
  &:hover {
    //background-color: lighten($app-primary-blue, 45%);
  }
}

//_dashboard.scss
.blue .contact-list {
  & i {
    color: $app-primary-blue;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.blue .Collapsible__trigger {
  background: $app-primary-blue;
}

.blue .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-blue;
  }
}

//_login.scss
.blue .login-content .form-control {
  &:focus {
    border-color: $app-primary-blue;
  }
}

//_portfolio.scss
.blue .filter-with-bg-color ul li {
  .jr-link {
    border-color: $app-primary-blue;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-blue;
    }
  }
}

//_card.scss
.blue .profile-intro {
  & .icon {
    color: $app-primary-blue;
  }
}

.blue .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-blue;
  }
}

//_tables.scss
.blue .actions {
  color: $secondary-blue;
}

.blue .table-hover tbody tr:hover {
  background-color: rgba($app-primary-blue, 0.075);
}

//Border Color

.blue .border-primary {
  border-color: $app-primary-blue !important;
}

// login page content

.blue .app-logo-content {
  background-color: $app-primary-blue;
}

.blue .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-blue;
    color: $app-primary-blue;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-blue;
    }
  }
}

/*Button Group Styles*/
.blue .btn-group,
.blue .btn-group-vertical {
  > .jr-btn {
    &.active {
      background-color: $app-primary-blue;
      border-color: $app-primary-blue;
    }

  }

  > .jr-flat-btn {
    background-color: transparent;
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $app-primary-blue;
      &:hover,
      &:focus {
        background-color: rgba($app-primary-blue, 0.12);
        color: $app-primary-blue;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.blue .jr-fillchart-btn-close,
.blue .jr-onchart .jr-badge-up,
.blue .jr-task-list-item:hover .jr-text-hover {
  color: $app-primary-blue;
}

.blue .jr-entry-title:before {
  background-color: $app-primary-blue;
}

.blue .jr-card-ticketlist {
  & .jr-task-list-item:hover .jr-task-item-title,
  .jr-link {
    color: $app-primary-blue;
  }
}

.blue .slick-dots li.slick-active button:before {
  border-color: $app-primary-blue;
}

//Nav Styles
.blue .nav-pills .nav-link.active,
.blue .nav-pills .show > .nav-link {
  color: $white !important;
  background-color: $app-primary-blue;
}

// Gradient Color Class
.blue .bg-gradient-primary {
  @include gradient-directional($app-primary-blue, lighten($app-primary-blue, 16%), 0deg);
}

.blue .bg-gradient-primary-x {
  @include gradient-x(darken($app-primary-blue, 10%), lighten($app-primary-blue, 16%), 70%, 100%);
}

//Profile style
.blue .jr-profile-banner {
  background-color: $app-primary-blue;
  color: $white;

  & .jr-link {
    color: $white;

    &:hover,
    &:focus {
      color: $secondary-blue;
    }
  }
}

.blue .dropdown-item {

  &.active,
  &:active {
    background-color: $app-primary-blue;
    color: $white;
  }

  &[class*="text-"] {

    &.active,
    &:active {
      background-color: transparent;
    }
  }
}